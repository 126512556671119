import React from 'react'

export default class Links extends React.Component {
  render() {

    const linkStyle = {fontSize: '2rem', margin: '1rem', textDecoration: 'none'}
    return (
      <div
        style={{
              flexGrow:'1',
              display:'flex',
              justifyContent:'center',
              alignItems:'center'
           }}
      >
        <div style={{display:'flex',flexDirection:'column'}}>
          <a style={linkStyle} href="http://mandrill.com" target="_blank">Mandrill</a>
          <a style={linkStyle} href="http://chargedesk.com" target="_blank">Chargedesk</a>
          <a style={linkStyle} href="http://baremetrics.com" target="_blank">Baremetrics</a>
          <a style={linkStyle} href="https://www.google.com/analytics" target="_blank">Google Analytics</a>
          <a style={linkStyle} href="http://intercom.io" target="_blank">Intercom</a>
          <a style={linkStyle} href="http://contacts.google.com" target="_blank">Google Contacts</a>
        </div>
      </div>
    );
  }
}
