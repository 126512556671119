import React from 'react'
import PropTypes from 'prop-types'
import {BaseUrl} from "../../../config"
import {Button} from "@material-ui/core"
import {updatePicturesAction} from "../../../action/restaurant"

function dataURItoBase64(dataURI) {
  return dataURI.split(',')[1]
}

export default class RestaurantPictures extends React.Component {

  constructor(props) {
    super(props)

    this.$menuInput = React.createRef()
    this.$pictureInput = React.createRef()
    const restaurant = this.props.restaurant

    if (!restaurant) {
      this.state = {
        pictureUrl: null, menuUrl: null, enableSave: false, menuMimeType: null
      }
      return

    }

    const pictureUrl = BaseUrl + `restaurant/${restaurant.id}/picture`
    const menuUrl = BaseUrl + `restaurant/${restaurant.id}/menu`

    this.state = {
      pictureUrl, menuUrl, enableSave: false, menuMimeType: null
    }
  }

  replacePicture = (e) => {
    e.preventDefault()
    const $pictureInput = this.$pictureInput.current
    $pictureInput.value = null
    $pictureInput.click()
  }

  readPicture = (e) => {
    const files = e.target.files
    const file = files[0]

    const fileReader = new FileReader()

    fileReader.onload = ((e) => {
      const data = e.target.result

      this.setState({pictureUrl: data, enableSave: true})
    })
    fileReader.readAsDataURL(file)
  }

  replaceMenu = (e) => {
    e.preventDefault()
    const $menuInput = this.$menuInput.current
    $menuInput.value = null
    $menuInput.click()
  }

  readMenu = (e) => {
    const files = e.target.files
    const file = files[0]
    const mimeType = file.type
    const fileReader = new FileReader()

    fileReader.onload = ((e) => {
      const data = e.target.result

      this.setState({menuUrl: data, enableSave: true, menuMimeType: mimeType})
    })
    fileReader.readAsDataURL(file)
  }

  save() {
    this.props.dispatch(updatePicturesAction(this.props.restaurant, this.state.pictureUrl.startsWith("http") ? null : dataURItoBase64(this.state.pictureUrl), this.state.menuUrl.startsWith("http") ? null : dataURItoBase64(this.state.menuUrl), this.state.menuMimeType))
  }

  render() {
    const restaurant = this.props.restaurant
    if (!restaurant)
      return <div></div>

    return (<div className="restaurant-pictures">
      <div>
        <h4>Restaurant</h4>
        <button className="restaurant-image-button btn-default btn" onClick={this.replacePicture}>
          <img src={this.state.pictureUrl}/>
          <div className="overlay">
            <i className="material-icons">
              add_a_photo
            </i>
          </div>
        </button>
        <input
          ref={this.$pictureInput}
          type='file'
          style={{display: 'none'}}
          multiple={false}
          accept='image/jpeg'
          onChange={this.readPicture}
        />

        <h4>Menu</h4>
        <button className="restaurant-image-button btn-default btn" onClick={this.replaceMenu}>
          <img src={this.state.menuUrl}/>
          <div className="overlay">
            <i className="material-icons">
              add_a_photo
            </i>
          </div>
        </button>
        <input
          type='file'
          ref={this.$menuInput}
          style={{display: 'none'}}
          multiple={false}
          accept='image/jpeg'
          onChange={this.readMenu}
        />
      </div>
      <Button style={{marginTop: '2rem'}} variant="contained" color="secondary" onClick={() => {
        this.save()
      }}
              disabled={!this.state.enableSave}>Save Pictures</Button>

    </div>)
  }
}

RestaurantPictures.propTypes = {
  restaurant: PropTypes.object,
  dispatch: PropTypes.func
}
