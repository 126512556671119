import React from "react"
import PropTypes from "prop-types"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core"
import {getNewStripeCustomersAction} from "../../../action/prospect"
import ConvertToExistingStripeCustomer from "./existingStripe/convertToExistingStripeCustomer"
import CreateNewStripeCustomer from "./noStripe/createNewStripeCustomer"
import {StripeToken} from "../../../config"
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

const stripePromise = loadStripe(StripeToken)


export default class ConvertToCustomerDialog extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      showStripe: false,
      showNoStripe: false,
      dialogActions:[]
    }

    if (!props.stripeCustomers.size) {
      props.dispatch(getNewStripeCustomersAction())
    }
  }

  validateInput = () => {
    return true
  }

  cancel = () => {
    this.setState({showStripe:false,showNoStripe:false,dialogActions:[]})
    this.props.cancel()
  }

  render() {
    let actions = [<Button key="cancel" color="primary" onClick={this.cancel}>Cancel</Button>].concat(this.state.dialogActions)

    let contents = <div></div>

    if (!this.state.showStripe && !this.state.showNoStripe) {
      contents = <div style={{display: 'flex', justifyContent: 'space-around',height:'100%'}}>

        <Button variant="contained" key="stripe" color="secondary" onClick={() => {
          this.setState({showStripe: true})
        }}>
          Exists in Stripe </Button>

        <Button variant="contained"
                key="noStripe"
                color="secondary"
                onClick={() => {
                  this.setState({showNoStripe: true})
                }}
        >Does Not Exist in Stripe</Button>
      </div>
    }

    if (this.state.showStripe) {
      contents = <ConvertToExistingStripeCustomer
        dispatch={this.props.dispatch}
        prospect={this.props.prospect}
        customers={this.props.stripeCustomers}
        setDialogActions={(actions) => {
          this.setState({dialogActions:actions})
        }}
      />
    }

    if(this.state.showNoStripe) {
      contents = (
            <Elements stripe={stripePromise}>
              <CreateNewStripeCustomer
              plans={this.props.plans}
              dispatch={this.props.dispatch}
              prospect={this.props.prospect}
              setDialogActions={(actions) => {
                this.setState({dialogActions:actions})
              }}
              />
            </Elements>
        )
    }

    const conversionErrorMessage = this.props.conversionErrorMessage

    return <Dialog
      open={this.props.show}
      onClose={this.props.cancel}
      fullWidth={true}
    >
      <DialogTitle>Convert to customer</DialogTitle>
      <DialogContent>
        <div className="convert-to-customer">
          {contents}
        </div>
        <div className="convert-to-customer-error">
          {conversionErrorMessage}
        </div>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  }

}

ConvertToCustomerDialog.propTypes = {
  dispatch: PropTypes.func,
  show: PropTypes.bool,
  plans: PropTypes.object,
  conversionErrorMessage: PropTypes.string,
  cancel: PropTypes.func,
  prospect: PropTypes.object,
  stripeCustomers: PropTypes.object
}