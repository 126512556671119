import React from 'react'
import PropTypes from 'prop-types'
import {Dialog, Button, Checkbox, Select, MenuItem, TextField, DialogTitle, FormControlLabel, DialogContent, DialogActions, RadioGroup, Radio} from "@material-ui/core"
import moment from 'moment'
import Immutable from 'immutable'

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
]

export default class EditDeal extends React.Component {

  constructor(props) {
    super(props)

    this.discounts = Immutable.List([20, 30, 40, 50])

    const plan = props.plans.find((plan) => props.subscription.planId === plan.id)

    if (plan)
      this.discounts = plan.discountPercentages

    if (this.props.deal) {
      const deal = this.props.deal
      this.state = {
        numberOfPlaces: deal.places,
        lunch: deal.lunch,
        dinner: deal.dinner,
        discount: deal.percentage,
        days: deal.days,
        selectedDayIndex: `${deal.days.indexOf(true)}`,
        startTime: `${deal.startHour}:${deal.startMinute}`,
        endTime: `${deal.endHour}:${deal.endMinute}`
      }
    } else {
      this.state = {
        numberOfPlaces: 1,
        lunch: false,
        dinner: true,
        discount: this.discounts.get(0),
        days: [false, false, false, false, false, false, false],
        selectedDayIndex: null,
        startTime: "18:00",
        endTime: "22:00"
      }
    }
  }

  validateInput = () => {
    const hasDay = this.state.days.reduce((acc, enabled) => acc || enabled, false)

    return hasDay && this.state.dinner || this.state.lunch
  }

  hasDealForDayIndex = (index) => {
    if (!this.props.deals) {
      return true
    }

    return this.props.deals.find((deal) => {
      return deal.days.get(index) === true
    }) !== undefined
  }

  changeSelectedDayIndex = (ev) => {
    const days = [false, false, false, false, false, false, false]
    const value = parseInt(ev.target.value)
    days[value] = true
    this.setState({days, selectedDayIndex: `${value}`})
  }

  done = () => {
    const startHour = parseInt(moment(this.state.startTime, "HH:mm").format("HH"))
    const startMinute = parseInt(moment(this.state.startTime, "HH:mm").format("mm"))
    const endHour = parseInt(moment(this.state.endTime, "HH:mm").format("HH"))
    const endMinute = parseInt(moment(this.state.endTime, "HH:mm").format("mm"))

    const deal = {
      id: this.props.deal ? this.props.deal.id : null,
      places: parseInt(this.state.numberOfPlaces),
      percentage: parseInt(this.state.discount),
      days: this.state.days,
      lunch: this.state.lunch,
      dinner: this.state.dinner,
      startHour,
      startMinute,
      endHour,
      endMinute,
      pause:this.props.deal ? this.props.deal.pause : false
    }

    this.props.save(deal)

    // reset to default state
    this.setState({
      numberOfPlaces: 1,
      lunch: false,
      discount: this.discounts.get(0),
      days: [false, false, false, false, false, false, false],
      startTime: "19:00",
      endTime: "21:30"
    })
  }

  render() {

    if (!this.props.show)
      return <div></div>

    const dealActions = [
      <Button
        key="cancel"
        color="primary"
        onClick={this.props.cancel}
      >Cancel</Button>,
      <Button variant="contained"
              key="add"
              color="secondary"
              onClick={this.done}
              disabled={!this.validateInput()}
      >{this.props.deal ? "Save" : "Add"}</Button>
    ]

    const dayCheckboxes = daysOfWeek.map((day, index) => {
      return <FormControlLabel
        key={index}
        value={`${index}`}
        disabled={this.hasDealForDayIndex(index)}
        control={<Radio/>}
        label={day}/>
    })

    const discountItems = this.discounts.map((percentage) => {
      const text = `${percentage}%`
      return <MenuItem value={percentage} key={percentage}>{text}</MenuItem>
    })

    const numberOfPlacesItems = [1, 2, 3, 4, 5, 6, 7, 8].map((count) => {
      return <MenuItem value={count} key={count}>{count}</MenuItem>
    })

    let dinnerTimesClass = "add-deal-row"

    if (!this.state.dinner) {
      dinnerTimesClass += " disabled"
    }

    return <Dialog
      open={this.props.show}
      onClose={this.props.cancel}
      fullWidth={true}
    >
      <DialogTitle>Add Deal</DialogTitle>
      <DialogContent>
        <div className="row add-deal-row">
          <FormControlLabel
            labelPlacement="start"
            label="Number of places"
            control={
              <Select value={this.state.numberOfPlaces} onChange={(event) => {
                this.setState({numberOfPlaces: event.target.value})
              }
              }>
                {numberOfPlacesItems}
              </Select>
            }
          />
        </div>
        <div className="row add-deal-row">
          <FormControlLabel
            labelPlacement="start"
            label="Discount"
            control={

              <Select value={this.state.discount} onChange={(event) => {
                this.setState({discount: event.target.value})
              }
              }>
                {discountItems}
              </Select>
            }/>

        </div>
        <div style={{display: "flex"}}>
          <div className="lunch-checkbox">
            <FormControlLabel
              control={<Checkbox checked={this.state.lunch} onChange={(ev, checked) => {
                this.setState({lunch: checked})
              }}/>} label="Lunch"/>
          </div>
          <div className="lunch-checkbox">
            <FormControlLabel
              control={<Checkbox checked={this.state.dinner} onChange={(ev, checked) => {
                this.setState({dinner: checked})
              }}/>} label="Dinner"/>
          </div>
        </div>
        <div className={dinnerTimesClass} style={{marginLeft: '1rem'}}>
          <TextField
            type="time"
            label="Start Time"
            inputProps={{
              step: 1800
            }}
            style={{marginRight: '1rem'}}
            value={this.state.startTime}
            onChange={(event) => {
              this.setState({
                startTime: event.target.value
              })
            }
            }
          />
          <TextField
            type="time"
            format="24hr"
            label="End Time"
            inputProps={{
              step: 1800
            }}
            value={this.state.endTime}
            onChange={(event) => {
              this.setState({
                endTime: event.target.value
              })
            }
            }
          />
        </div>
        <div className="deal-day-checkboxes">
          <RadioGroup aria-label="Day" name="day" value={this.state.selectedDayIndex}
                      onChange={this.changeSelectedDayIndex}>
            {dayCheckboxes}
          </RadioGroup>
        </div>
      </DialogContent>
      <DialogActions>{dealActions}</DialogActions>
    </Dialog>
  }
}

EditDeal.propTypes = {
  deal: PropTypes.object,
  cancel: PropTypes.func,
  save: PropTypes.func,
  show: PropTypes.bool,
  deals: PropTypes.object,
  plans: PropTypes.object,
  subscription: PropTypes.object
}
