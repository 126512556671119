import React from 'react'
import PropTypes from 'prop-types'
import {mainColor} from '../../../theme'

export default class RestaurantListItem extends React.Component {

  constructor(props) {
    super(props)
    this.el = React.createRef()
  }

  select = (e) => {
    e.preventDefault()
    this.props.selectRestaurant(this.props.restaurant)
  }

  render() {
    let style = {}
    if (this.props.isSelected) {
      style = Object.assign({}, style, {backgroundColor: mainColor, color: 'white'})
    }

    const restaurant = this.props.restaurant
    let provider = null

    if (restaurant.stripeCustomerId) {
      provider = "Stripe"
    } else {
      if (restaurant.braintreeCustomerId) {
        provider = "Braintree"
      }
    }

    return (
      <div style={style} className="restaurant-list-item" onClick={this.select} ref={this.el}>
        <div>
        <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div>{restaurant.name}</div>
          <div className="provider">{provider}</div>
        </span>
        </div>
        <div style={{
          backgroundColor: 'rgba(0,0,0,0.1)',
          width: '90%',
          minHeight: '1px',
          maxHeight: '1px',
          height: '1px',
          marginTop: "1rem"
        }}>
        </div>
      </div>)
  }
}

RestaurantListItem.propTypes = {
  isSelected: PropTypes.bool,
  restaurant: PropTypes.object,
  selectRestaurant: PropTypes.func
}