import React from 'react'
import PropTypes from 'prop-types'
import Reservation from './reservation'

export default class RestaurantReservations extends React.Component {

  render() {

    if (!this.props.reservations) {
      return <div></div>
    }

    const reservations = this.props.reservations.sort((reservation1, reservation2) => {
      const time1 = reservation1.hour * 100 + reservation1.minute
      const time2 = reservation2.hour * 100 + reservation2.minute

      return time1 - time2
    }).map((reservation, index) => {
      return <Reservation key={index} reservation={reservation} dispatch={this.props.dispatch}/>
    })

    return <div className="reservations">
      <div className="header">
        <div className="time">
          Time
        </div>
        <div className="type">
          Type
        </div>
        <div className="price-or-discount">
          Price or Discount
        </div>
        <div className="name">
          Name
        </div>
        <div className="email">
          Email
        </div>
        <div className="phone">
          Phone
        </div>
        <div className="places">
          Places
        </div>
      </div>
      {reservations}
    </div>
  }
}

RestaurantReservations.propTypes = {
  reservations: PropTypes.object
}