import React from 'react'
import LoginComponent from './login'
import {doLoginAction,doLoginCurrentUserAction,doForgetPasswordAction} from '../../action/login'
import { connect } from 'react-redux'
import {loadCredentials} from '../../auth/'
import logo from '../../img/chrome.svg'

function selectState(state) {
  const router = state.router
  const next = router ? router.location.pathname.next : null
  return {
    userInfo: state.userInfo,
    router: state.router,
    next: next
  }
}

class Login extends React.Component {
  doForgetPassword = (username) => {
    this.props.dispatch(doForgetPasswordAction(username))
  }

  componentWillMount() {
  }

  componentDidMount() {
    const currentUser = loadCredentials()
    if(currentUser) {
      this.props.dispatch(doLoginCurrentUserAction(this.props.next || '/restaurant',this.props.dispatch))
    }
  }

  render() {
    const { dispatch, userInfo } = this.props
    const next = this.props.next || '/restaurant'

    return (
      <div style={{
          display:'flex',
          flexGrow:'1',
          justifyContent:'center',
          alignItems:'center'}}>
        <div id="login"
             style={{
              flexGrow:'1',
              height:'100%',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
           }}
        >
          <div style={{display:'flex',
                      flexDirection:'column',
              justifyContent:'center',
              alignItems:'center'
        }}>
            <LoginComponent
              loggingIn={userInfo.loggingIn}
              message={userInfo.loginMessage}
              doLogin={(username,password) => dispatch(doLoginAction(username,password,next,dispatch))}
              next={this.props.next}
              doForgetPassword={this.doForgetPassword}
            />
            <div style={{marginTop:'2rem',display:'flex'}}>
              <div style={{marginRight:'1rem',width:'2rem',height:'2rem',backgroundImage:`url(${logo})`}}></div>
              <span style={{color:'grey'}}>Optimized for Google Chrome</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(selectState)(Login)
