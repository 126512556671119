import React from 'react'
import PropTypes from "prop-types"

export default class StripeCustomer extends React.Component {

  render() {
    const customer = this.props.customer
    return (
      <div className="stripe-customer" onClick={this.props.select}>
        <div className="name">{customer.name}</div>
        <div className="email">{customer.email}</div>
        <div className="description">{customer.description}</div>
        <div className="product-name">{customer.productName}</div>
      </div>)
  }
}

StripeCustomer.propTypes = {
  customer: PropTypes.object,
  select: PropTypes.func
}