import Immutable from 'immutable'

export const Stats = Immutable.Record({
  reservations: null
})

export const Reservations = Immutable.Record({

  reservationsPerDay: Immutable.List(),
  reservations: Immutable.List(),
  reservationCountPerTime:Immutable.OrderedMap()
})

export const ReservationsPerDay = Immutable.Record({
  reservationCount: 0,
  reservedPlaces: 0,
  totalPlaces: 0,
  occupancy: 0,
  date: null
})

export const Reservation = Immutable.Record({
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  places: 0,
  lunchPrice: null,
  percentage: null,
  restaurantName: null,
  hour: 0,
  minute: 0,
  date: null
})