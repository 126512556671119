import React from 'react'
import {connect} from 'react-redux'
import {selectRestaurantAction, addDealAction, deleteRestaurantAction, setRestaurantFilterAction, setRestaurantGroupByZipcodeAction, convertToProspectAction} from '../../action/restaurant'
import RestaurantList from './list/restaurantList'
import RestaurantDeals from './deals/restaurantDeals'
import RestaurantTodayDeals from './deals/restaurantTodayDeals'
import RestaurantReservations from './reservations/restaurantReservations'
import EditDeal from './deals/editDeal'
import {Dialog, DialogTitle, DialogContent, Button, DialogActions} from "@material-ui/core"
import {RestaurantDetails} from "./details/restaurantDetails"
import RestaurantPictures from "./details/restaurantPictures"
import RestaurantDownloads from './restaurantDownloads'
import RestaurantSubscription from "./details/restaurantSubscription"
import {Elements} from "@stripe/react-stripe-js"
import {StripeToken} from "../../config"
import {Redirect} from "react-router"
import RestaurantListHeader from "./list/restaurantListHeader"
import GroupedRestaurantList from "./list/groupedRestaurantList"
import memoize from "memoize-one"
import {filterRestaurants} from "../../controller/restaurant"
import {loadStripe} from '@stripe/stripe-js'

const stripePromise = loadStripe(StripeToken)

function selectState(state, props) {
  return {
    history: props.history,
    restaurants: state.restaurantsInfo.restaurants,
    plans: state.data.plans,
    cuisines: state.data.cuisines,
    deals: state.restaurantsInfo.dealsForSelectedRestaurant,
    selectedRestaurant: state.restaurantsInfo.selectedRestaurant,
    reservations: state.restaurantsInfo.reservationsForSelectedRestaurant,
    subscription: state.restaurantsInfo.subscriptionForSelectedRestaurant,
    filter: state.restaurantsInfo.filter,
    groupByZipcode: state.restaurantsInfo.groupByZipcode
  }
}

class Restaurants extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showAddDeal: false,
      showDeleteRestaurantDialog: false,
      showConvertToProspectDialog: false
    }

    this.restaurantsToShowFilter = memoize((restaurants, textFilter) => {
      return filterRestaurants(restaurants, {
        textFilter
      })
    })

  }

  componentDidMount() {
    this.checkIfDataIsLoaded()
  }

  checkIfDataIsLoaded() {
    if (!this.props.restaurants.size) {
      return
    }

    const pathname = this.props.history.location.pathname
    const restaurantId = parseInt(pathname.split("/")[2])

    if (restaurantId && (!this.props.selectedRestaurant || this.props.selectedRestaurant.id !== restaurantId)) {
      if (this.restaurantIdBeingLoaded !== restaurantId) {
        this.restaurantIdBeingLoaded = restaurantId
        setTimeout(() => {
          this.props.dispatch(selectRestaurantAction(restaurantId))
        }, 1)
      }
    } else {
      this.restaurantIdBeingLoaded = null
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkIfDataIsLoaded()
  }

  selectRestaurant = (restaurant) => {
    this.props.history.push(`/restaurant/${restaurant.id}`)
  }

  showAddDeal = () => {
    this.setState({
      showAddDeal: true
    })
  }

  cancelAddDeal = () => {
    this.setState({
      showAddDeal: false
    })
  }

  addDeal = (deal) => {
    this.props.dispatch(addDealAction(this.props.selectedRestaurant, deal))
    this.setState({
      showAddDeal: false
    })
  }

  deleteRestaurant = () => {
    this.setState({showDeleteRestaurantDialog: true})
  }

  cancelDeleteRestaurant = () => {
    this.setState({showDeleteRestaurantDialog: false})
  }

  doDeleteRestaurant = () => {
    this.setState({showDeleteRestaurantDialog: false}, () =>
      this.props.dispatch(deleteRestaurantAction(this.props.selectedRestaurant)))
  }

  convertToProspect = () => {
    this.setState({showConvertToProspectDialog: true})
  }

  cancelConvertToProspect = () => {
    this.setState({showConvertToProspectDialog: false})
  }

  doConvertToProspect = () => {
    this.setState({showConvertToProspectDialog: false}, () =>
      this.props.dispatch(convertToProspectAction(this.props.selectedRestaurant)))
  }

  canConvertToProspect = () => {
    if (!this.props.selectedRestaurant)
      return false

    if (this.props.deals && this.props.deals.size)
      return false

    return true
  }

  render() {

    if (!this.props.selectedRestaurant) {
      if (!this.props.restaurants || this.props.restaurants.size === 0) {
        return <div></div>
      } else {
        const pathname = this.props.history.location.pathname
        const restaurantId = parseInt(pathname.split("/")[2])

        if (!restaurantId) {
          const restaurant = this.props.restaurants.get(0)
          const url = `/restaurant/${restaurant.id}`
          return <Redirect to={url}/>
        } else {
          return <div></div>
        }
      }
    }

    let subscription = <div></div>
    const restaurantId = this.props.selectedRestaurant.id

    if (this.props.plans) {
      if (this.props.subscription) {
        subscription = <RestaurantSubscription
          key={(this.props.subscription.id ? this.props.subscription.id : "null") + restaurantId}
          restaurant={this.props.selectedRestaurant}
          dispatch={this.props.dispatch}
          subscription={this.props.subscription}
          plans={this.props.plans}
        />
      } else {
        subscription = <RestaurantSubscription
          key={"null" + restaurantId}
          restaurant={this.props.selectedRestaurant}
          dispatch={this.props.dispatch}
          subscription={this.props.subscription}
          plans={this.props.plans}
        />
      }
    }

    const deleteRestaurantActions = [
      <Button
        key={1}
        color="primary"
        onClick={this.cancelDeleteRestaurant}
      >Cancel</Button>,
      <Button
        key={2}
        variant="contained"
        color="secondary"
        onClick={this.doDeleteRestaurant}
      >Delete</Button>
    ]

    const convertToProspectActions = [
      <Button
        key={1}
        color="primary"
        onClick={this.cancelConvertToProspect}
      >Cancel</Button>,
      <Button
        key={2}
        variant="contained"
        color="secondary"
        onClick={this.doConvertToProspect}
      >Convert</Button>
    ]

    let addDeal = <div></div>

    if (this.props.subscription && this.props.plans) {
      addDeal =
        <EditDeal cancel={this.cancelAddDeal} save={this.addDeal} show={this.state.showAddDeal} deals={this.props.deals}
                  subscription={this.props.subscription}
                  key={this.props.subscription.id}
                  plans={this.props.plans}
        />
    }

    let restaurantDetail = <div></div>

    if (this.props.cuisines) {
      restaurantDetail = <RestaurantDetails key={this.props.selectedRestaurant.id} cuisines={this.props.cuisines}
                                            restaurant={this.props.selectedRestaurant}
                                            dispatch={this.props.dispatch}/>
    }

    let restaurantList = <div></div>
    const restaurants = this.restaurantsToShowFilter(this.props.restaurants, this.props.filter)

    if (this.props.groupByZipcode) {
      restaurantList = <GroupedRestaurantList
        restaurants={restaurants}
        selectedRestaurant={this.props.selectedRestaurant}
        selectRestaurant={this.selectRestaurant}/>

    } else {
      restaurantList = <RestaurantList
        restaurants={restaurants}
        selectedRestaurant={this.props.selectedRestaurant}
        selectRestaurant={this.selectRestaurant}/>
    }

    return <div className="restaurants">
      <div className="restaurants-list">
        <RestaurantListHeader
          filter={this.props.filter}
          groupByZipcode={this.props.groupByZipcode}
          updateFilter={(filter) => this.props.dispatch(setRestaurantFilterAction(filter))}
          updateGroupByZipcode={(group) => this.props.dispatch(setRestaurantGroupByZipcodeAction(group))}
        />
        <div className="separator"/>
        <div className="items">
          {restaurantList}
        </div>
      </div>
      <div className="detail">
        <div className="section">
          <h3>Deals</h3>
          <RestaurantDeals restaurant={this.props.selectedRestaurant} deals={this.props.deals}
                           dispatch={this.props.dispatch} subscription={this.props.subscription} plans={this.props.plans}/>
          <Button variant="contained" color="secondary" onClick={this.showAddDeal}
                  disabled={this.props.selectedRestaurant == null || this.props.subscription == null}>Add</Button>
        </div>
        <div className="section">
          <h3>Today's Deals</h3>
          <RestaurantTodayDeals restaurant={this.props.selectedRestaurant} deals={this.props.deals}
                                dispatch={this.props.dispatch}/>
        </div>
        <div className="section">
          <h3>Today's Reservations</h3>
          <RestaurantReservations reservations={this.props.reservations} dispatch={this.props.dispatch}/>
        </div>
        <div className="section">
          <h3>Details</h3>
          {restaurantDetail}
        </div>
        <div className="section">
          <h3>Subscription</h3>
          <Elements stripe={stripePromise}>
            {subscription}
          </Elements>
        </div>
        <div className="section">
          <h3>Pictures</h3>
          <RestaurantPictures key={this.props.selectedRestaurant.id} restaurant={this.props.selectedRestaurant}
                              dispatch={this.props.dispatch}/>
        </div>
        <div className="section">
          <h3>Downloads</h3>
          <RestaurantDownloads restaurant={this.props.selectedRestaurant} dispatch={this.props.dispatch}/>
        </div>
        <div className="action-buttons">
          <div style={{marginTop: '2rem'}} className="delete">
            <Button variant="contained" color="secondary" onClick={this.deleteRestaurant}
                    disabled={this.props.selectedRestaurant == null}>Delete Restaurant</Button>
          </div>
          <div style={{marginTop: '2rem'}} className="delete">
            <Button variant="contained" color="secondary" onClick={this.convertToProspect}
                    disabled={!this.canConvertToProspect()}>Convert to Prospect</Button>
          </div>
        </div>
      </div>
      {addDeal}
      <Dialog
        modal={false}
        open={this.state.showDeleteRestaurantDialog}
        onClose={this.cancelDeleteRestaurant}
      >
        <DialogTitle>Delete Restaurant</DialogTitle>
        <DialogContent>Do you want to delete this restaurant?</DialogContent>
        <DialogActions>{deleteRestaurantActions}</DialogActions>
      </Dialog>

      <Dialog
        modal={false}
        open={this.state.showConvertToProspectDialog}
        onClose={this.cancelConvertToProspect}
      >
        <DialogTitle>Convert Restaurant to Prospect</DialogTitle>
        <DialogContent>Do you want to convert this restaurant to a prospect?</DialogContent>
        <DialogActions>{convertToProspectActions}</DialogActions>
      </Dialog>
    </div>
  }
}

export default connect(selectState)(Restaurants)
