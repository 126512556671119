import React from 'react'
import PropTypes from 'prop-types'
import TodayDeal from './todayDeal'
import moment from 'moment'

export default class RestaurantTodayDeals extends React.Component {


  render() {
    if (!this.props.deals)
      return <div></div>

    const todayIndex = (parseInt(moment().format("d")) + 6) % 7

    const todayDeals = this.props.deals.filter((deal) => {
      return deal.days.get(todayIndex) === true
    })

    const elements = todayDeals.map((deal) => {
      return <TodayDeal restaurant={this.props.restaurant} deal={deal} key={deal.id} dispatch={this.props.dispatch}/>
    })

    return <div className="today-deals">
      <div className="header">
        <div className="type">Type</div>
        <div className="price-or-discount">Price or Discount</div>
        <div className="places">Places</div>
        <div className="available-places">Available Places</div>
      </div>
      {elements}
    </div>
  }
}

RestaurantTodayDeals.propTypes = {
  restaurant: PropTypes.object,
  deals:PropTypes.object,
  dispatch:PropTypes.func
}