import {postData,getData} from '../network'
import {createAction, createPromiseAction, actionType, actionArguments, actionMetaData,actionResult} from './index'
import {getAllDataAction} from './data'
import {Login,LoggedInUser} from '../model/login'
import {saveCredentials,deleteCredentials,currentUser} from '../auth'
import {history} from "../history"
const LOGIN = 'LOGIN'
const LOGIN_CURRENT = 'LOGIN_CURRENT'
const LOGOUT = 'LOGOUT'
const FORGET_PASSWORD = 'FORGET_PASSWORD'

export function doLoginAction(username, password, next, dispatch) {
  const promise = postData('login',{email:username, password},false)

  return createPromiseAction(LOGIN,promise,{username,next,dispatch})
}

export function doLoginCurrentUserAction(next, dispatch,history) {
  const promise = getData('login',false)

  return createPromiseAction(LOGIN_CURRENT,promise,{next,dispatch,history})
}

export function doLogoutAction(dispatch) {

  return createAction(LOGOUT,{},{dispatch})
}

export function doForgetPasswordAction(username, callback) {
  const promise = postData('admin/forgotpassword',{email:username},false)

  return createPromiseAction(FORGET_PASSWORD,promise,{callback})
}

export function reduceLogin(state = null, action = null) {
  if(!state) return new Login()

  let nextState = state
  const meta = actionMetaData(action)
  const result = actionResult(action)

  switch(actionType(action)) {
    case `${LOGIN}_BEGIN`:
    case `${LOGIN_CURRENT}_BEGIN`:
      nextState = nextState.set('loggingIn',true)
      break
    case `${LOGIN_CURRENT}_SUCCESS`:
      nextState = new Login({loggedInUser:currentUser,loggedIn:true})
      setTimeout(()=>{
        history.push(meta.next)
        meta.dispatch(getAllDataAction())
      },100)
      break
    case `${LOGIN}_SUCCESS`:
      const user = new LoggedInUser({
        id:result.user.id,
        token:result.user.token,
        email:meta.username
      })

      nextState = new Login({loggedInUser:user,loggedIn:true})

      saveCredentials(user)

      setTimeout(()=>{
        history.push(meta.next)
        meta.dispatch(getAllDataAction())
      },100)

      break
    case `${LOGIN}_ERROR`:
    case `${LOGIN_CURRENT}_ERROR`:
      nextState = new Login({loggingIn:false,loggedIn:false,loginMessage:action.payload.statusText})
      deleteCredentials()
      break
    case LOGOUT:
      nextState = new Login()
      deleteCredentials()
      setTimeout(()=>{
        history.push('/login')
      },100)

      break
    case `${FORGET_PASSWORD}_SUCCESS`:
      nextState.loginMessage = 'Password reset mail sent'
      break
    case `${FORGET_PASSWORD}_ERROR`:
      nextState.loginMessage = action.payload.statusText
      break

  }

  return nextState
}
