import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from "@material-ui/core"
import PropTypes from 'prop-types'

export default class SendProspectMailDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {language: "fr"}
  }

  render() {

    return (
      <Dialog
        open={this.props.show}
      >
        <DialogTitle>Send Mail To Prospect</DialogTitle>
        <DialogContent>
          <div>Please select a language and push 'Send'</div>
          <Select
            style={{marginTop: '1rem'}}
            value={this.state.language} onChange={(event) => {
            this.setState({language: event.target.value})
          }
          }>
            <MenuItem value="nl" key="nl">nl</MenuItem>
            <MenuItem value="fr" key="fr">fr</MenuItem>
          </Select>

        </DialogContent>
        <DialogActions>
          <Button
            key={1}
            color="primary"
            onClick={this.props.close}
          >Cancel</Button>
          <Button
            key={2}
            variant="contained"
            color="secondary"
            onClick={() => {
              this.props.send(this.state.language)
            }}
          >Send</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

SendProspectMailDialog.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  send: PropTypes.func
}