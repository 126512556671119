import React, {createRef} from 'react'
import PropTypes from 'prop-types'
import {Checkbox, Button, TextField, FormControlLabel, MenuItem, Select} from "@material-ui/core"
import ModifyPaymentMethodDialog from '../../component/modifyPaymentMethodDialog'
import {ElementsConsumer} from '@stripe/react-stripe-js';
import {updateSubscriptionAction} from "../../../action/restaurant"
import {updateSubscription} from "../../../controller/restaurant"
import {getPaymentErrorDescription} from "../../../controller/stripe"
import {planToMenuItem} from "../../component/planDropdownItem"

class RestaurantSubscription extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      planId: props.subscription ? props.subscription.planId : null,
      isFree: props.subscription ? props.subscription.isFree : false,
      showModifyPaymentDialog: false,
      paymentMethod: null
    }
  }

  changePlan = (planId) => {
    this.setState({planId})
  }

  changeIsFree = (isFree) => {
    this.setState({isFree})
  }

  getCurrentPlanId = () => {
    return this.props.subscription ? this.props.subscription.planId : null
  }

  getCurrentIsFree = () => {
    return this.props.subscription ? this.props.subscription.isFree : false
  }

  validateSave = () => {

    // no plan, no save
    if (this.state.planId === null)
      return false

    let somethingChanged = false

    // there is a plan selected
    if (this.getCurrentPlanId() !== this.state.planId)
      somethingChanged = true

    // the free status was changed
    if (this.getCurrentIsFree() !== this.state.isFree)
      somethingChanged = true

    if(this.state.paymentMethod)
      somethingChanged = true

    if (!somethingChanged)
      return false

    if (this.state.isFree)
      return true

    // only allow paid subscriptions if the restaurant already has a payment method
    // or if a new one was selected

    const paymentMethod = this.props.subscription ? this.props.subscription.paymentMethod : null

    return (paymentMethod || this.state.paymentMethod)
  }

  changePaymentMethod = () => {
    this.setState({
      showModifyPaymentDialog: true
    })
  }

  saveSubscription = async () => {
    const result = await updateSubscription(this.props.restaurant, this.state.planId, this.state.paymentMethod, this.state.isFree)

    if (result.error) {
      console.log(result.error)
      alert(getPaymentErrorDescription(result.error))
      return
    }

    if (result.subscription) {
      this.setState({
        paymentMethod: null
      })

      this.props.dispatch(updateSubscriptionAction(this.props.restaurant, result.subscription))
      return
    }

    const intent = result.paymentIntent
    const nextAction = intent.next_action

    if (nextAction.redirect_to_url) {
      const url = nextAction.redirect_to_url.url
      window.location = url
    }

  }

  render() {
    const plans = this.props.plans.map(planToMenuItem)

    let paymentMethodButtonText = 'Payment Method'

    if (this.state.paymentMethod) {
      paymentMethodButtonText = 'New Payment Selected'
    } else {
      const paymentMethod = this.props.subscription ? this.props.subscription.paymentMethod : null
      if (paymentMethod && paymentMethod.description) {
        paymentMethodButtonText = paymentMethod.description
      }
    }

    return (<ElementsConsumer>
      {({stripe, elements}) => (
        <div>
          <div className="restaurant-subscription">
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Select value={this.state.planId || ""} onChange={(event) => {
                this.changePlan(event.target.value)
              }} displayEmpty>
                <MenuItem value="" disabled>Plan</MenuItem>
                {plans}
              </Select>

              <Button variant="outlined" color="secondary" onClick={this.changePaymentMethod}
                      style={{marginLeft: '1rem'}}
                      disabled={this.state.isFree}>{paymentMethodButtonText}</Button>
              <FormControlLabel
                style={{marginLeft: '1rem'}}
                control={<Checkbox checked={this.state.isFree} onChange={(ev, checked) => {
                  this.changeIsFree(checked)
                }}/>}
                label="Free Subscription"
              />
            </div>
          </div>
          <Button variant="contained" color="secondary" onClick={this.saveSubscription}
                  disabled={!this.validateSave()}>Save Subscription</Button>
          <ModifyPaymentMethodDialog
            email={this.props.restaurant.email}
            stripe={stripe}
            elements={elements}
            token={this.state.token}
            show={this.state.showModifyPaymentDialog}
            cancel={() => {
              this.setState({showModifyPaymentDialog: false}, this.validateSave)
            }}
            setPaymentMethod={(paymentMethod) => {
              this.setState({showModifyPaymentDialog: false, paymentMethod}, this.validateSave)
            }}
          />
        </div>
      )}
    </ElementsConsumer>)
  }
}

RestaurantSubscription.propTypes = {
  restaurant: PropTypes.object,
  dispatch: PropTypes.func,
  subscription: PropTypes.object,
  plans: PropTypes.object,
  changePlan: PropTypes.func
}

export default RestaurantSubscription
