import React from 'react'
import PropTypes from 'prop-types'
import {Button, DialogTitle, DialogContent, DialogActions, Dialog, TextField} from "@material-ui/core"
import {CardElement, IbanElement, Elements} from '@stripe/react-stripe-js'
import {getSetupIntent} from '../../controller/stripe'
import {loadStripe} from '@stripe/stripe-js'

export default class ModifyPaymentMethodDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: "",
      zipCode: "",
      showCard: true,
      cardComplete: false,
      ibanComplete: false,
      setupIntentSecret:null,
      disabled:false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if we show the dialog, get the intent and block the gui while doing it
    if(!prevProps.show && this.props.show) {
      this.setState({disabled:true},() => {
        getSetupIntent().then((setupIntentSecret) => {
          this.setState({setupIntentSecret,disabled:false})
        })
      })
    }
  }


  createOptions = (fontSize) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };

  save = async () => {
    let result = null
    if (this.state.cardComplete) {
      result = await this.props.stripe.confirmCardSetup(this.state.setupIntentSecret, {
        payment_method: {
          card: this.props.elements.getElement(CardElement),
          billing_details: {
            name: this.state.name,
            address: {
              postal_code: this.state.zipCode
            }
          },
        }
      })
    } else {
      result = await this.props.stripe.confirmSepaDebitSetup(this.state.setupIntentSecret, {
        payment_method: {
          sepa_debit: this.props.elements.getElement(IbanElement),
          billing_details: {
            email: this.props.email,
            name: this.state.name,
            address: {
              postal_code: this.state.zipCode
            }
          },
        }
      })
    }

    if (result.error) {
      console.log(result.error)
    } else {
      this.props.setPaymentMethod(result.setupIntent.payment_method)
     }
  }

  validateSaveButton = () => {
    return this.state.name.length > 2 && ((this.state.cardComplete) || (this.state.ibanComplete && this.state.zipCode.length))
  }

  togglePaymentMethod = () => {
    this.setState({showCard: !this.state.showCard})
  }

  showIban = () => {
    if (!this.state.showCard)
      return

    this.setState({showCard: false, cardComplete: false, ibanComplete: false})
  }

  showCreditCard = () => {
    if (this.state.showCard)
      return

    this.setState({showCard: true, cardComplete: false, ibanComplete: false})
  }

  render() {
    let paymentElementToShow = null

    if (this.state.showCard) {
      paymentElementToShow = <CardElement
        onChange={(event) => {
          this.setState({cardComplete: event.complete})
        }}
        options={this.createOptions('16px')}
      />
    } else {
      paymentElementToShow = <IbanElement
        onChange={(event) => {
          this.setState({ibanComplete: event.complete})
        }}
        options={{
          ...this.createOptions('16px'),
          supportedCountries: ['SEPA'],
          placeholderCountry: "BE"
        }}
      />
    }

    const actions = [
      <Button
        key="cancel"
        color="primary"
        onClick={this.props.cancel}
      >Cancel</Button>,
      <Button variant="contained"
              key="add"
              color="secondary"
              disabled={!this.validateSaveButton()}
              onClick={this.save}
      >Save</Button>
    ]

    const className = this.state.disabled ? 'disabled' : ''

    return (<Dialog
      open={this.props.show}
      onClose={this.props.cancel}
      fullWidth={true}
      className={className}
    >
      <DialogTitle>Set Payment Method</DialogTitle>
      <DialogContent>
        <TextField
          style={{width: '20rem', marginBottom: '2rem'}}
          value={this.state.name}
          label="Name"
          onChange={(event) => this.setState({name: event.target.value})}
        />
        <TextField
          style={{width: '20rem', marginBottom: '2rem'}}
          value={this.state.zipCode}
          label="Zip Code"
          onChange={(event) => this.setState({zipCode: event.target.value})}
        />
        <div style={{display: 'flex', alignContent: 'center', alignItems: 'center'}}>
          <Button variant={this.state.showCard ? "outlined" : "contained"}
                  color="secondary"
                  onClick={this.showIban}
          >IBAN</Button>
          <div style={{marginLeft: '10px', marginRight: '10px'}}>OR</div>
          <Button variant={!this.state.showCard ? "outlined" : "contained"}
                  color="secondary"
                  onClick={this.showCreditCard}
          >Credit Card</Button>
        </div>
        <div style={{height: '20px'}}/>
        {paymentElementToShow}

      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>)
  }
}

ModifyPaymentMethodDialog.propTypes = {
  email : PropTypes.string,
  stripe: PropTypes.object,
  elements: PropTypes.object,
  show: PropTypes.bool,
  cancel: PropTypes.func,
  setPaymentMethod:PropTypes.func
}

