import {createAction, createPromiseAction, actionType, actionArguments, actionMetaData,actionResult} from './index'
import {loadStatistics} from '../controller/stats'
import {Stats} from '../model/stats'

const STATS = 'STATS'

export function doLoadMainStatsAction() {

  const promise = loadStatistics()

  return createPromiseAction(STATS,promise,{})
}

export function reduceStats(state = null, action = null) {
  if(!state) return new Stats()

  let nextState = state
  const meta = actionMetaData(action)
  const result = actionResult(action)

  switch(actionType(action)) {
    case `${STATS}_BEGIN`:
      break
    case `${STATS}_SUCCESS`:
      const reservations = result

      nextState = nextState.set('reservations',reservations)
      break
    case `${STATS}_ERROR`:
      break
  }

  return nextState
}

