import {getData} from '../network'
import {Restaurant} from '../model/restaurant'
import Immutable from 'immutable'

export async function getDormants() {
  const result = await getData('dormant')
  const restaurants = result.restaurants

  return Immutable.List(restaurants.map((json) => Restaurant.fromJson(json))).sort((restaurant1, restaurant2) => {
    return restaurant1.name.localeCompare(restaurant2.name)
  })
}
