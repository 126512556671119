import React from 'react'
import PropTypes from 'prop-types'
import Deal from './deal'
import TodayDeal from "./todayDeal"

export default class RestaurantDeals extends React.Component {


  render() {

    if(!this.props.deals) {
      return <div></div>
    }

    const deals = this.props.deals.map((deal) => {
      return <Deal key={deal.id} deal={deal} restaurant={this.props.restaurant} dispatch={this.props.dispatch} subscription={this.props.subscription} plans={this.props.plans}/>
    })

    return <div className="deals">
      <div className="header">
        <div className="time">Time</div>
        <div className="lunch">Lunch</div>
        <div className="dinner">Dinner</div>
        <div className="days">
          <div className="day">Mon</div>
          <div className="day">Tue</div>
          <div className="day">Wed</div>
          <div className="day">Thu</div>
          <div className="day">Fri</div>
          <div className="day">Sat</div>
          <div className="day">Sun</div>
        </div>
        <div className="places">Places</div>
        <div className="price-or-discount">Discount</div>
      </div>
      {deals}
      </div>
  }
}

RestaurantDeals.propTypes = {
  restaurant: PropTypes.object,
  deals:PropTypes.object,
  subscription: PropTypes.object,
  plans: PropTypes.object,
  dispatch:PropTypes.func

}
