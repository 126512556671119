import React from "react"
import PropTypes from "prop-types"
import {Button} from "@material-ui/core"
import MonthPicker from "../../component/monthPicker"
import {doDownloadDailyReservationsAction, doDownloadDailyReservationsForRestaurantAction} from "../../action/download"
import moment from 'moment'

export default class RestaurantDownloads extends React.Component {

  constructor(props) {
    super(props)
    const currentMonth = moment().format("YYYYMM")

    this.state = {dailyReservationsMonthValue: currentMonth}
  }

  downloadDailyReservations = () => {
    this.props.dispatch(doDownloadDailyReservationsForRestaurantAction(this.state.dailyReservationsMonthValue,this.props.restaurant))
  }


  render() {
    return <div>
      <div className="download-combo">
        <Button variant="contained" style={{width:'300px'}} onClick={this.downloadDailyReservations}>Download Daily Reservations</Button>
        <span style={{width:'1rem'}}/>
        <MonthPicker value={this.state.dailyReservationsMonthValue} updateValue={(val) => {
          this.setState({dailyReservationsMonthValue: val})
        }}/>
      </div>
    </div>
  }
}

RestaurantDownloads.propTypes = {
  restaurant: PropTypes.object,
  dispatch: PropTypes.func
}