import React from 'react'
import PropTypes from 'prop-types'
import RestaurantListItem from './restaurantListItem'
import {FixedSizeList as List} from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

export default class RestaurantList extends React.Component {

  constructor(props) {
    super(props)
    this.listRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedRestaurant && !this.doneScroll) {
      const indexInScrollableList = this.props.restaurants.indexOf(this.props.selectedRestaurant)
      setTimeout(() => {
        this.listRef.current.scrollToItem(indexInScrollableList)

      }, 1000)
      this.doneScroll = true
    }
  }

  render() {
    if (this.props.restaurants.size === 0)
      return <div></div>

    const restaurants = this.props.restaurants
    const row = ({index, style}) => {
      const restaurant = restaurants.get(index)
      const isSelected = this.props.selectedRestaurant ? (restaurant.id === this.props.selectedRestaurant.id) : false

      return (
        <div style={style}
             key={restaurant.id}>
          <RestaurantListItem isSelected={isSelected}
                              key={restaurant.id}
                              restaurant={restaurant}
                              selectRestaurant={() => {
                                this.props.selectRestaurant(restaurant)
                              }}/>
        </div>
      )
    }

    return (
      <AutoSizer>
        {({height, width}) => (
          <List
            ref={this.listRef}
            height={height}
            width={width}
            itemCount={restaurants.size}
            itemSize={59}
          >
            {row}
          </List>
        )}
      </AutoSizer>
    )
  }
}

RestaurantList.propTypes = {
  restaurants: PropTypes.object,
  selectedRestaurant: PropTypes.object,
  selectRestaurant: PropTypes.func
}
