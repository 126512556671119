import Immutable from 'immutable'

export const RestaurantsInfo = Immutable.Record({
  restaurants: Immutable.List(),
  selectedRestaurant: null,
  reservationsForSelectedRestaurant: null,
  dealsForSelectedRestaurant: null,
  subscriptionForSelectedRestaurant: null,
  groupByZipcode:false,
  filter:""
})

export const Restaurant = Immutable.Record({
  id: null,
  braintreeCustomerId:null,
  stripeCustomerId:null,
  tablebookerId:null,
  name: null,
  company: null,
  street: null,
  city: null,
  country: null,
  zipcode: null,
  phone: null,
  email: null,
  emailNotif: null,
  website: null,
  lat: null,
  lon: null,
  vat: null,
  comments: '',
  contactedByPhone:false,
  noPotential:false,
  cuisines: Immutable.List()
})

Restaurant.fromJson = (json) => {
  let resto = new Restaurant(json)
  let cuisines = Immutable.List(json.cuisines.map(cuisine => new Cuisine(cuisine)))

  return resto.set('cuisines',cuisines)
}

export const Cuisine = Immutable.Record({
  id: null,
  name: null
})

export const Reservation = Immutable.Record({
  id: null,
  dayDealId: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  places: 0,
  lunchPrice: null,
  percentage: null,
  date: null,
  hour: null,
  minute: null
})

export const Deal = Immutable.Record({
  id: null,
  startHour: "00",
  startMinute: "00",
  lunch:false,
  dinner:true,
  pause:false,
  endHour: 0,
  endMinute: 0,
  percentage: null,
  places: 0,
  availablePlaces: null,
  dayDealId: null,
  lunchPrice: null,
  days: Immutable.List()
})

Deal.fromJson = (json) => {
  let deal = new Deal(json)
  deal = deal.set('days', Immutable.List(json.days))
  deal = deal.set('startHour', ("00" + json.startHour).slice(-2))
  deal = deal.set('startMinute', ("00" + json.startMinute).slice(-2))
  deal = deal.set('endHour', ("00" + json.endHour).slice(-2))
  deal = deal.set('endMinute', ("00" + json.endMinute).slice(-2))

  return deal
}

export const mandatoryFields = ['name', 'company', 'street', 'city', 'zipcode', 'email']
