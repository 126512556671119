import React from 'react'
import {Link} from 'react-router-dom'
import {Button, Toolbar} from '@material-ui/core'

import {TopbarNavigation} from './topbarNavigation'
import {doLogoutAction} from '../../action/login'
import {networkEventEmitter, ajaxStartEvent, ajaxStopEvent, ajaxInProgress} from '../../network'
import {history} from "../../history"

const pjson = require('../../../package.json');
const version = pjson.version
const backgroundImage = require('../../img/ajax.gif')
const proLogo1x = require('../../img/proLogo.png')
const proLogo2x = require('../../img/proLogo@2x.png')

export default class Topbar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {ajaxLoad: ajaxInProgress()}
  }

  componentDidMount = () => {
    networkEventEmitter.addListener(ajaxStartEvent, this.ajaxStart)
    networkEventEmitter.addListener(ajaxStopEvent, this.ajaxStop)
  }

  componentWillUnmount = () => {
    networkEventEmitter.removeListener(ajaxStartEvent, this.ajaxStart)
    networkEventEmitter.removeListener(ajaxStopEvent, this.ajaxStop)
  }

  ajaxStart = () => {
    this.setState({ajaxLoad: true})
  }

  ajaxStop = () => {
    this.setState({ajaxLoad: false})
  }

  logout = () => {
    const {dispatch} = this.props
    dispatch(doLogoutAction(dispatch))
  }

  render() {
    let rightButton = null
    const loggedIn = this.props.userInfo.loggedInUser != null
    const loggingIn = this.props.userInfo.loggingIn

    const activeLocation = history.location.pathname

    let tabs = <TopbarNavigation loggedIn={loggedIn}
                                 activeTab={activeLocation}/>

    if (loggedIn) {
      rightButton = <Button variant="contained"
                            color="primary"
                            onClick={this.logout}
      >Log out</Button>
    } else {
      if (loggingIn) {
        rightButton = <span/>

      } else {

        const link = React.forwardRef((props, ref) => <Link to='/login' innerRef={ref} {...props} />);

        rightButton = <Button variant="contained"
                              component={link}
                              color="primary"
        >Log in</Button>
      }
    }

    const display = this.state.ajaxLoad ? 'inline-block' : 'none'

    return (
      <Toolbar className="top-bar">
        <div style={{display:'flex',alignItems:'center'}}>
          <img src={proLogo1x} srcSet={proLogo1x + ' 1x,' + proLogo2x + ' 2x'}/>
          <div className="version">{version}</div>
          <span className="ajax-loader"
                style={{
                  width: '3.2rem',
                  height: '3rem',
                  display: display,
                  backgroundImage: `url(${backgroundImage})`
                }}
          >
            </span>
        </div>
        <div className="tabs">
          {tabs}
          <div></div>
          {rightButton}
        </div>

      </Toolbar>
    );
  }
}
