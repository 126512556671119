import store from 'store'
import {LoggedInUser,isAdmin} from '../model/login'
import crypto from 'crypto'
import moment from 'moment'


export let currentUser = null

export function saveCredentials(user) {
  if (store.enabled) {
    store.set('user', user.toJS())
  }

  currentUser = user
}

export function deleteCredentials() {
  if (store.enabled) {
    store.remove('user')
  }

  currentUser = null
}

export function loadCredentials() {
  if (store.enabled) {
    currentUser = store.get('user')
    if (currentUser)
      currentUser = new LoggedInUser(currentUser)

    return currentUser
  }

  return null
}

export function updateCurrentUserToken(token) {
  currentUser = currentUser.set('token', token)
  saveCredentials(currentUser)
}

export function getCurrentUserToken() {
  if (!currentUser)
    return null

  return currentUser.token
}

export function getCurrentUserEmail() {
  if (!currentUser)
    return null

  return currentUser.email
}

export function isCurrentUserAdmin() {
  if (!currentUser)
    return null

  return isAdmin(currentUser)

}

export function buildSecurityHeader() {
  const userId = currentUser.id
  const token = currentUser.token
  const nonceString = "00000000000000000000000000000000"
  const nonce = Buffer.from(nonceString, "hex")
  const now = moment().valueOf()
  const passHash = token

  const identifierString = `${userId}:${now}`
  const signature = aesEncryptString(identifierString, passHash, nonce)

  const value = `BJS1-HMAC-SHA256 id=${userId}, signature=${signature}, nonce=${nonceString}`

  return value
}

function aesEncryptString(data, key, nonce) {
  const cipher = crypto.createCipheriv("aes-128-ctr", new Buffer(key, "hex"), new Buffer(nonce, "hex"))
  let result = cipher.update(data, "utf8", "hex")
  result += cipher.final('hex')
  return result
}
