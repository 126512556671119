import React from 'react'
import PropTypes from "prop-types"
import SelectStripeCustomer from "./selectStripeCustomer"
import {Button, MenuItem, Select, TextField, FormControlLabel} from "@material-ui/core"
import {convertToRestaurantAction} from "../../../../action/prospect"

const md5 = require("md5")

export default class ConvertToExistingStripeCustomer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      password: "",
      email:"",
      selectedCustomer: null,
      language: "fr"
    }
  }

  convert = () => {
    this.props.dispatch(convertToRestaurantAction(this.props.prospect, this.state.selectedCustomer.id, md5(this.state.password),this.state.email,this.state.language))
  }

  validate = () => {
    const result = (this.state.password && this.state.password.length >= 6) && (this.state.email.length > 3)

    if(result) {
      this.props.setDialogActions([
        <Button variant="contained" key="convert" color="primary" disabled={!result}
                onClick={this.convert}>Convert</Button>
      ])
    } else {
      this.props.setDialogActions([])
    }
  }

  selectCustomer = (customer) => {
    this.setState({selectedCustomer: customer,email:customer.email})
    this.validate()
  }

  render() {

    let body = <div></div>

    if (!this.state.selectedCustomer) {
      body = <SelectStripeCustomer customers={this.props.customers} select={this.selectCustomer}/>
    } else {
      const selectedCustomer = this.state.selectedCustomer
      body = <div>
        <h3>Stripe Customer</h3>
        <div className="selected-customer">
          <div>Name: {selectedCustomer.name}</div>
          <div>Description: {selectedCustomer.description}</div>
          <div>Product: {selectedCustomer.productName}</div>
        </div>
        <div>
        <TextField
          style={{marginRight: '1rem',marginTop:'1rem'}}
          type="text"
          value={this.state.email}
          label="Email"
          onChange={(event) => this.setState({email: event.target.value},this.validate)}
        />
        </div>
        <div style={{marginTop: '1rem', display: 'flex'}}>
          <TextField
            style={{marginRight: '1rem'}}
            type="password"
            value={this.state.password}
            label="Password"
            onChange={(event) => this.setState({password: event.target.value},this.validate)}
          />
          <Select value={this.state.language} onChange={(event) => {
            this.setState({language: event.target.value})
          }
          }>
            <MenuItem value="nl" key="nl">nl</MenuItem>
            <MenuItem value="fr" key="fr">fr</MenuItem>
          </Select>
        </div>
      </div>
    }

    return (<div>
      {body}
    </div>)
  }
}

ConvertToExistingStripeCustomer.propTypes = {
  customers: PropTypes.object,
  dispatch: PropTypes.func,
  prospect: PropTypes.object,
  setDialogActions: PropTypes.func
}
