import {getData, putData, deleteData, postData} from '../network'
import {Restaurant, Cuisine, Reservation, Deal} from '../model/restaurant'
import Immutable from 'immutable'
import {Plan, Subscription, PaymentMethod} from '../model/subscription'
import {SiteRootUrl} from "../config"
import memoize from "memoize-one"

export async function getRestaurants() {
  const result = await getData('restaurant')
  const restaurants = result.restaurants

  return Immutable.List(restaurants.map((json) => Restaurant.fromJson(json))).sort((restaurant1, restaurant2) => {
    return restaurant1.name.localeCompare(restaurant2.name)
  })
}

export async function getCuisines() {
  const result = await getData('cuisine')
  const cuisines = result.cuisines

  return Immutable.List(cuisines.map((json) => new Cuisine(json))).sort((cuisine1, cuisine2) => {
    return cuisine1.name.localeCompare(cuisine2.name)
  })
}

export async function getPlans() {
  const result = await getData('subscription/plan')
  const plans = result.plans

  return Immutable.List(plans.map((json) => Plan.fromJson(json)))
}

export async function getDataForRestaurant(id) {

  const reservationsResult = await getData(`restaurant/${id}/todayreservation`)
  const dealsResult = await getData(`restaurant/${id}/deal`)
  const subscriptionResult = await getData(`restaurant/${id}/subscription`)

  const reservationsTable = reservationsResult.reservations
  const dealsTable = dealsResult.deals

  const reservations = Immutable.List(reservationsTable.map((json) => new Reservation(json)))
  const deals = Immutable.List(dealsTable.map((json) => {
    return Deal.fromJson(json)
  }))

  let subscription = null

  if (subscriptionResult) {
    const paymentMethod = new PaymentMethod(subscriptionResult.paymentMethod)

    if (subscriptionResult.planId) {
      subscription = new Subscription({
        id: subscriptionResult.info.id,
        isFree: subscriptionResult.isFree,
        planId: subscriptionResult.planId,
        paymentMethod
      })
    }
  }
  return {reservations, deals, subscription}
}

export async function setDayDealAvailablePlaces(deal, availablePlaces) {
  return putData(`dayDeal/${deal.dayDealId}`, {availablePlaces})
}

export async function deleteDayDeal(restaurant, deal) {
  return deleteData(`restaurant/${restaurant.id}/dayDeal/${deal.dayDealId}`)
}

export async function deleteDeal(restaurant, deal) {
  return deleteData(`restaurant/${restaurant.id}/deal/${deal.id}`)
}

export async function updateDeal(restaurant, deal) {
  return putData(`restaurant/${restaurant.id}/deal/${deal.id}`,deal)
}

export async function addDeal(restaurant, deal) {
  return postData(`restaurant/${restaurant.id}/deal/`, deal)
}

export async function cancelReservation(reservation) {
  return deleteData(`reservation/${reservation.id}`)
}

export async function updateRestaurantInfo(restaurant) {
  return putData(`restaurant/${restaurant.id}`, restaurant)
}

export async function deleteRestaurant(restaurant) {
  return deleteData(`restaurant/${restaurant.id}`)
}

export async function updatePictures(restaurant, picture, menu, menuMimeType) {
  if (picture) {
    await putData(`restaurant/${restaurant.id}/picture`, {picture})
  }

  if (menu) {
    await putData(`restaurant/${restaurant.id}/menu`, {menu, mimeType: menuMimeType})
  }
}

export async function updateSubscription(restaurant, planId, paymentMethod, isFree) {
  try {

    const result = await putData(`restaurant/${restaurant.id}/subscription`, {
      planId, paymentMethod, isFree, returnUrl: SiteRootUrl + `restaurant/${restaurant.id}`
    })

    if (result.planId) {
      const subscription = new Subscription({
        stripeCustomerId: result.stripeCustomerId,
        isFree: result.isFree,
        planId: result.planId,
        paymentMethod: new PaymentMethod(result.paymentMethod)
      })

      return {subscription}
    }

    return {paymentIntent: result.paymentIntent}
  } catch (err) {
    return {error: err.response.data}
  }
}

export function groupForZipcode(restaurants) {
  const restaurantsByZip = {}

  restaurants.map((restaurant) => {
    if (!restaurantsByZip[restaurant.zipcode]) {
      restaurantsByZip[restaurant.zipcode] = []
    }

    restaurantsByZip[restaurant.zipcode].push(restaurant)
  })

  const sortedZipcodes = Object.keys(restaurantsByZip).sort((zip1, zip2) => {
    return parseInt(zip1) < parseInt(zip2)
  })

  let resultList = []

  sortedZipcodes.forEach((zipcode) => {
    const restaurantList = restaurantsByZip[zipcode].sort((p1, p2) => {
      return p1.name.localeCompare(p2.name)
    })

    resultList.push({zipcode, items: Immutable.List(restaurantList)})
  })

  return Immutable.fromJS(resultList)
}

// {
//         contactedByPhone, noPotential,   --> optional
//         textFilter
//       }

export const filterRestaurants = memoize((restaurants, params) => {
  const filterLower = params.textFilter.toLowerCase()

  return restaurants.filter((restaurant) => {
    const name = restaurant.name
    const zipcode = restaurant.zipcode
    const email = restaurant.email
    const emailNotif = restaurant.emailNotif

    if ((zipcode && zipcode.toLowerCase().includes(filterLower)) || (name && name.toLowerCase().includes(filterLower)) || (email && email.toLowerCase().includes(filterLower)) || (emailNotif && emailNotif.toLowerCase().includes(filterLower))) {

      if(params.contactedByPhone !== undefined) {
        if ((restaurant.contactedByPhone == params.contactedByPhone) && (restaurant.noPotential == params.noPotential)) {
          return true
        }
      } else {
        return true
      }

    }

    return false
  })
})

export async function convertToProspect(restaurant) {
  await postData(`restaurant/${restaurant.id}/converttoprospect`, {})
}
