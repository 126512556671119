import React from 'react'
import PropTypes from 'prop-types'
import {Button, Checkbox, FormControlLabel, MenuItem, Select, TextField} from "@material-ui/core"
import {updateRestaurantInfoAction} from "../../../action/restaurant"
import RestaurantCuisines from './restaurantCuisines'
import {mandatoryFields} from "../../../model/restaurant"
import {withStyles} from "@material-ui/core"

const styles = {
  label: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)'
  }
};

class RestaurantDetailsInternal extends React.Component {

  constructor(props) {
    super(props)
    const restaurant = props.restaurant

    this.state = {
      name: restaurant.name,
      company: restaurant.company || '',
      street: restaurant.street || '',
      city: restaurant.city || '',
      country: restaurant.country || '',
      zipcode: restaurant.zipcode || '',
      phone: restaurant.phone || '',
      email: restaurant.email || '',
      emailNotif: restaurant.emailNotif || '',
      website: restaurant.website || '',
      vat: restaurant.vat || '',
      tablebookerId: restaurant.tablebookerId || '',
      comments: restaurant.comments || '',
      cuisines: restaurant.cuisines,
      contactedByPhone: restaurant.contactedByPhone ? true : false,
      noPotential: restaurant.noPotential ? true : false,
      enableSave: false
    }

    this.fields = ['name', 'company', 'street', 'city', 'country', 'zipcode', 'phone', 'email', 'emailNotif', 'website', 'vat', 'comments', 'cuisines', 'noPotential', 'contactedByPhone']
  }

  updateField(name, value) {
    console.log('updateField')
    const obj = {}
    obj[name] = value
    console.log(this)
    this.setState(obj, () => {
      this.setState({enableSave: this.props.validateSaveButton(this.props, this.state, this.fields)})
    })
  }

  render() {
    const {classes} = this.props

    if (!this.props.restaurant) {
      return <div></div>
    }

    const textFieldStyle = {width: '25rem', marginRight: '1.5rem', marginBottom: '1rem'}

    return (
      <div>
        <div className="restaurant-detail">
          <div className="row">
            <TextField
              style={textFieldStyle}
              value={this.state.name}
              label="Name"
              onChange={(event) => this.updateField('name', event.target.value)}
            />
            <TextField
              style={textFieldStyle}
              value={this.state.company}
              label="Legal Name"
              onChange={(event) => this.updateField('company', event.target.value)}
            />
          </div>
          <div className="row">
            <TextField
              style={textFieldStyle}
              value={this.state.street}
              label="Street"
              onChange={(event) => this.updateField('street', event.target.value)}
            />
            <TextField
              style={textFieldStyle}
              value={this.state.zipcode}
              label="Zip"
              onChange={(event) => this.updateField('zipcode', event.target.value)}
            />
          </div>
          <div className="row">
            <TextField
              style={textFieldStyle}
              value={this.state.city}
              label="City"
              onChange={(event) => this.updateField('city', event.target.value)}
            />
            <FormControlLabel
              classes={{label: classes.label}}
              labelPlacement="top"
              style={{marginLeft: '0', alignItems: 'flex-start', marginBottom: '1rem', marginTop: '-4px'}}
              control={<Select value={this.state.country} onChange={(event) => {
                this.updateField('country', event.target.value)
              }} displayEmpty>
                <MenuItem value="Belgium">Belgium</MenuItem>
                <MenuItem value="France">France</MenuItem>
              </Select>
              }
              label="Country"
            />

          </div>
          <div className="row">

            <TextField
              style={textFieldStyle}
              value={this.state.phone}
              label="Phone"
              onChange={(event) => this.updateField('phone', event.target.value)}
            />
            <TextField
              style={textFieldStyle}
              value={this.state.email}
              label="Email (unique)"
              onChange={(event) => this.updateField('email', event.target.value)}
            />
            <TextField
              style={textFieldStyle}
              value={this.state.emailNotif}
              label="Email for reservations"
              onChange={(event) => this.updateField('emailNotif', event.target.value)}
            />
          </div>
          <div className="row">

            <TextField
              style={textFieldStyle}
              value={this.state.website}
              label="Web site"
              onChange={(event) => this.updateField('website', event.target.value)}
            />
            <TextField
              style={textFieldStyle}
              value={this.state.vat}
              label="VAT Number"
              onChange={(event) => this.updateField('vat', event.target.value)}
            />
          </div>
          <div className="row">
            <TextField
              style={textFieldStyle}
              value={this.state.tablebookerId}
              label="Tablebooker ID"
              onChange={(event) => this.updateField('tablebookerId', event.target.value)}
            />

          </div>
          <div className="row">
            <TextField
              style={{width: '40rem', marginBottom: '2rem'}}
              multiline
              rows="2"
              rowsMax="8"
              value={this.state.comments}
              label="Comments"
              onChange={(event) => this.updateField('comments', event.target.value)}
            />
          </div>

          <h5>Prospection</h5>
          <div className="row">
            <FormControlLabel
              control={<Checkbox checked={this.state.contactedByPhone}
                                 onChange={(ev, checked) => {
                                   this.updateField('contactedByPhone', checked)
                                 }
                                 }/>} label="Contacted By Phone"/>

            <FormControlLabel
              control={<Checkbox checked={this.state.noPotential}
                                 onChange={(ev, checked) => {
                                   this.updateField('noPotential', checked)
                                 }
                                 }/>} label="No Potential"/>


          </div>

          <h5>Cuisines</h5>
          <RestaurantCuisines
            cuisines={this.props.cuisines}
            restaurantCuisines={this.state.cuisines}
            update={(cuisines) => this.updateField('cuisines', cuisines)}
          />

        </div>
        <Button variant="contained" color="secondary" onClick={() => {
          this.props.saveDetails(this.props, this.state, this.fields)
        }}
                disabled={!this.state.enableSave}>Save Details</Button>
      </div>)

  }
}

RestaurantDetails.propTypes = {
  cuisines: PropTypes.object,
  restaurant: PropTypes.object,
  dispatch: PropTypes.func
}

const validateSaveButton = (props, state, fields) => {
  const restaurant = props.restaurant
  let saveButtonEnabled = true

  for (let field of mandatoryFields) {
    if (!state[field]) {
      saveButtonEnabled = false
      break
    }
  }

  if (state.cuisines.length === 0) {
    saveButtonEnabled = false
    return
  }

  if (saveButtonEnabled) {
    saveButtonEnabled = false

    for (let field of fields) {
      if (state[field] !== restaurant.get(field))
        saveButtonEnabled = true
    }
  }

  return saveButtonEnabled
}

const saveDetails = (props, state) => {
  const restaurant = Object.assign({}, state)
  restaurant.id = props.restaurant.id

  if (!restaurant.emailNotif)
    restaurant.emailNotif = null

  props.dispatch(updateRestaurantInfoAction(restaurant))
}

export const RestaurantDetailsBase = withStyles(styles)(RestaurantDetailsInternal)

export function RestaurantDetails(props) {
  return <RestaurantDetailsBase {...props} saveDetails={saveDetails} validateSaveButton={validateSaveButton}/>
}
