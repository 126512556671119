import Topbar from './topbar'
import React from 'react'
import {connect} from 'react-redux'
import {Router, Route} from 'react-router-dom'
import {Snackbar, SnackbarContent} from '@material-ui/core'
import {networkEventEmitter, successEvent, failureEvent, ajaxStartEvent, ajaxStopEvent, ajaxInProgress} from '../../network/index'
import {history} from '../../history'
import {withRouter} from "react-router"

function selectState(state) {
  return {
    userInfo: state.userInfo
  }
}

class Application extends React.Component {
  constructor(props) {
    super(props)
    this.state = {showSnackbar: false, snackbarMessage: ' ', ajaxLoad: ajaxInProgress(), snackbarState: "success"}
  }

  componentDidMount = () => {
    networkEventEmitter.addListener(successEvent, this.saveSuccess)
    networkEventEmitter.addListener(failureEvent, this.saveFailed)
    networkEventEmitter.addListener(ajaxStartEvent, this.ajaxStart)
    networkEventEmitter.addListener(ajaxStopEvent, this.ajaxStop)
  }

  componentWillUnmount = () => {
    networkEventEmitter.removeListener(successEvent, this.saveSuccess)
    networkEventEmitter.removeListener(failureEvent, this.saveFailed)
    networkEventEmitter.removeListener(ajaxStartEvent, this.ajaxStart)
    networkEventEmitter.removeListener(ajaxStopEvent, this.ajaxStop)
  }

  saveSuccess = () => {
    this.setState({showSnackbar: true, snackbarMessage: 'Save succeeded', snackbarState: "success"})
  }

  saveFailed = (response) => {
    let message = 'Save failed'

    if (response) {
      if (response.data.status === 409) {
        if (response.data.errors.email) {
          message += ': Email address already in use'
        }
      }
    }
    this.setState({showSnackbar: true, snackbarMessage: message, snackbarState: "error"})
  }

  ajaxStart = () => {
    this.setState({ajaxLoad: true})
  }

  ajaxStop = () => {
    this.setState({ajaxLoad: false})
  }

  render() {

    const RoutedTopbar = withRouter(Topbar)

    const appStyle = {pointerEvents: 'auto', opacity: 1}

    if (this.state.ajaxLoad) {
      appStyle.pointerEvents = 'none'
      appStyle.opacity = 0.7
    }

    return (
      <Router history={history}>
        <div id="application-view" className="application">
          <div className="app-header">
            <RoutedTopbar userInfo={this.props.userInfo} dispatch={this.props.dispatch}/>
          </div>
          <div className="app-body" style={appStyle}>
            {this.props.children}
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            autoHideDuration={7000}
            open={this.state.showSnackbar}
            onClose={() => {
              this.setState({showSnackbar: false})
            }}
          >
            <SnackbarContent
              classes={{
                root: "snackbar-" + this.state.snackbarState
              }}
              message={this.state.snackbarMessage}
            >

            </SnackbarContent>
          </Snackbar>
        </div>
      </Router>
    );
  }
}

export default connect(selectState)(Application)

