import React from 'react'
import PropTypes from "prop-types"
import StripeCustomer from "./stripeCustomer"

export default class SelectStripeCustomer extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    const list = this.props.customers.sort((c1, c2) => {
      if(!c1.name)
        return c1

      if(!c2.name)
        return c2

      return c1.name.localeCompare(c2.name)
    })

    const customers = list.map((customer) => {
      return <StripeCustomer customer={customer} key={customer.id} select={() => this.props.select(customer)}/>
    })

    return (
      <div>
        <h3>Select Stripe Customer</h3>
        <div className="stripe-customers">
          {customers}
        </div>
      </div>
    )
  }
}

SelectStripeCustomer.propTypes = {
  customers: PropTypes.object,
  select: PropTypes.func
}