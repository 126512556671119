import React from 'react'
import PropTypes from 'prop-types'
import {Checkbox, Button, TextField, FormControlLabel} from "@material-ui/core"
import ModifyCuisinesDialog from './modifyCuisinesDialog'

export default class RestaurantCuisines extends React.Component {

  constructor(props) {
    super(props)
    this.state = {showModify:false}
  }

  modifyCuisines = () => {
    this.setState({showModify:true})
  }

  cancelModifyCuisines = () => {
    this.setState({showModify:false})
  }

  update = (selectedCuisines) => {
    this.setState({showModify:false},()=>{
      this.props.update(selectedCuisines)
    })
  }

  render() {

    if (!this.props.cuisines) {
      return <div></div>
    }

    const cuisines = this.props.restaurantCuisines.map((cuisine, idx) => {
      return (<div className="restaurant-cuisine" key={idx}>
        {cuisine.name}
      </div>)
    })

    return (<div className="restaurant-cuisines">
      {cuisines} <Button variant="outlined" color="secondary" onClick={this.modifyCuisines}>Modify</Button>

      <ModifyCuisinesDialog
        show={this.state.showModify}
        cancel={this.cancelModifyCuisines}
        selectedCuisines={this.props.restaurantCuisines}
        cuisines={this.props.cuisines}
        update={this.update}/>
    </div>)
  }
}

RestaurantCuisines.propTypes = {
  restaurantCuisines:PropTypes.object,
  cuisines: PropTypes.object,
  update: PropTypes.func
}
