import {actionArguments, actionMetaData, actionResult, actionType, createPromiseAction} from "./index"
import {DormantsInfo} from "../model/dormant"
import {getDormants} from "../controller/dormant"

const GET_DORMANTS = 'GET_DORMANTS'

export function getAllDormantsAction() {
  const promise = getDormants()
  return createPromiseAction(GET_DORMANTS, promise, {})
}


export function reduceDormants(state = null, action = null) {
  if (!state) return new DormantsInfo()

  let nextState = state
  const meta = actionMetaData(action)
  const result = actionResult(action)
  const args = actionArguments(action)

  switch (actionType(action)) {
    case `${GET_DORMANTS}_SUCCESS`:
      nextState = nextState.merge({restaurants: result})
      break
  }

  return nextState

}
