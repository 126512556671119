import {MenuItem} from "@material-ui/core"
import React from "react"

class PlanDropdownItem extends React.Component {

  render() {
    const plan = this.props.plan

    return (<div className="plan-dropdown-item">
        <div>
          {plan.name}
        </div>
        <div>
          {this.props.interval}
        </div>
        <div>
          {plan.description}
        </div>
      </div>
    )
  }
}

// value and key will be plan.id

export function planToMenuItem(plan) {
  let interval = null

  switch (plan.interval) {
    case 'month':
      interval = plan.price + '€ / Month'
      break
    case 'year':
      interval = plan.price + '€ / Year'
      break
    case 'reservation':
      interval = plan.price + '€ / Reservation'
      break
  }

  return <MenuItem value={plan.id} key={plan.id} style={{height: "auto"}}>
    <PlanDropdownItem
      plan={plan}
      interval={interval}
    />
  </MenuItem>

}