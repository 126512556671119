import React from 'react'
import PropTypes from 'prop-types'
import {Dialog, Button, DialogTitle, DialogContent, DialogActions} from "@material-ui/core"
import {deleteDealAction,toggleDealPauseStateAction,updateDealAction} from "../../../action/restaurant"
import EditDeal from "./editDeal"
const selectedCheckboxStyle = {backgroundImage: `url(${require('../../../img/check.svg')})`}

export default class Deal extends React.Component {

  constructor(props) {
    super(props)

    this.state = {showDeleteDealDialog: false,showEditDealDialog:false}
  }

  showDeleteDealDialog = () => {
    this.setState({
      showDeleteDealDialog: true
    })
  }

  cancelDeleteDeal = () => {
    this.setState({
      showDeleteDealDialog: false
    })
  }

  deleteDeal = () => {
    this.setState({showDeleteDealDialog: false})
    this.props.dispatch(deleteDealAction(this.props.restaurant, this.props.deal))
  }

  pauseResume = () => {
    this.props.dispatch(toggleDealPauseStateAction(this.props.restaurant, this.props.deal))
  }

  edit = () => {
    this.setState({showEditDealDialog:true})
  }

  cancelEditDeal = () => {
    this.setState({
      showEditDealDialog: false
    })
  }

  saveDeal = (dealData) => {
    this.props.dispatch(updateDealAction(this.props.restaurant, dealData))
    this.setState({showEditDealDialog: false})
  }

  render() {

    const deal = this.props.deal

    const deleteDealActions = [
      <Button
        key="cancel"
        color="primary"
        onClick={this.cancelDeleteDeal}
      >Cancel</Button>,
      <Button variant="contained"
              key="delete"
              color="secondary"
              onClick={this.deleteDeal}
      >Delete</Button>
    ]

    const days = deal.days.map((day, index) => {
      if (day) {
        return <div style={selectedCheckboxStyle} className="day selected" key={index}/>
      }

      return <div className="day" key={index}/>
    })

    let lunch = null
    if (deal.lunch) {
      lunch = <div style={selectedCheckboxStyle} className="lunch selected"/>
    } else {
      lunch = <div className="lunch"/>
    }

    let dinner = null
    let timespan = <span/>
    if (deal.dinner) {
      dinner = <div style={selectedCheckboxStyle} className="dinner selected"/>
      timespan = <span>{deal.startHour}:{deal.startMinute} - {deal.endHour}:{deal.endMinute}</span>
    } else {
      dinner = <div className="dinner"/>
    }

    let pauseResume = "Pause"
    let dealClass = "ticks"
    if (deal.pause) {
      dealClass += " paused"
      pauseResume = "Resume"
    }

    const discount = deal.percentage + '%'

    let editDeal = <div/>

    if(this.props.subscription && this.props.plans) {
      editDeal = <EditDeal cancel={this.cancelEditDeal}
                             save={this.saveDeal}
                             show={this.state.showEditDealDialog}
                             key={this.state.showEditDealDialog}
                             deal={this.props.deal}
                             subscription={this.props.subscription}
                             plans={this.props.plans}
      />
    }

    return <div className="deal">
      <div className={dealClass}>
        <div className="time">
          {timespan}
        </div>
        <div className="lunch">
          {lunch}
        </div>
        <div className="dinner">
          {dinner}
        </div>
        <div className="days">
          {days}
        </div>
        <div className="places">
          {deal.places}
        </div>
        <div className="price-or-discount">
          {discount}
        </div>
      </div>
      <div className="buttons">
        <div className="pause-resume">
          <Button variant="contained" color="secondary" onClick={this.pauseResume}>{pauseResume}</Button>
        </div>
        <div className="edit">
          <Button variant="contained" color="secondary" onClick={this.edit}>Edit</Button>
        </div>
        <div className="delete">
          <Button variant="contained" color="secondary" onClick={this.showDeleteDealDialog}>Delete</Button>
        </div>
      </div>
      <Dialog
        modal={false}
        open={this.state.showDeleteDealDialog}
        onClose={this.cancelDeleteDeal}
      >
        <DialogTitle>Delete Deal</DialogTitle>
        <DialogContent>

          Do you want to delete this deal?
        </DialogContent>
        <DialogActions>{deleteDealActions}</DialogActions>
      </Dialog>
      {editDeal}

    </div>
  }
}

Deal.propTypes = {
  restaurant: PropTypes.object,
  subscription: PropTypes.object,
  plans: PropTypes.object,
  deal: PropTypes.object,
  dispatch:PropTypes.func
}
