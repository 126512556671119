import {actionArguments, actionMetaData, actionResult, actionType, createAction, createPromiseAction} from "./index"
import {getNewStripeCustomers, getProspects, updateProspectInfo, convertToRestaurant, createStripeCustomerAndConvertToRestaurant, sendMail, createProspect} from "../controller/prospect"
import {newProspectId, ProspectsInfo} from "../model/prospect"
import {Restaurant} from "../model/restaurant"
import {updateProspectPictures} from "../controller/prospect"
import {deleteProspect} from "../controller/prospect"
import {history} from "../history"
import {getAllRestaurantsAction} from "./restaurant"
import {getPaymentErrorDescription} from "../controller/stripe"
import {Prospect} from '../model/prospect'

const GET_PROSPECTS = 'GET_PROSPECTS'
const SELECT_PROSPECT = 'SELECT_PROSPECT'
const UPDATE_PROSPECT_INFO = 'UPDATE_PROSPECT_INFO'
const UPDATE_PROSPECT_PICTURES = 'UPDATE_PROSPECT_PICTURES'
const DELETE_PROSPECT = 'DELETE_PROSPECT'
const GET_NEW_STRIPE_CUSTOMERS = 'GET_NEW_STRIPE_CUSTOMERS'
const CONVERT_TO_RESTAURANT = 'CONVERT_TO_RESTAURANT'
const SET_PROSPECT_FILTER = 'SET_PROSPECT_FILTER'
const SET_PROSPECT_GROUP_BY_ZIPCODE = 'SET_PROSPECT_GROUP_BY_ZIPCODE'
const SEND_PROSPECT_MAIL = 'SEND_PROSPECT_MAIL'
const CREATE_NEW_PROSPECT = 'CREATE_NEW_PROSPECT'
const CREATE_PROSPECT_ON_SERVER = 'CREATE_PROSPECT_ON_SERVER'
const SET_CONTACTED_BY_PHONE_FILTER = 'SET_CONTACTED_BY_PHONE_FILTER'
const SET_NO_POTENTIAL_FILTER = 'SET_NO_POTENTIAL_FILTER'

export function getAllProspectsAction() {
  const promise = getProspects()
  return createPromiseAction(GET_PROSPECTS, promise, {})
}

export function selectProspectAction(id) {
  return createAction(SELECT_PROSPECT, {id})
}

export function updateProspectInfoAction(restaurant) {
  const promise = updateProspectInfo(restaurant)
  return createPromiseAction(UPDATE_PROSPECT_INFO, promise, {prospect: restaurant})
}

export function createProspectAction(restaurant) {
  const promise = createProspect(restaurant)
  return createPromiseAction(CREATE_PROSPECT_ON_SERVER, promise, {prospect: restaurant})
}

export function updateProspectPicturesAction(restaurant, image, menu, menuMimeType) {
  const promise = updateProspectPictures(restaurant, image, menu, menuMimeType)

  return createPromiseAction(UPDATE_PROSPECT_PICTURES, promise)
}

export function deleteProspectAction(restaurant) {
  const promise = deleteProspect(restaurant)
  return createPromiseAction(DELETE_PROSPECT, promise, {prospect: restaurant})
}

export function getNewStripeCustomersAction() {
  const promise = getNewStripeCustomers()
  return createPromiseAction(GET_NEW_STRIPE_CUSTOMERS, promise, {})
}

export function convertToRestaurantAction(prospect, stripeCustomerId, passwordHash, email, language) {
  return (dispatch) => {
    const promise = convertToRestaurant(prospect, stripeCustomerId, passwordHash, email, language)

    dispatch(createPromiseAction(CONVERT_TO_RESTAURANT, promise, {prospect})).then(() => {
      return dispatch(getAllRestaurantsAction())
    }).then(() => {
      history.push(`/restaurant/${prospect.id}`)
    })
  }
}

export function createStripeCustomerAndConvertToRestaurantAction(prospect, passwordHash, email, language, planId, isFree, paymentMethod) {
  return (dispatch) => {
    const promise = createStripeCustomerAndConvertToRestaurant(prospect, passwordHash, email, language, planId, isFree, paymentMethod)

    dispatch(createPromiseAction(CONVERT_TO_RESTAURANT, promise, {prospect})).then(() => {
      return dispatch(getAllRestaurantsAction())
    }).then(() => {
      history.push(`/restaurant/${prospect.id}`)
    }).catch((err) => {
      console.log(err)
    })
  }
}

export function setProspectFilterAction(filter) {
  return createAction(SET_PROSPECT_FILTER, {filter})
}

export function setProspectGroupByZipcodeAction(groupByZipcode) {
  return createAction(SET_PROSPECT_GROUP_BY_ZIPCODE, {groupByZipcode})
}

export function setContactedByPhoneFilterAction(contactedByPhoneFilter) {
  return createAction(SET_CONTACTED_BY_PHONE_FILTER, {contactedByPhoneFilter})
}

export function setNoPotentialFilterAction(noPotentialFilter) {
  return createAction(SET_NO_POTENTIAL_FILTER, {noPotentialFilter})
}

export function sendProspectMailAction(prospect, language) {
  const promise = sendMail(prospect, language)
  return createPromiseAction(SEND_PROSPECT_MAIL, promise, {})
}

export function createNewProspectAction() {
  return createAction(CREATE_NEW_PROSPECT)
}

export function reduceProspects(state = null, action = null) {
  if (!state) return new ProspectsInfo()

  let nextState = state
  const meta = actionMetaData(action)
  const result = actionResult(action)
  const args = actionArguments(action)

  switch (actionType(action)) {
    case `${GET_NEW_STRIPE_CUSTOMERS}_SUCCESS`: {
      nextState = nextState.merge({stripeCustomers: result})
    }
      break
    case `${GET_PROSPECTS}_SUCCESS`: {
      const selectedProspect = result.size ? result[0] : null
      nextState = nextState.merge({prospects: result, selectedProspect})
    }
      break
    case SELECT_PROSPECT: {
      const t0 = performance.now()
      const selectedProspect = nextState.prospects.find(p => p.id === parseInt(args.id))
      nextState = nextState.merge({selectedProspect})
      const t1 = performance.now()
      console.log('time is', t1 - t0)
    }
      break
    case `${UPDATE_PROSPECT_INFO}_SUCCESS`: {
      const selectedProspect = new Prospect(meta.prospect)
      const prospectIndex = nextState.prospects.findIndex((r) => {
        return r.id === selectedProspect.id
      })
      const prospects = nextState.prospects.set(prospectIndex, selectedProspect)
      nextState = nextState.merge({prospects, selectedProspect})
    }
      break
    case `${DELETE_PROSPECT}_SUCCESS`: {
      let selectedProspect = null

      const prospectIndex = nextState.prospects.findIndex((r) => {
        return r.id === meta.prospect.id
      })
      const prospects = nextState.prospects.delete(prospectIndex)

      if (prospects.size) {
        if (prospectIndex)
          selectedProspect = prospects.get(prospectIndex - 1)
        else
          selectedProspect = prospects.get(0)
      } else {
        selectedProspect = null
      }

      if (selectedProspect) {
        history.push(`/prospect/${selectedProspect.id}`)
      } else {
        history.push(`/prospect`)
      }

      nextState = nextState.merge({
        prospects,
        selectedProspect: selectedProspect
      })

    }
      break
    case `${CONVERT_TO_RESTAURANT}_ERROR`: {
      const response = result.response

      // email address conflict
      if (response) {

        if (response.data.status === 409) {
          if (response.data.errors.email) {
            nextState = nextState.merge({conversionErrorMessage: "The Stripe email address is already used by another restaurant. Please change it"})
          } else {
            nextState = nextState.merge({conversionErrorMessage: "The prospect is already converted to a restaurant"})
          }
        }
      }

      if (result.error) {
        nextState = nextState.merge({conversionErrorMessage: getPaymentErrorDescription(result.error)})
      }
    }
      break
    case `${CONVERT_TO_RESTAURANT}_BEGIN`: {
      nextState = nextState.merge({conversionErrorMessage: null})
    }
      break
    case `${CONVERT_TO_RESTAURANT}_SUCCESS`: {
      let prospects = nextState.prospects.filter((p) => {
        return p.id !== meta.prospect.id
      })
      nextState = nextState.merge({
        prospects
      })
    }
      break
    case SET_PROSPECT_FILTER: {
      nextState = nextState.merge({
        filter: args.filter
      })

    }
      break
    case SET_PROSPECT_GROUP_BY_ZIPCODE : {
      nextState = nextState.merge({
        groupByZipcode: args.groupByZipcode
      })
      break

    }
    case SET_NO_POTENTIAL_FILTER: {
      nextState = nextState.merge({
        noPotentialFilter: args.noPotentialFilter
      })
    }
      break
    case SET_CONTACTED_BY_PHONE_FILTER: {
      nextState = nextState.merge({
        contactedByPhoneFilter: args.contactedByPhoneFilter
      })
    }
      break
    case CREATE_NEW_PROSPECT: {
      const selectedProspect = new Prospect()
      const prospects = nextState.prospects.unshift(selectedProspect)
      nextState = nextState.merge({
        prospects,
        selectedProspect
      })

      history.push(`/prospect/${selectedProspect.id}`)

    }
      break
    case `${CREATE_PROSPECT_ON_SERVER}_SUCCESS`: {
      const id = result.id
      const selectedProspect = (new Prospect(meta.prospect)).set('id',id)
      const prospectIndex = nextState.prospects.findIndex((r) => {
        return r.id === newProspectId
      })
      const prospects = nextState.prospects.set(prospectIndex, selectedProspect).sort((p1,p2) => {
        return p1.name.localeCompare(p2.name)
      })
      nextState = nextState.merge({prospects, selectedProspect})

      history.push(`/prospect/${selectedProspect.id}`)
    }
      break
  }

  return nextState
}
