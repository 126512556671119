import React from 'react'
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core"
import PropTypes from 'prop-types'

export default class RestaurantListHeader extends React.Component {

  render() {
    return <div className="header">
      <TextField
        value={this.props.filter}
        label="Filter"
        onChange={(event) => this.props.updateFilter(event.target.value)}
      />
      <FormControlLabel
        control={<Checkbox checked={this.props.groupByZipcode}
                           onChange={(ev, checked) => {
                             this.props.updateGroupByZipcode(checked)
                           }
                           }/>} label="Group By Zip"/>
    </div>
  }
}

RestaurantListHeader.propTypes = {
  filter:PropTypes.string,
  groupByZipcode:PropTypes.bool,
  updateFilter:PropTypes.func,
  updateGroupByZipcode:PropTypes.func
}
