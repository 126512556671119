import React from 'react'
import { Link } from 'react-router-dom'

export class TopbarNavigation extends React.Component {
  render() {

    const liStyle = {
      color: '#bbbbbb',
      fontSize: '1.2rem',
      listStyleType: 'none',
      marginRight: '1rem',
      marginLeft: '1rem',
      textDecoration: 'none'
    }

    const liStyleActive = Object.assign({}, liStyle, {color: 'white'})

    const luStyle = {
      display: 'flex',
      alignItems: 'center',
      //top: '1.2rem'
    }

    const dashboardStyle = (this.props.activeTab.startsWith('/dashboard')) ? liStyleActive : liStyle
    const prospectStyle = (this.props.activeTab.startsWith('/prospect')) ? liStyleActive : liStyle
    const restaurantStyle = (this.props.activeTab.startsWith('/restaurant')) ? liStyleActive : liStyle
    const dormantStyle = (this.props.activeTab.startsWith('/dormant')) ? liStyleActive : liStyle
    const downloadStyle = (this.props.activeTab.startsWith('/download')) ? liStyleActive : liStyle

    if (this.props.loggedIn) {
      const spanElements = []

      spanElements.push(<Link style={restaurantStyle} to='/restaurant' key="restaurants">Restaurants</Link>)
      spanElements.push(<Link style={prospectStyle} to='/prospect' key="prospects">Prospects</Link>)
      spanElements.push(<Link style={dormantStyle} to='/dormant' key="dormants">Dormants</Link>)
      spanElements.push(<Link style={dashboardStyle} to='/dashboard' key="dashboard">Dashboard</Link>)
      spanElements.push(<Link style={downloadStyle} to='/download' key="downloads">Downloads</Link>)

      return (
        <span style={luStyle}>
          {spanElements}

        </span>)
    }

    return (<span/>)
  }
}
