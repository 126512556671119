import Immutable from 'immutable'

export const Plan = Immutable.Record({
  id:null,
  name:null,
  description:null,
  price:null,
  priceSingle:null,
  interval:null,
  intervalCount:null,
  discountPercentages:null
})

Plan.fromJson = (json) => {
  let plan = new Plan(json)
  let discountPercentages = Immutable.List(json.discountPercentages)

  return plan.set('discountPercentages',discountPercentages)
}

export const PaymentMethod = Immutable.Record({
  imageUrl: null,
  description: null,
  name: null
})

export const Subscription = Immutable.Record({
  id:null,
  stripeCustomerId:null,
  planId: null,
  isFree:false,
  paymentMethod: null
})

