import {getData,getBinaryData} from '../network'
import {Reservations, ReservationsPerDay, Reservation} from '../model/stats'
import Immutable from 'immutable'
import moment from 'moment'

export function getReservations(month) {
  return getData(`reservation?month=${month}`,{accept:'text/csv'})
}

export function getDailyReservations(month) {
  return getData(`dailyReservation?month=${month}`,{accept:'text/csv'})
}

export function getDailyReservationsForRestaurant(month, restaurant) {
  return getData(`restaurant/${restaurant.id}/reservation?month=${month}`,{accept:'text/csv'})
}

export function getCustomers() {
  return getData(`customer`,{accept:'text/csv'})
}

export function getInvoices(month) {
  return getBinaryData(`invoice?month=${month}`,{accept:'application/zip'})
}

export function loadStatistics() {

  return getData('statistics').then((result) => {
    const statistics = result.statistics

    let oneMonthAgo = moment()
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0).add(-30, "days")

    const reservationsPerDayList = Immutable.List(statistics.reservationsPerDay.filter((json) => {
      const date = moment(json.date)
      return date.valueOf() > oneMonthAgo.valueOf()
    }).map((json) => {
      return new ReservationsPerDay(json)
    })).reverse()

    const reservationsList = statistics.reservations.filter((json) => {
      const date = moment(json.date)
      return date.valueOf() > oneMonthAgo.valueOf()
    }).map((json) => {
      return new Reservation(json)
    })

    let reservationCountPerTime = {}

    let now = moment()
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);

    statistics.reservations.forEach((json) => {
      const date = moment(json.date).set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);

      if (now.valueOf() == date.valueOf()) {
        const timeString = json.hour * 100 + json.minute

        if (!reservationCountPerTime[timeString])
          reservationCountPerTime[timeString] = 0

        reservationCountPerTime[timeString] += parseInt(json.places)
      }

    })

    reservationCountPerTime = Immutable.OrderedMap(reservationCountPerTime)
    const reservations = new Reservations({
      reservations: reservationsList,
      reservationsPerDay: reservationsPerDayList,
      reservationCountPerTime
    })

    return Promise.resolve(reservations)
  })
}