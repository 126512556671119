import React from "react"
import {connect} from "react-redux"
import {Redirect} from "react-router"
import RestaurantList from "../restaurant/list/restaurantList"
import {createNewProspectAction, selectProspectAction, sendProspectMailAction, setContactedByPhoneFilterAction, setNoPotentialFilterAction, setProspectFilterAction, setProspectGroupByZipcodeAction} from "../../action/prospect"
import ProspectDetails from "./prospectDetails"
import ProspectPictures from "./prospectPictures"
import {deleteProspectAction} from "../../action/prospect"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core"
import {mandatoryFields} from "../../model/restaurant"
import ConvertToCustomerDialog from "./convert/convertToCustomerDialog"
import GroupedRestaurantList from "../restaurant/list/groupedRestaurantList"
import RestaurantListHeader from "../restaurant/list/restaurantListHeader"
import SendProspectMailDialog from "./sendProspectMailDialog"
import {newProspectId} from "../../model/prospect"
import ProspectFilter from './prospectFilter'
import memoize from "memoize-one"
import {filterRestaurants} from "../../controller/restaurant"

function selectState(state, props) {
  return {
    history: props.history,
    prospects: state.prospectsInfo.prospects,
    plans: state.data.plans,
    cuisines: state.data.cuisines,
    selectedProspect: state.prospectsInfo.selectedProspect,
    conversionErrorMessage: state.prospectsInfo.conversionErrorMessage,
    stripeCustomers: state.prospectsInfo.stripeCustomers,
    filter: state.prospectsInfo.filter,
    groupByZipcode: state.prospectsInfo.groupByZipcode,
    noPotentialFilter: state.prospectsInfo.noPotentialFilter,
    contactedByPhoneFilter: state.prospectsInfo.contactedByPhoneFilter
  }
}

class Prospects extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showDeleteProspectDialog: false,
      showConvertToCustomerDialog: false,
      showSendProspectMailDialog: false
    }

    this.prospectsToShowFilter = memoize((prospects, contactedByPhone, noPotential, textFilter) => {
      return filterRestaurants(prospects, {
        contactedByPhone, noPotential, textFilter
      })
    })

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pathname = this.props.history.location.pathname
    const prospectId = parseInt(pathname.split("/")[2])

    if (prospectId && (!this.props.selectedProspect || this.props.selectedProspect.id !== prospectId) && this.props.prospects.size) {
      setTimeout(() => {
        this.props.dispatch(selectProspectAction(prospectId))
      }, 1)
    }
  }

  selectProspect = (prospect) => {
    this.props.history.push(`/prospect/${prospect.id}`)
  }

  deleteProspect = () => {
    this.setState({showDeleteProspectDialog: true})
  }

  cancelDeleteProspect = () => {
    this.setState({showDeleteProspectDialog: false})
  }

  doDeleteProspect = () => {
    this.setState({showDeleteProspectDialog: false}, () =>
      this.props.dispatch(deleteProspectAction(this.props.selectedProspect)))
  }

  convertToCustomer = () => {
    this.setState({showConvertToCustomerDialog: true})
  }

  updateContactedByPhoneFilter = (selected) => {
    this.props.dispatch(setContactedByPhoneFilterAction(selected))
  }

  updateNoPotentialFilter = (selected) => {
    this.props.dispatch(setNoPotentialFilterAction(selected))
  }

  validateFieldsForRestaurantCreation = () => {
    if (!this.props.selectedProspect)
      return false

    const prospect = this.props.selectedProspect

    for (let field of mandatoryFields) {
      // we allow empty email as we will recover it from Stripe
      if (field !== 'email') {
        if (!prospect[field]) {
          return false
        }
      }
    }

    if (prospect.cuisines.length === 0) {
      return false
    }

    if (prospect.id === newProspectId) {
      return false
    }

    return true
  }

  validateFieldsForMailSending = () => {
    if (!this.props.selectedProspect)
      return false

    const prospect = this.props.selectedProspect

    return prospect.email
  }

  sendProspectMail = (language) => {
    this.props.dispatch(sendProspectMailAction(this.props.selectedProspect, language))
    this.setState({showSendProspectMailDialog: false})
  }

  createNewProspect = () => {
    this.props.dispatch(createNewProspectAction())
  }

  validateCreateProspectButton = () => {
    if (!this.props.selectedProspect)
      return true

    const prospect = this.props.selectedProspect

    return prospect.id !== newProspectId
  }

  render() {

    if (!this.props.selectedProspect) {
      if (!this.props.prospects || this.props.prospects.size === 0) {
        return <div></div>
      } else {
        const pathname = this.props.history.location.pathname
        const restaurantId = parseInt(pathname.split("/")[2])

        if (!restaurantId) {
          const prospects = this.props.prospects
          const prospect = prospects.get(0)
          const url = `/prospect/${prospect.id}`
          return <Redirect to={url}/>
        } else {
          return <div></div>
        }
      }
    }

    let prospectDetail = <div></div>

    if (this.props.cuisines) {
      prospectDetail = <ProspectDetails key={this.props.selectedProspect.id} cuisines={this.props.cuisines}
                                        restaurant={this.props.selectedProspect}
                                        dispatch={this.props.dispatch}/>
    }

    const deleteProspectActions = [
      <Button
        key={1}
        color="primary"
        onClick={this.cancelDeleteProspect}
      >Cancel</Button>,
      <Button
        key={2}
        variant="contained"
        color="secondary"
        onClick={this.doDeleteProspect}
      >Delete</Button>
    ]

    const prospect = this.props.selectedProspect

    let prospectList = <div></div>

    const prospectsToShow = this.prospectsToShowFilter(this.props.prospects, this.props.contactedByPhoneFilter, this.props.noPotentialFilter, this.props.filter)

    if (this.props.groupByZipcode) {
      prospectList = <GroupedRestaurantList
        restaurants={prospectsToShow}
        selectedRestaurant={prospect}
        selectRestaurant={this.selectProspect}/>

    } else {
      prospectList = <RestaurantList
        restaurants={prospectsToShow}
        selectedRestaurant={prospect}
        selectRestaurant={this.selectProspect}/>
    }

    let picturesSection = <div/>

    if (prospect.id !== newProspectId) {
      picturesSection = <div className="section">
        <h3>Pictures</h3>
        <ProspectPictures key={this.props.selectedProspect.id} restaurant={this.props.selectedProspect}
                          dispatch={this.props.dispatch}/>
      </div>

    }

    return (
      <div className="restaurants">
        <div className="restaurants-list">
          <RestaurantListHeader
            filter={this.props.filter}
            groupByZipcode={this.props.groupByZipcode}
            updateFilter={(filter) => this.props.dispatch(setProspectFilterAction(filter))}
            updateGroupByZipcode={(group) => this.props.dispatch(setProspectGroupByZipcodeAction(group))}
          />
          <ProspectFilter
            contactedByPhoneFilter={this.props.contactedByPhoneFilter}
            noPotentialFilter={this.props.noPotentialFilter}
            updateContactedByPhone={this.updateContactedByPhoneFilter}
            updateNoPotential={this.updateNoPotentialFilter}
          />
          <div className="separator"/>
          <div className="add-prospect-section">
            <Button variant="contained" color="primary" onClick={this.createNewProspect} style={{marginRight: '1rem'}}
                    disabled={!this.validateCreateProspectButton()}>Add Prospect</Button>
          </div>
          <div className="items">
            {prospectList}
          </div>
        </div>
        <div className="detail">
          <div style={{marginTop: '2rem'}}>
            <div>
              <Button variant="contained" color="secondary"
                      onClick={() => this.setState({showSendProspectMailDialog: true})} style={{marginRight: '1rem'}}
                      disabled={!this.validateFieldsForMailSending()}>Send Email</Button>
              <Button variant="contained" color="secondary" onClick={this.convertToCustomer}
                      disabled={!this.validateFieldsForRestaurantCreation()}>Convert To Customer</Button>
            </div>
          </div>

          <div className="section">
            <h3>Details</h3>
            {prospectDetail}
          </div>
          {picturesSection}
          <div style={{marginTop: '2rem'}} className="delete">
            <Button variant="contained" color="secondary" onClick={this.deleteProspect}
                    disabled={prospect == null || prospect.id === newProspectId}>Delete Prospect</Button>
          </div>
        </div>
        <Dialog
          modal={false}
          open={this.state.showDeleteProspectDialog}
          onClose={this.cancelDeleteProspect}
        >
          <DialogTitle>Delete Prospect</DialogTitle>
          <DialogContent>Do you want to delete this prospect?</DialogContent>
          <DialogActions>{deleteProspectActions}</DialogActions>
        </Dialog>
        <ConvertToCustomerDialog prospect={this.props.selectedProspect}
                                 plans={this.props.plans}
                                 conversionErrorMessage={this.props.conversionErrorMessage}
                                 dispatch={this.props.dispatch}
                                 stripeCustomers={this.props.stripeCustomers}
                                 key={this.props.selectedProspect.id}
                                 cancel={() => {
                                   this.setState({showConvertToCustomerDialog: false})
                                 }}
                                 show={this.state.showConvertToCustomerDialog}/>
        <SendProspectMailDialog
          show={this.state.showSendProspectMailDialog}
          close={() => {
            this.setState({showSendProspectMailDialog: false})
          }}
          send={this.sendProspectMail}
        />

      </div>)
  }
}

export default connect(selectState)(Prospects)
