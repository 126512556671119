import React from 'react'
import PropTypes from 'prop-types'
import Picker from 'react-month-picker'

import 'react-month-picker/css/month-picker.css'

let pickerLang = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  from: 'From',
  to: 'To'
}

class MonthBox extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      value: this.props.value || 'N/A'
    }

    this._handleClick = this._handleClick.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value || 'N/A'
    })
  }

  render() {

    return (
      <div className="month-box" onClick={this._handleClick}>
        <label>{this.state.value}</label>
      </div>
    )
  }

  _handleClick(e) {
    this.props.onClick && this.props.onClick(e)
  }
}

MonthBox.propTypes = {
  value: PropTypes.string
  , onClick: PropTypes.func
}

export default class MonthPicker extends React.Component {

  constructor(props) {
    super(props)
    const val = props.value
    this.state = {value: {year: val.substring(0, 4), month: val.substring(4, 6)}}
    this.pickAMonth = React.createRef()
  }

  componentWillReceiveProps(newProps) {
    const val = newProps.value
    this.setState({value: {year: val.substring(0, 4), month: val.substring(4, 6)}})
  }

  handleMonthChange = (year, month) => {
    this.props.updateValue(`${year * 100 + month}`)
  }

  handleMonthDismiss = () => {

  }

  makeText = (m) => {
    if (m && m.year && m.month) return (pickerLang.months[m.month - 1] + '. ' + m.year)
    return '?'
  }

  handleClickMonthBox = () => {
    this.pickAMonth.current.show()
  }

  render() {

    const mvalue = this.state.value
    return <div style={{width:'10rem'}}>
      <Picker
        ref={this.pickAMonth}
        years={5}
        lang={pickerLang.months}
        value={mvalue}
        onChange={this.handleMonthChange}
        onDismiss={this.handleMonthDismiss}
      >
        <MonthBox value={this.makeText(mvalue)} onClick={this.handleClickMonthBox}/>
      </Picker>

    </div>
  }
}

MonthPicker.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func
}

