import React from 'react'
import {connect} from 'react-redux'
import {Dialog, DialogTitle, DialogContent, Button, DialogActions} from "@material-ui/core"
import {getAllDormantsAction} from "../../action/dormant"
import RestaurantListItem from "../restaurant/list/restaurantListItem"
import {history} from "../../history"

function selectState(state, props) {
  return {
    restaurants: state.dormantsInfo.restaurants
  }
}

class Dormants extends React.Component {

  constructor(props) {
    super(props)
  }

  loadDormants = () => {
    this.props.dispatch(getAllDormantsAction())
  }

  componentDidMount() {
  }

  selectRestaurant = (restaurant) => {
    history.push(`/restaurant/${restaurant.id}`)

  }

  render() {

    if (this.props.restaurants.size === 0) {
      return <div className="dormants">
        <Button
          key={1}
          variant="contained"
          color="primary"
          onClick={this.loadDormants}
        >Load Dormants</Button>
      </div>
    }

    const items = []

    this.props.restaurants.forEach((restaurant) => {
      items.push(<div key={restaurant.id}><RestaurantListItem restaurant={restaurant} isSelected={false} selectRestaurant={this.selectRestaurant}
                                     /></div>)
    })

    return <div className="dormants">
      {items}
    </div>
  }
}

export default connect(selectState)(Dormants)
