import React from 'react'
import {VictoryChart, VictoryLine, VictoryBar, VictoryLegend, VictoryPie, VictoryAxis, VictoryArea} from 'victory';
import {VictoryTheme, mainColor} from '../../theme'
import {doLoadMainStatsAction} from '../../action/stats'
import {connect} from 'react-redux'

function selectState(state) {
  return {
    stats: state.stats
  }
}

class Dashboard extends React.Component {

  componentWillMount() {
    if (!this.props.stats.reservations)
      this.props.dispatch(doLoadMainStatsAction())
  }

  render() {
    if (!this.props.stats.reservations) {
      return <div></div>
    }

    const reservationsPerDay = this.props.stats.reservations.reservationsPerDay
    const today = reservationsPerDay.last()
    const reservationCountPerTime = this.props.stats.reservations.reservationCountPerTime.toJS()

    const placesPerDay = reservationsPerDay.map((reservation) => {
      return {
        x: reservation.date.substr(5, 6),
        y: reservation.reservedPlaces
      }
    }).toJS()

    const totalPlacesPerDay = reservationsPerDay.map((reservation) => {
      return {
        x: reservation.date.substr(5, 6),
        y: reservation.totalPlaces
      }
    }).toJS()

    const occupancyPerDay = reservationsPerDay.map((reservation) => {
      return {
        x: reservation.date.substr(5, 6),
        y: reservation.occupancy
      }
    }).toJS()

    const timeXAxisValues = []

    for (let x = 1100; x <= 2330;) {
      const hour = x / 100
      const minute = x % 100
      timeXAxisValues.push(`${x}`)
      x += 30
      if ((x % 100) === 60) {
        x -= 60
        x += 100
      }
    }

    console.log(reservationCountPerTime)

    const reservationTimes = timeXAxisValues.map((xValue) => {
      return {
        x: xValue,
        y: reservationCountPerTime[parseInt(xValue)] || 0
      }
    })

    console.log(reservationTimes)

    const todayData = [{x: 0, label: `${today.reservedPlaces} Reserved`, y: today.reservedPlaces}, {
      x: 1,
      label: `${today.totalPlaces - today.reservedPlaces} Available`,
      y: today.totalPlaces - today.reservedPlaces
    }]

    return <div className="dashboard container-fluid">
      <div className="row">
        <h2 className="col-xs-12">Today</h2>
        <div className="graph col-xs-6">
          <h3>Popular Hours</h3>
          <VictoryChart theme={VictoryTheme} domainPadding={20}>
            <VictoryAxis dependentAxis
            />
            <VictoryAxis
              tickValues={timeXAxisValues}
            />
            <VictoryBar
              style={{
                data: {fill: mainColor},
                parent: {border: "1px solid #ccc"}
              }}
              data={reservationTimes}
            />
          </VictoryChart>
        </div>
        <div className="graph col-xs-3 col-xs-offset-2">
          <h3>Places</h3>
          <VictoryPie
            colorScale={[mainColor, "#424242"]}
            style={{
              data: {stroke: "grey"},
              parent: {border: "none",overflow:"visible"}
            }}
            data={todayData}
          />
        </div>

      </div>
      <div className="row">
        <h2 className="col-xs-12">Month</h2>
        <div className="graph col-xs-6">
          <h3>Places</h3>
          <VictoryChart theme={VictoryTheme}>
            <VictoryAxis dependentAxis fixLabelOverlap={false} domain={[0, 1000]}/>
            <VictoryAxis fixLabelOverlap={true}/>
            <VictoryArea
              style={{
                data: {fill: "#424242"},
                parent: {border: "1px solid #ccc"}
              }}
              data={totalPlacesPerDay}
            />
            <VictoryArea
              style={{
                data: {fill: mainColor},
                parent: {border: "1px solid #ccc"}
              }}
              data={placesPerDay}
            />
          </VictoryChart>
        </div>

        <div className="graph col-xs-6">
          <h3>% Occupancy</h3>
          <VictoryChart theme={VictoryTheme}>
            <VictoryAxis dependentAxis
                         domain={[0, 100]}
            />
            <VictoryAxis
              fixLabelOverlap={true}
            />
            <VictoryArea
              style={{
                data: {fill: mainColor},
                parent: {border: "1px solid #ccc"}
              }}
              data={occupancyPerDay}
            />
          </VictoryChart>
        </div>
      </div>
    </div>
  }
}

export default connect(selectState)(Dashboard)