import {getData, putData, deleteData, postData} from '../network'
import Immutable from 'immutable'
import {Prospect, StripeCustomer} from "../model/prospect"
import {SiteRootUrl} from "../config"
import {PaymentMethod, Subscription} from "../model/subscription"

export async function getProspects() {
  const result = await getData('prospect')
  const prospects = result.prospects

  return Immutable.List(prospects.map((json) => Prospect.fromJson(json))).sort((prospect1, prospect2) => {
    return prospect1.name.localeCompare(prospect2.name)
  })
}

export async function updateProspectInfo(prospect) {
  return putData(`prospect/${prospect.id}`, prospect)
}

export async function createProspect(prospect) {
  return postData(`prospect`, prospect)
}

export async function updateProspectPictures(restaurant, picture, menu, menuMimeType) {
  if (picture) {
    await putData(`prospect/${restaurant.id}/picture`, {picture})
  }

  if (menu) {
    await putData(`prospect/${restaurant.id}/menu`, {menu, mimeType: menuMimeType})
  }
}

export async function deleteProspect(restaurant) {
  return deleteData(`prospect/${restaurant.id}`)
}

export async function getNewStripeCustomers() {
  const result = await getData('subscription/newcustomer')

  return Immutable.List(result.customers.map(json => StripeCustomer(json)))
}

export async function convertToRestaurant(prospect, stripeCustomerId, passwordHash, email, language) {
  await postData(`prospect/${prospect.id}/converttorestaurant`, {
    stripeCustomerId, passwordHash, language, email
  })
}

export async function createStripeCustomerAndConvertToRestaurant(prospect, passwordHash, email, language, planId, isFree, paymentMethod) {
  try {
    const result = await postData(`prospect/${prospect.id}/converttorestaurant`, {
      passwordHash,
      language,
      email,
      planId,
      isFree,
      paymentMethod
    })

    if (result.planId) {
      const subscription = new Subscription({
        stripeCustomerId: result.stripeCustomerId,
        isFree: result.isFree,
        planId: result.planId,
        paymentMethod: new PaymentMethod(result.paymentMethod)
      })

      return {subscription}
    }

    const intent = result.paymentIntent
    const nextAction = intent.next_action

    if (nextAction.redirect_to_url) {
      const url = nextAction.redirect_to_url.url
      window.location = url
    }

  } catch (err) {
    console.log(err)
    throw {error: err.response.data}
  }
}

export async function sendMail(prospect, language) {
  await postData(`prospect/${prospect.id}/sendmail`, {language})
}
