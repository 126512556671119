import React from 'react'
import PropTypes from 'prop-types'
import {Paper, Button, TextField, CircularProgress} from '@material-ui/core'

export default class LoginComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {username: '', password: ''}
  }

  username() {
    return this.state.username
  }

  password() {
    return this.state.password
  }

  usernameChanged = (e) => {
    this.setState({username: e.target.value})
  }

  passwordChanged = (e) => {
    this.setState({password: e.target.value})
  }

  doForgetPassword = (e) => {
    e.preventDefault()
    this.props.doForgetPassword(this.username())

  }

  doLogin = (e) => {
    e.preventDefault()
    this.props.doLogin(this.username(), this.password(), this.state.region)
  }

  render() {
    const enableFields = !this.props.loggingIn
    const enableLogin = ((this.username().length > 3) && (this.password().length > 3)) && enableFields
    const enableForgotPassword = (this.username().length > 3) && enableFields
    const showSpinner = this.props.loggingIn
    const spinnerVisibility = showSpinner ? 'visible' : 'hidden'
    return (
      <Paper
        className="login"
        elevation={1}
      >
        <h1>Login</h1>
        <div><TextField
          label="Email"
          placeholder="Email"
          onChange={this.usernameChanged}
          value={this.state.username}
          disabled={!enableFields}
        /></div>
        <div>
          <TextField
            type="password"
            label="Password"
            onChange={this.passwordChanged}
            value={this.state.password}
            disabled={!enableFields}
            placeholder="Password"/>
          <div style={{
            color: 'red',
          }}>
            {this.props.message}
          </div>
        </div>
        <CircularProgress
          style={{
            position: 'absolute',
            margin: 'auto',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
            visibility: spinnerVisibility
          }}
        />
        <span style={{
          marginTop: '2rem',
          display: 'inline-flex',
          alignContent: 'space-between'
        }}><Button variant="contained"
          style={{marginRight: '1rem'}}
          color="primary"
          disabled={!enableLogin}
          onClick={this.doLogin}
        >LOG IN</Button></span>
      </Paper>
    )
  }
}

LoginComponent.propTypes = {
  doLogin: PropTypes.func.isRequired,
  doForgetPassword: PropTypes.func.isRequired,
  message: PropTypes.string
};
