import {createAction, createPromiseAction, actionType, actionMetaData, actionResult, actionArguments} from './index'
import {getRestaurants, getDataForRestaurant, setDayDealAvailablePlaces, deleteDayDeal, deleteDeal, updateDeal, cancelReservation, addDeal, updateRestaurantInfo, deleteRestaurant, updatePictures, convertToProspect} from '../controller/restaurant'
import {Deal, RestaurantsInfo, Restaurant} from '../model/restaurant'
import {history} from "../history"
import React from "react"
import {getAllProspectsAction} from "./prospect"

const GET_RESTAURANTS = 'GET_RESTAURANTS'
const GET_DATA_FOR_SELECTED_RESTAURANT = 'GET_DATA_FOR_SELECTED_RESTAURANT'
const SET_DAY_DEAL_AVAILABLE_PLACES = 'SET_DAY_DEAL_AVAILABLE_PLACES'
const DELETE_DAY_DEAL = 'DELETE_DAY_DEAL'
const ADD_DEAL = 'ADD_DEAL'
const DELETE_DEAL = 'DELETE_DEAL'
const UPDATE_DEAL = 'UPDATE_DEAL'
const CANCEL_RESERVATION = 'CANCEL_RESERVATION'
const UPDATE_RESTAURANT_INFO = 'UPDATE_RESTAURANT_INFO'
const DELETE_RESTAURANT = 'DELETE_RESTAURANT'
const UPDATE_PICTURES = 'UPDATE_PICTURES'
const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
const SET_GROUP_BY_ZIPCODE = 'SET_GROUP_BY_ZIPCODE'
const SET_FILTER = 'SET_FILTER'
const CONVERT_TO_PROSPECT = 'CONVERT_TO_PROSPECT'

export function getAllRestaurantsAction() {
  const promise = getRestaurants()
  return createPromiseAction(GET_RESTAURANTS, promise, {})
}

export function selectRestaurantAction(id) {
  const promise = getDataForRestaurant(id)
  return createPromiseAction(GET_DATA_FOR_SELECTED_RESTAURANT, promise, {id})
}

export function setDayDealAvailablePlacesAction(deal, availablePlaces) {
  const promise = setDayDealAvailablePlaces(deal, availablePlaces)
  return createPromiseAction(SET_DAY_DEAL_AVAILABLE_PLACES, promise, {deal, availablePlaces})
}

export function deleteDayDealAction(restaurant, deal) {
  const promise = deleteDayDeal(restaurant, deal)
  return createPromiseAction(DELETE_DAY_DEAL, promise, {deal})
}

export function deleteDealAction(restaurant, deal) {
  const promise = deleteDeal(restaurant, deal)
  return createPromiseAction(DELETE_DEAL, promise, {deal})
}

export function updateDealAction(restaurant, deal) {
  const promise = updateDeal(restaurant, deal)
  return createPromiseAction(UPDATE_DEAL, promise, {deal})
}

export function toggleDealPauseStateAction(restaurant, deal) {
  let updatedDeal = deal.set('pause',!deal.pause)
  const promise = updateDeal(restaurant, updatedDeal)
  return createPromiseAction(UPDATE_DEAL, promise, {deal:updatedDeal})
}

export function addDealAction(restaurant, deal) {
  const promise = addDeal(restaurant, deal)
  return createPromiseAction(ADD_DEAL, promise, {deal})
}

export function cancelReservationAction(reservation) {
  const promise = cancelReservation(reservation)
  return createPromiseAction(CANCEL_RESERVATION, promise, {reservation})
}

export function updateRestaurantInfoAction(restaurant) {
  const promise = updateRestaurantInfo(restaurant)
  return createPromiseAction(UPDATE_RESTAURANT_INFO, promise, {restaurant})
}

export function deleteRestaurantAction(restaurant) {
  const promise = deleteRestaurant(restaurant)
  return createPromiseAction(DELETE_RESTAURANT, promise, {restaurant})
}

export function updatePicturesAction(restaurant, image, menu, menuMimeType) {
  const promise = updatePictures(restaurant, image, menu, menuMimeType)

  return createPromiseAction(UPDATE_PICTURES, promise)
}

export function updateSubscriptionAction(restaurant, subscription) {
  return createAction(UPDATE_SUBSCRIPTION, {subscription})
}

export function setRestaurantFilterAction(filter) {
  return createAction(SET_FILTER, {filter})
}

export function setRestaurantGroupByZipcodeAction(groupByZipcode) {
  return createAction(SET_GROUP_BY_ZIPCODE, {groupByZipcode})
}

export function convertToProspectAction(restaurant) {
  return (dispatch) => {
    const promise = convertToProspect(restaurant)

    dispatch(createPromiseAction(CONVERT_TO_PROSPECT, promise, {restaurant})).then(() => {
      return dispatch(getAllProspectsAction())
    }).then(() => {
      history.push(`/prospect/${restaurant.id}`)
    })
  }
}

export function reduceRestaurants(state = null, action = null) {
  if (!state) return new RestaurantsInfo()

  let nextState = state
  const meta = actionMetaData(action)
  const result = actionResult(action)
  const args = actionArguments(action)

  switch (actionType(action)) {
    case `${ADD_DEAL}_BEGIN`:
    case `${UPDATE_DEAL}_BEGIN`:
    case `${GET_RESTAURANTS}_BEGIN`:
    case `${SET_DAY_DEAL_AVAILABLE_PLACES}_BEGIN`:
    case `${DELETE_DAY_DEAL}_BEGIN`:
    case `${DELETE_DEAL}_BEGIN`:
    case `${CANCEL_RESERVATION}_BEGIN`:
    case `${UPDATE_RESTAURANT_INFO}_BEGIN`:
    case `${DELETE_RESTAURANT}_BEGIN`:
    case `${UPDATE_PICTURES}_BEGIN`:
      break
    case `${GET_RESTAURANTS}_SUCCESS`:
      nextState = nextState.merge({restaurants: result})
      break
    case `${ADD_DEAL}_ERROR`:
    case `${UPDATE_DEAL}_ERROR`:
    case `${GET_RESTAURANTS}_ERROR`:
    case `${SET_DAY_DEAL_AVAILABLE_PLACES}_ERROR`:
    case `${DELETE_DAY_DEAL}_ERROR`:
    case `${DELETE_DEAL}_ERROR`:
    case `${CANCEL_RESERVATION}_ERROR`:
    case `${UPDATE_RESTAURANT_INFO}_ERROR`:
    case `${DELETE_RESTAURANT}_ERROR`:
    case `${UPDATE_PICTURES}_ERROR`:
    case `${CONVERT_TO_PROSPECT}_ERROR`:
      break
    case UPDATE_SUBSCRIPTION: {
      if (result.subscription) {
        const subscription = result.subscription
        let selectedRestaurant = nextState.selectedRestaurant
        const restaurantIndex = nextState.restaurants.findIndex((r) => {
          return r.id === selectedRestaurant.id
        })

        selectedRestaurant = selectedRestaurant.merge({
          braintreeCustomerId: null,
          stripeCustomerId: subscription.stripeCustomerId
        })
        const restaurants = nextState.restaurants.set(restaurantIndex, selectedRestaurant)
        nextState = nextState.merge({restaurants, selectedRestaurant, subscriptionForSelectedRestaurant: subscription})
      }
    }
      break
    case `${UPDATE_PICTURES}_SUCCESS`:
      break
    case `${GET_DATA_FOR_SELECTED_RESTAURANT}_BEGIN`:
      nextState = nextState.merge({
        selectedRestaurant: nextState.restaurants.find(restaurant => restaurant.id === meta.id),
        reservationsForSelectedRestaurant: null,
        dealsForSelectedRestaurant: null,
        subscriptionForSelectedRestaurant: null
      })
      break
    case `${GET_DATA_FOR_SELECTED_RESTAURANT}_SUCCESS`:
      nextState = nextState.merge({
        reservationsForSelectedRestaurant: result.reservations,
        dealsForSelectedRestaurant: result.deals,
        subscriptionForSelectedRestaurant: result.subscription
      })
      break
    case `${SET_DAY_DEAL_AVAILABLE_PLACES}_SUCCESS`: {
      let dayDeal = meta.deal
      dayDeal = dayDeal.set('availablePlaces', meta.availablePlaces)
      let deals = nextState.dealsForSelectedRestaurant.map((deal) => {
        if (deal.id === dayDeal.id)
          return dayDeal

        return deal
      })
      nextState = nextState.merge({dealsForSelectedRestaurant: deals})
    }
      break
    case `${DELETE_DAY_DEAL}_SUCCESS`: {
      let dayDeal = meta.deal
      dayDeal = dayDeal.set('availablePlaces', null)
      dayDeal = dayDeal.set('dayDealId', null)
      let deals = nextState.dealsForSelectedRestaurant.map((deal) => {
        if (deal.id === dayDeal.id)
          return dayDeal

        return deal
      })
      nextState = nextState.merge({dealsForSelectedRestaurant: deals})
    }
      break
    case `${DELETE_DEAL}_SUCCESS`: {
      let deletedDeal = meta.deal
      let deals = nextState.dealsForSelectedRestaurant.filter((deal) => {
        return deletedDeal.id !== deal.id
      })
      nextState = nextState.merge({dealsForSelectedRestaurant: deals})
    }
      break
    case `${ADD_DEAL}_SUCCESS`: {
      let addedDeal = result
      let deals = nextState.dealsForSelectedRestaurant.push(Deal.fromJson(addedDeal))
      nextState = nextState.merge({dealsForSelectedRestaurant: deals})
    }
      break
    case `${UPDATE_DEAL}_SUCCESS`: {
      let modifiedDeal = meta.deal
      let originalDeal = nextState.dealsForSelectedRestaurant.find(deal => deal.id === modifiedDeal.id)
      originalDeal = originalDeal.merge(modifiedDeal)

      let deals = nextState.dealsForSelectedRestaurant.map((deal) => {
        if(deal.id === modifiedDeal.id) {
          return originalDeal
        }

        return deal
      })
      nextState = nextState.merge({dealsForSelectedRestaurant: deals})
    }
      break
    case `${CANCEL_RESERVATION}_SUCCESS`: {
      const reservation = meta.reservation
      const filtered = nextState.reservationsForSelectedRestaurant.filter((res) => res.id !== reservation.id)
      nextState = nextState.set('reservationsForSelectedRestaurant', filtered)
      let deals = nextState.dealsForSelectedRestaurant
      let dayDeal = deals.find((deal) => {
        return deal.dayDealId == reservation.dayDealId
      })

      if (dayDeal) {
        dayDeal = dayDeal.set('availablePlaces', dayDeal.availablePlaces + reservation.places)

        deals = deals.map((deal) => {
          if (deal.id === dayDeal.id)
            return dayDeal

          return deal
        })

        nextState = nextState.merge({dealsForSelectedRestaurant: deals})
      }
    }
      break
    case `${UPDATE_RESTAURANT_INFO}_SUCCESS`: {
      const selectedRestaurant = new Restaurant(meta.restaurant)
      const restaurantIndex = nextState.restaurants.findIndex((r) => {
        return r.id === selectedRestaurant.id
      })
      const restaurants = nextState.restaurants.set(restaurantIndex, selectedRestaurant)
      nextState = nextState.merge({restaurants, selectedRestaurant})
    }
      break
    case `${DELETE_RESTAURANT}_SUCCESS`: {
      let selectedRestaurant = new Restaurant(meta.restaurant)
      const restaurantIndex = nextState.restaurants.findIndex((r) => {
        return r.id === selectedRestaurant.id
      })
      const restaurants = nextState.restaurants.delete(restaurantIndex)

      if (restaurants.size) {
        if (restaurantIndex)
          selectedRestaurant = restaurants.get(restaurantIndex - 1)
        else
          selectedRestaurant = restaurants.get(0)
      } else {
        selectedRestaurant = null
      }

      if (selectedRestaurant) {
        history.push(`/restaurant/${selectedRestaurant.id}`)
      } else {
        history.push(`/restaurant`)
      }

      nextState = nextState.merge({
        restaurants,
        selectedRestaurant,
        dealsForSelectedRestaurant: null,
        reservationsForSelectedRestaurant: null,
        subscriptionForSelectedRestaurant: null
      })

    }
      break
    case SET_FILTER: {
      nextState = nextState.merge({
        filter: args.filter
      })

    }
      break
    case SET_GROUP_BY_ZIPCODE : {
      nextState = nextState.merge({
        groupByZipcode: args.groupByZipcode
      })

    }
      break
    case `${CONVERT_TO_PROSPECT}_BEGIN`: {
    }
      break
    case `${CONVERT_TO_PROSPECT}_SUCCESS`: {
      let restaurants = nextState.restaurants.filter((p) => {
        return p.id !== meta.restaurant.id
      })
      nextState = nextState.merge({
        restaurants
      })
    }
      break
  }

  return nextState
}
