import Immutable from 'immutable'

const adminRole = 'ADMIN'

export function isAdmin(user) {
  return user.role === adminRole
}

export const LoggedInUser = Immutable.Record({
  id:null,
  email:null,
  token:null
})

export const Login = Immutable.Record({
  loggedInUser:null,
  loggingIn: false,
  loggedIn: false,
  loginMessage: null
})