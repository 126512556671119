export default {
  "1000": "BRUSSEL",
  "1020": "BRUSSEL",
  "1030": "SCHAARBEEK",
  "1040": "ETTERBEEK",
  "1050": "ELSENE",
  "1060": "SINT-GILLIS",
  "1070": "ANDERLECHT",
  "1080": "SINT-JANS-MOLENBEEK",
  "1081": "KOEKELBERG",
  "1082": "SINT-AGATHA-BERCHEM",
  "1083": "GANSHOREN",
  "1090": "JETTE",
  "1120": "BRUSSEL",
  "1130": "BRUSSEL",
  "1140": "EVERE",
  "1150": "SINT-PIETERS-WOLUWE",
  "1160": "OUDERGEM",
  "1170": "WATERMAAL-BOSVOORDE",
  "1180": "UKKEL",
  "1190": "VORST",
  "1200": "SINT-LAMBRECHTS-WOLUWE",
  "1210": "SINT-JOOST-TEN-NOODE",
  "1300": "WAVRE",
  "1301": "WAVRE",
  "1310": "LA HULPE",
  "1315": "INCOURT",
  "1320": "BEAUVECHAIN",
  "1325": "CHAUMONT-GISTOUX",
  "1330": "RIXENSART",
  "1331": "RIXENSART",
  "1332": "RIXENSART",
  "1340": "OTTIGNIES-LOUVAIN-LA-NEUVE",
  "1341": "OTTIGNIES-LOUVAIN-LA-NEUVE",
  "1342": "OTTIGNIES-LOUVAIN-LA-NEUVE",
  "1348": "OTTIGNIES-LOUVAIN-LA-NEUVE",
  "1350": "ORP-JAUCHE",
  "1357": "HÉLÉCINE",
  "1360": "PERWEZ",
  "1367": "RAMILLIES",
  "1370": "JODOIGNE",
  "1380": "LASNE",
  "1390": "GREZ-DOICEAU",
  "1400": "NIVELLES",
  "1401": "NIVELLES",
  "1402": "NIVELLES",
  "1404": "NIVELLES",
  "1410": "WATERLOO",
  "1420": "BRAINE-L'ALLEUD",
  "1421": "BRAINE-L'ALLEUD",
  "1428": "BRAINE-L'ALLEUD",
  "1430": "REBECQ",
  "1435": "MONT-SAINT-GUIBERT",
  "1440": "BRAINE-LE-CHÂTEAU",
  "1450": "CHASTRE",
  "1457": "WALHAIN",
  "1460": "ITTRE",
  "1461": "ITTRE",
  "1470": "GENAPPE",
  "1471": "GENAPPE",
  "1472": "GENAPPE",
  "1473": "GENAPPE",
  "1474": "GENAPPE",
  "1476": "GENAPPE",
  "1480": "TUBIZE",
  "1490": "COURT-SAINT-ETIENNE",
  "1495": "VILLERS-LA-VILLE",
  "1500": "HALLE",
  "1501": "HALLE",
  "1502": "HALLE",
  "1540": "HERNE",
  "1541": "HERNE",
  "1547": "BEVER",
  "1560": "HOEILAART",
  "1570": "GALMAARDEN",
  "1600": "SINT-PIETERS-LEEUW",
  "1601": "SINT-PIETERS-LEEUW",
  "1602": "SINT-PIETERS-LEEUW",
  "1620": "DROGENBOS",
  "1630": "LINKEBEEK",
  "1640": "SINT-GENESIUS-RODE",
  "1650": "BEERSEL",
  "1651": "BEERSEL",
  "1652": "BEERSEL",
  "1653": "BEERSEL",
  "1654": "BEERSEL",
  "1670": "PEPINGEN",
  "1671": "PEPINGEN",
  "1673": "PEPINGEN",
  "1674": "PEPINGEN",
  "1700": "DILBEEK",
  "1701": "DILBEEK",
  "1702": "DILBEEK",
  "1703": "DILBEEK",
  "1730": "ASSE",
  "1731": "ASSE",
  "1733": "HighCo DATA",
  "1740": "TERNAT",
  "1741": "TERNAT",
  "1742": "TERNAT",
  "1745": "OPWIJK",
  "1750": "LENNIK",
  "1755": "GOOIK",
  "1760": "ROOSDAAL",
  "1761": "ROOSDAAL",
  "1770": "LIEDEKERKE",
  "1780": "WEMMEL",
  "1785": "MERCHTEM",
  "1790": "AFFLIGEM",
  "1800": "VILVOORDE",
  "1804": "Cargovil",
  "1818": "VTM",
  "1820": "STEENOKKERZEEL",
  "1830": "MACHELEN",
  "1831": "MACHELEN",
  "1840": "LONDERZEEL",
  "1850": "GRIMBERGEN",
  "1851": "GRIMBERGEN",
  "1852": "GRIMBERGEN",
  "1853": "GRIMBERGEN",
  "1860": "MEISE",
  "1861": "MEISE",
  "1880": "KAPELLE-OP-DEN-BOS",
  "1910": "KAMPENHOUT",
  "1930": "ZAVENTEM",
  "1931": "Brucargo",
  "1932": "ZAVENTEM",
  "1933": "ZAVENTEM",
  "1950": "KRAAINEM",
  "1970": "WEZEMBEEK-OPPEM",
  "1980": "ZEMST",
  "1981": "ZEMST",
  "1982": "ZEMST",
  "2000": "ANTWERPEN",
  "2018": "ANTWERPEN",
  "2020": "ANTWERPEN",
  "2030": "ANTWERPEN",
  "2040": "ANTWERPEN",
  "2050": "ANTWERPEN",
  "2060": "ANTWERPEN",
  "2070": "ZWIJNDRECHT",
  "2100": "ANTWERPEN",
  "2110": "WIJNEGEM",
  "2140": "ANTWERPEN",
  "2150": "BORSBEEK",
  "2160": "WOMMELGEM",
  "2170": "ANTWERPEN",
  "2180": "ANTWERPEN",
  "2200": "HERENTALS",
  "2220": "HEIST-OP-DEN-BERG",
  "2221": "HEIST-OP-DEN-BERG",
  "2222": "HEIST-OP-DEN-BERG",
  "2223": "HEIST-OP-DEN-BERG",
  "2230": "HERSELT",
  "2235": "HULSHOUT",
  "2240": "ZANDHOVEN",
  "2242": "ZANDHOVEN",
  "2243": "ZANDHOVEN",
  "2250": "OLEN",
  "2260": "WESTERLO",
  "2270": "HERENTHOUT",
  "2275": "LILLE",
  "2280": "GROBBENDONK",
  "2288": "GROBBENDONK",
  "2290": "VORSELAAR",
  "2300": "TURNHOUT",
  "2310": "RIJKEVORSEL",
  "2320": "HOOGSTRATEN",
  "2321": "HOOGSTRATEN",
  "2322": "HOOGSTRATEN",
  "2323": "HOOGSTRATEN",
  "2328": "HOOGSTRATEN",
  "2330": "MERKSPLAS",
  "2340": "BEERSE",
  "2350": "VOSSELAAR",
  "2360": "OUD-TURNHOUT",
  "2370": "ARENDONK",
  "2380": "RAVELS",
  "2381": "RAVELS",
  "2382": "RAVELS",
  "2387": "BAARLE-HERTOG",
  "2390": "MALLE",
  "2400": "MOL",
  "2430": "LAAKDAL",
  "2431": "LAAKDAL",
  "2440": "GEEL",
  "2450": "MEERHOUT",
  "2460": "KASTERLEE",
  "2470": "RETIE",
  "2480": "DESSEL",
  "2490": "BALEN",
  "2491": "BALEN",
  "2500": "LIER",
  "2520": "RANST",
  "2530": "BOECHOUT",
  "2531": "BOECHOUT",
  "2540": "HOVE",
  "2547": "LINT",
  "2550": "KONTICH",
  "2560": "NIJLEN",
  "2570": "DUFFEL",
  "2580": "PUTTE",
  "2590": "BERLAAR",
  "2600": "ANTWERPEN",
  "2610": "ANTWERPEN",
  "2620": "HEMIKSEM",
  "2627": "SCHELLE",
  "2630": "AARTSELAAR",
  "2640": "MORTSEL",
  "2650": "EDEGEM",
  "2660": "ANTWERPEN",
  "2800": "MECHELEN",
  "2801": "MECHELEN",
  "2811": "MECHELEN",
  "2812": "MECHELEN",
  "2820": "BONHEIDEN",
  "2830": "WILLEBROEK",
  "2840": "RUMST",
  "2845": "NIEL",
  "2850": "BOOM",
  "2860": "SINT-KATELIJNE-WAVER",
  "2861": "SINT-KATELIJNE-WAVER",
  "2870": "PUURS-SINT-AMANDS",
  "2880": "BORNEM",
  "2890": "PUURS-SINT-AMANDS",
  "2900": "SCHOTEN",
  "2910": "ESSEN",
  "2920": "KALMTHOUT",
  "2930": "BRASSCHAAT",
  "2940": "STABROEK",
  "2950": "KAPELLEN",
  "2960": "BRECHT",
  "2970": "SCHILDE",
  "2980": "ZOERSEL",
  "2990": "WUUSTWEZEL",
  "3000": "LEUVEN",
  "3001": "LEUVEN",
  "3010": "LEUVEN",
  "3012": "LEUVEN",
  "3018": "LEUVEN",
  "3020": "HERENT",
  "3040": "HULDENBERG",
  "3050": "OUD-HEVERLEE",
  "3051": "OUD-HEVERLEE",
  "3052": "OUD-HEVERLEE",
  "3053": "OUD-HEVERLEE",
  "3054": "OUD-HEVERLEE",
  "3060": "BERTEM",
  "3061": "BERTEM",
  "3070": "KORTENBERG",
  "3071": "KORTENBERG",
  "3078": "KORTENBERG",
  "3080": "TERVUREN",
  "3090": "OVERIJSE",
  "3110": "ROTSELAAR",
  "3111": "ROTSELAAR",
  "3118": "ROTSELAAR",
  "3120": "TREMELO",
  "3128": "TREMELO",
  "3130": "BEGIJNENDIJK",
  "3140": "KEERBERGEN",
  "3150": "HAACHT",
  "3190": "BOORTMEERBEEK",
  "3191": "BOORTMEERBEEK",
  "3200": "AARSCHOT",
  "3201": "AARSCHOT",
  "3202": "AARSCHOT",
  "3210": "LUBBEEK",
  "3211": "LUBBEEK",
  "3212": "LUBBEEK",
  "3220": "HOLSBEEK",
  "3221": "HOLSBEEK",
  "3270": "SCHERPENHEUVEL-ZICHEM",
  "3271": "SCHERPENHEUVEL-ZICHEM",
  "3272": "SCHERPENHEUVEL-ZICHEM",
  "3290": "DIEST",
  "3293": "DIEST",
  "3294": "DIEST",
  "3300": "TIENEN",
  "3320": "HOEGAARDEN",
  "3321": "HOEGAARDEN",
  "3350": "LINTER",
  "3360": "BIERBEEK",
  "3370": "BOUTERSEM",
  "3380": "GLABBEEK",
  "3381": "GLABBEEK",
  "3384": "GLABBEEK",
  "3390": "TIELT-WINGE",
  "3391": "TIELT-WINGE",
  "3400": "LANDEN",
  "3401": "LANDEN",
  "3404": "LANDEN",
  "3440": "ZOUTLEEUW",
  "3450": "GEETBETS",
  "3454": "GEETBETS",
  "3460": "BEKKEVOORT",
  "3461": "BEKKEVOORT",
  "3470": "KORTENAKEN",
  "3471": "KORTENAKEN",
  "3472": "KORTENAKEN",
  "3473": "KORTENAKEN",
  "3500": "HASSELT",
  "3501": "HASSELT",
  "3510": "HASSELT",
  "3511": "HASSELT",
  "3512": "HASSELT",
  "3520": "ZONHOVEN",
  "3530": "HOUTHALEN-HELCHTEREN",
  "3540": "HERK-DE-STAD",
  "3545": "HALEN",
  "3550": "HEUSDEN-ZOLDER",
  "3560": "LUMMEN",
  "3570": "ALKEN",
  "3580": "BERINGEN",
  "3581": "BERINGEN",
  "3582": "BERINGEN",
  "3583": "BERINGEN",
  "3590": "DIEPENBEEK",
  "3600": "GENK",
  "3620": "LANAKEN",
  "3621": "LANAKEN",
  "3630": "MAASMECHELEN",
  "3631": "MAASMECHELEN",
  "3640": "KINROOI",
  "3650": "DILSEN-STOKKEM",
  "3660": "OUDSBERGEN",
  "3665": "AS",
  "3668": "AS",
  "3670": "OUDSBERGEN",
  "3680": "MAASEIK",
  "3690": "ZUTENDAAL",
  "3700": "TONGEREN",
  "3717": "HERSTAPPE",
  "3720": "KORTESSEM",
  "3721": "KORTESSEM",
  "3722": "KORTESSEM",
  "3723": "KORTESSEM",
  "3724": "KORTESSEM",
  "3730": "HOESELT",
  "3732": "HOESELT",
  "3740": "BILZEN",
  "3742": "BILZEN",
  "3746": "BILZEN",
  "3770": "RIEMST",
  "3790": "VOEREN",
  "3791": "VOEREN",
  "3792": "VOEREN",
  "3793": "VOEREN",
  "3798": "VOEREN",
  "3800": "SINT-TRUIDEN",
  "3803": "SINT-TRUIDEN",
  "3806": "SINT-TRUIDEN",
  "3830": "WELLEN",
  "3831": "WELLEN",
  "3832": "WELLEN",
  "3840": "BORGLOON",
  "3850": "NIEUWERKERKEN",
  "3870": "HEERS",
  "3890": "GINGELOM",
  "3891": "GINGELOM",
  "3900": "PELT",
  "3910": "PELT",
  "3920": "LOMMEL",
  "3930": "HAMONT-ACHEL",
  "3940": "HECHTEL-EKSEL",
  "3941": "HECHTEL-EKSEL",
  "3945": "HAM",
  "3950": "BOCHOLT",
  "3960": "BREE",
  "3970": "LEOPOLDSBURG",
  "3971": "LEOPOLDSBURG",
  "3980": "TESSENDERLO",
  "3990": "PEER",
  "4000": "LIÈGE",
  "4020": "LIÈGE",
  "4030": "LIÈGE",
  "4031": "LIÈGE",
  "4032": "LIÈGE",
  "4040": "HERSTAL",
  "4041": "HERSTAL",
  "4042": "HERSTAL",
  "4050": "CHAUDFONTAINE",
  "4051": "CHAUDFONTAINE",
  "4052": "CHAUDFONTAINE",
  "4053": "CHAUDFONTAINE",
  "4100": "SERAING",
  "4101": "SERAING",
  "4102": "SERAING",
  "4120": "NEUPRÉ",
  "4121": "NEUPRÉ",
  "4122": "NEUPRÉ",
  "4130": "ESNEUX",
  "4140": "SPRIMONT",
  "4141": "SPRIMONT",
  "4160": "ANTHISNES",
  "4161": "ANTHISNES",
  "4162": "ANTHISNES",
  "4163": "ANTHISNES",
  "4170": "COMBLAIN-AU-PONT",
  "4171": "COMBLAIN-AU-PONT",
  "4180": "HAMOIR",
  "4181": "HAMOIR",
  "4190": "FERRIÈRES",
  "4210": "BURDINNE",
  "4217": "HÉRON",
  "4218": "HÉRON",
  "4219": "WASSEIGES",
  "4250": "GEER",
  "4252": "GEER",
  "4253": "GEER",
  "4254": "GEER",
  "4257": "BERLOZ",
  "4260": "BRAIVES",
  "4261": "BRAIVES",
  "4263": "BRAIVES",
  "4280": "HANNUT",
  "4287": "LINCENT",
  "4300": "WAREMME",
  "4317": "FAIMES",
  "4340": "AWANS",
  "4342": "AWANS",
  "4347": "FEXHE-LE-HAUT-CLOCHER",
  "4350": "REMICOURT",
  "4351": "REMICOURT",
  "4357": "DONCEEL",
  "4360": "OREYE",
  "4367": "CRISNÉE",
  "4400": "FLÉMALLE",
  "4420": "SAINT-NICOLAS",
  "4430": "ANS",
  "4431": "ANS",
  "4432": "ANS",
  "4450": "JUPRELLE",
  "4451": "JUPRELLE",
  "4452": "JUPRELLE",
  "4453": "JUPRELLE",
  "4458": "JUPRELLE",
  "4460": "GRÂCE-HOLLOGNE",
  "4470": "SAINT-GEORGES-SUR-MEUSE",
  "4480": "ENGIS",
  "4500": "HUY",
  "4520": "WANZE",
  "4530": "VILLERS-LE-BOUILLET",
  "4537": "VERLAINE",
  "4540": "AMAY",
  "4550": "NANDRIN",
  "4557": "TINLOT",
  "4560": "CLAVIER",
  "4570": "MARCHIN",
  "4577": "MODAVE",
  "4590": "OUFFET",
  "4600": "VISÉ",
  "4601": "VISÉ",
  "4602": "VISÉ",
  "4606": "DALHEM",
  "4607": "DALHEM",
  "4608": "DALHEM",
  "4610": "BEYNE-HEUSAY",
  "4620": "FLÉRON",
  "4621": "FLÉRON",
  "4623": "FLÉRON",
  "4624": "FLÉRON",
  "4630": "SOUMAGNE",
  "4631": "SOUMAGNE",
  "4632": "SOUMAGNE",
  "4633": "SOUMAGNE",
  "4650": "HERVE",
  "4651": "HERVE",
  "4652": "HERVE",
  "4653": "HERVE",
  "4654": "HERVE",
  "4670": "BLÉGNY",
  "4671": "BLÉGNY",
  "4672": "BLÉGNY",
  "4680": "OUPEYE",
  "4681": "OUPEYE",
  "4682": "OUPEYE",
  "4683": "OUPEYE",
  "4684": "OUPEYE",
  "4690": "BASSENGE",
  "4700": "EUPEN",
  "4701": "EUPEN",
  "4710": "LONTZEN",
  "4711": "LONTZEN",
  "4720": "KELMIS",
  "4721": "KELMIS",
  "4728": "KELMIS",
  "4730": "RAEREN",
  "4731": "RAEREN",
  "4750": "BUTGENBACH",
  "4760": "BÜLLINGEN",
  "4761": "BÜLLINGEN",
  "4770": "AMEL",
  "4771": "AMEL",
  "4780": "SANKT-VITH",
  "4782": "SANKT-VITH",
  "4783": "SANKT-VITH",
  "4784": "SANKT-VITH",
  "4790": "BURG-REULAND",
  "4791": "BURG-REULAND",
  "4800": "VERVIERS",
  "4801": "VERVIERS",
  "4802": "VERVIERS",
  "4820": "DISON",
  "4821": "DISON",
  "4830": "LIMBOURG",
  "4831": "LIMBOURG",
  "4834": "LIMBOURG",
  "4837": "BAELEN",
  "4840": "WELKENRAEDT",
  "4841": "WELKENRAEDT",
  "4845": "JALHAY",
  "4850": "PLOMBIÈRES",
  "4851": "PLOMBIÈRES",
  "4852": "PLOMBIÈRES",
  "4860": "PEPINSTER",
  "4861": "PEPINSTER",
  "4870": "TROOZ",
  "4877": "OLNE",
  "4880": "AUBEL",
  "4890": "THIMISTER-CLERMONT",
  "4900": "SPA",
  "4910": "THEUX",
  "4920": "AYWAILLE",
  "4950": "WEISMES",
  "4960": "MALMEDY",
  "4970": "STAVELOT",
  "4980": "TROIS-PONTS",
  "4983": "TROIS-PONTS",
  "4987": "STOUMONT",
  "4990": "LIERNEUX",
  "5000": "NAMUR",
  "5001": "NAMUR",
  "5002": "NAMUR",
  "5003": "NAMUR",
  "5004": "NAMUR",
  "5010": "SA SudPresse",
  "5012": "Parlement Wallon",
  "5020": "NAMUR",
  "5021": "NAMUR",
  "5022": "NAMUR",
  "5024": "NAMUR",
  "5030": "GEMBLOUX",
  "5031": "GEMBLOUX",
  "5032": "GEMBLOUX",
  "5060": "SAMBREVILLE",
  "5070": "FOSSES-LA-VILLE",
  "5080": "LA BRUYÈRE",
  "5081": "LA BRUYÈRE",
  "5100": "NAMUR",
  "5101": "NAMUR",
  "5140": "SOMBREFFE",
  "5150": "FLOREFFE",
  "5170": "PROFONDEVILLE",
  "5190": "JEMEPPE-SUR-SAMBRE",
  "5300": "ANDENNE",
  "5310": "EGHEZÉE",
  "5330": "ASSESSE",
  "5332": "ASSESSE",
  "5333": "ASSESSE",
  "5334": "ASSESSE",
  "5336": "ASSESSE",
  "5340": "GESVES",
  "5350": "OHEY",
  "5351": "OHEY",
  "5352": "OHEY",
  "5353": "OHEY",
  "5354": "OHEY",
  "5360": "HAMOIS",
  "5361": "HAMOIS",
  "5362": "HAMOIS",
  "5363": "HAMOIS",
  "5364": "HAMOIS",
  "5370": "HAVELANGE",
  "5372": "HAVELANGE",
  "5374": "HAVELANGE",
  "5376": "HAVELANGE",
  "5377": "SOMME-LEUZE",
  "5380": "FERNELMONT",
  "5500": "DINANT",
  "5501": "DINANT",
  "5502": "DINANT",
  "5503": "DINANT",
  "5504": "DINANT",
  "5520": "ONHAYE",
  "5521": "ONHAYE",
  "5522": "ONHAYE",
  "5523": "ONHAYE",
  "5524": "ONHAYE",
  "5530": "YVOIR",
  "5537": "ANHÉE",
  "5540": "HASTIÈRE",
  "5541": "HASTIÈRE",
  "5542": "HASTIÈRE",
  "5543": "HASTIÈRE",
  "5544": "HASTIÈRE",
  "5550": "VRESSE-SUR-SEMOIS",
  "5555": "BIÈVRE",
  "5560": "HOUYET",
  "5561": "HOUYET",
  "5562": "HOUYET",
  "5563": "HOUYET",
  "5564": "HOUYET",
  "5570": "BEAURAING",
  "5571": "BEAURAING",
  "5572": "BEAURAING",
  "5573": "BEAURAING",
  "5574": "BEAURAING",
  "5575": "GEDINNE",
  "5576": "BEAURAING",
  "5580": "ROCHEFORT",
  "5589": "Jemelle",
  "5590": "CINEY",
  "5600": "PHILIPPEVILLE",
  "5620": "FLORENNES",
  "5621": "FLORENNES",
  "5630": "CERFONTAINE",
  "5640": "METTET",
  "5641": "METTET",
  "5644": "METTET",
  "5646": "METTET",
  "5650": "WALCOURT",
  "5651": "WALCOURT",
  "5660": "COUVIN",
  "5670": "VIROINVAL",
  "5680": "DOISCHE",
  "6000": "CHARLEROI",
  "6001": "CHARLEROI",
  "6010": "CHARLEROI",
  "6020": "CHARLEROI",
  "6030": "CHARLEROI",
  "6031": "CHARLEROI",
  "6032": "CHARLEROI",
  "6040": "CHARLEROI",
  "6041": "CHARLEROI",
  "6042": "CHARLEROI",
  "6043": "CHARLEROI",
  "6044": "CHARLEROI",
  "6060": "CHARLEROI",
  "6061": "CHARLEROI",
  "6075": "CSM Charleroi X",
  "6099": "Charleroi X",
  "6110": "MONTIGNY-LE-TILLEUL",
  "6111": "MONTIGNY-LE-TILLEUL",
  "6120": "HAM-SUR-HEURE",
  "6140": "FONTAINE-L'EVÊQUE",
  "6141": "FONTAINE-L'EVÊQUE",
  "6142": "FONTAINE-L'EVÊQUE",
  "6150": "ANDERLUES",
  "6180": "COURCELLES",
  "6181": "COURCELLES",
  "6182": "COURCELLES",
  "6183": "COURCELLES",
  "6200": "CHÂTELET",
  "6210": "LES BONS VILLERS",
  "6211": "LES BONS VILLERS",
  "6220": "FLEURUS",
  "6221": "FLEURUS",
  "6222": "FLEURUS",
  "6223": "FLEURUS",
  "6224": "FLEURUS",
  "6230": "PONT-À-CELLES",
  "6238": "PONT-À-CELLES",
  "6240": "FARCIENNES",
  "6250": "AISEAU-PRESLES",
  "6280": "GERPINNES",
  "6440": "FROIDCHAPELLE",
  "6441": "FROIDCHAPELLE",
  "6460": "CHIMAY",
  "6461": "CHIMAY",
  "6462": "CHIMAY",
  "6463": "CHIMAY",
  "6464": "CHIMAY",
  "6470": "SIVRY-RANCE",
  "6500": "BEAUMONT",
  "6511": "BEAUMONT",
  "6530": "THUIN",
  "6531": "THUIN",
  "6532": "THUIN",
  "6533": "THUIN",
  "6534": "THUIN",
  "6536": "THUIN",
  "6540": "LOBBES",
  "6542": "LOBBES",
  "6543": "LOBBES",
  "6560": "ERQUELINNES",
  "6567": "MERBES-LE-CHÂTEAU",
  "6590": "MOMIGNIES",
  "6591": "MOMIGNIES",
  "6592": "MOMIGNIES",
  "6593": "MOMIGNIES",
  "6594": "MOMIGNIES",
  "6596": "MOMIGNIES",
  "6600": "BASTOGNE",
  "6630": "MARTELANGE",
  "6637": "FAUVILLERS",
  "6640": "VAUX-SUR-SÛRE",
  "6642": "VAUX-SUR-SÛRE",
  "6660": "HOUFFALIZE",
  "6661": "HOUFFALIZE",
  "6662": "HOUFFALIZE",
  "6663": "HOUFFALIZE",
  "6666": "HOUFFALIZE",
  "6670": "GOUVY",
  "6671": "GOUVY",
  "6672": "GOUVY",
  "6673": "GOUVY",
  "6674": "GOUVY",
  "6680": "SAINTE-ODE",
  "6681": "SAINTE-ODE",
  "6686": "BERTOGNE",
  "6687": "BERTOGNE",
  "6688": "BERTOGNE",
  "6690": "VIELSALM",
  "6692": "VIELSALM",
  "6698": "VIELSALM",
  "6700": "ARLON",
  "6704": "ARLON",
  "6706": "ARLON",
  "6717": "ATTERT",
  "6720": "HABAY",
  "6721": "HABAY",
  "6723": "HABAY",
  "6724": "HABAY",
  "6730": "TINTIGNY",
  "6740": "ETALLE",
  "6741": "ETALLE",
  "6742": "ETALLE",
  "6743": "ETALLE",
  "6747": "SAINT-LÉGER",
  "6750": "MUSSON",
  "6760": "VIRTON",
  "6761": "VIRTON",
  "6762": "VIRTON",
  "6767": "ROUVROY",
  "6769": "MEIX-DEVANT-VIRTON",
  "6780": "MESSANCY",
  "6781": "MESSANCY",
  "6782": "MESSANCY",
  "6790": "AUBANGE",
  "6791": "AUBANGE",
  "6792": "AUBANGE",
  "6800": "LIBRAMONT-CHEVIGNY",
  "6810": "CHINY",
  "6811": "CHINY",
  "6812": "CHINY",
  "6813": "CHINY",
  "6820": "FLORENVILLE",
  "6821": "FLORENVILLE",
  "6823": "FLORENVILLE",
  "6824": "FLORENVILLE",
  "6830": "BOUILLON",
  "6831": "BOUILLON",
  "6832": "BOUILLON",
  "6833": "BOUILLON",
  "6834": "BOUILLON",
  "6836": "BOUILLON",
  "6838": "BOUILLON",
  "6840": "NEUFCHÂTEAU",
  "6850": "PALISEUL",
  "6851": "PALISEUL",
  "6852": "PALISEUL",
  "6853": "PALISEUL",
  "6856": "PALISEUL",
  "6860": "LÉGLISE",
  "6870": "SAINT-HUBERT",
  "6880": "BERTRIX",
  "6887": "HERBEUMONT",
  "6890": "LIBIN",
  "6900": "MARCHE-EN-FAMENNE",
  "6920": "WELLIN",
  "6921": "WELLIN",
  "6922": "WELLIN",
  "6924": "WELLIN",
  "6927": "TELLIN",
  "6929": "DAVERDISSE",
  "6940": "DURBUY",
  "6941": "DURBUY",
  "6950": "NASSOGNE",
  "6951": "NASSOGNE",
  "6952": "NASSOGNE",
  "6953": "NASSOGNE",
  "6960": "MANHAY",
  "6970": "TENNEVILLE",
  "6971": "TENNEVILLE",
  "6972": "TENNEVILLE",
  "6980": "LA ROCHE-EN-ARDENNE",
  "6982": "LA ROCHE-EN-ARDENNE",
  "6983": "LA ROCHE-EN-ARDENNE",
  "6984": "LA ROCHE-EN-ARDENNE",
  "6986": "LA ROCHE-EN-ARDENNE",
  "6987": "RENDEUX",
  "6990": "HOTTON",
  "6997": "EREZÉE",
  "7000": "MONS",
  "7010": "SHAPE",
  "7011": "MONS",
  "7012": "MONS",
  "7020": "MONS",
  "7021": "MONS",
  "7022": "MONS",
  "7024": "MONS",
  "7030": "MONS",
  "7031": "MONS",
  "7032": "MONS",
  "7033": "MONS",
  "7034": "MONS",
  "7040": "QUÉVY",
  "7041": "QUÉVY",
  "7050": "JURBISE",
  "7060": "SOIGNIES",
  "7061": "SOIGNIES",
  "7062": "SOIGNIES",
  "7063": "SOIGNIES",
  "7070": "LE ROEULX",
  "7080": "FRAMERIES",
  "7090": "BRAINE-LE-COMTE",
  "7100": "LA LOUVIÈRE",
  "7110": "LA LOUVIÈRE",
  "7120": "ESTINNES",
  "7130": "BINCHE",
  "7131": "BINCHE",
  "7133": "BINCHE",
  "7134": "BINCHE",
  "7140": "MORLANWELZ",
  "7141": "MORLANWELZ",
  "7160": "CHAPELLE-LEZ-HERLAIMONT",
  "7170": "MANAGE",
  "7180": "SENEFFE",
  "7181": "SENEFFE",
  "7190": "ECAUSSINNES",
  "7191": "ECAUSSINNES",
  "7300": "BOUSSU",
  "7301": "BOUSSU",
  "7320": "BERNISSART",
  "7321": "BERNISSART",
  "7322": "BERNISSART",
  "7330": "SAINT-GHISLAIN",
  "7331": "SAINT-GHISLAIN",
  "7332": "SAINT-GHISLAIN",
  "7333": "SAINT-GHISLAIN",
  "7334": "SAINT-GHISLAIN",
  "7340": "COLFONTAINE",
  "7350": "HENSIES",
  "7370": "DOUR",
  "7380": "QUIÉVRAIN",
  "7382": "QUIÉVRAIN",
  "7387": "HONNELLES",
  "7390": "QUAREGNON",
  "7500": "TOURNAI",
  "7501": "TOURNAI",
  "7502": "TOURNAI",
  "7503": "TOURNAI",
  "7504": "TOURNAI",
  "7506": "TOURNAI",
  "7520": "TOURNAI",
  "7521": "TOURNAI",
  "7522": "TOURNAI",
  "7530": "TOURNAI",
  "7531": "TOURNAI",
  "7532": "TOURNAI",
  "7533": "TOURNAI",
  "7534": "TOURNAI",
  "7536": "TOURNAI",
  "7538": "TOURNAI",
  "7540": "TOURNAI",
  "7542": "TOURNAI",
  "7543": "TOURNAI",
  "7548": "TOURNAI",
  "7600": "PÉRUWELZ",
  "7601": "PÉRUWELZ",
  "7602": "PÉRUWELZ",
  "7603": "PÉRUWELZ",
  "7604": "PÉRUWELZ",
  "7608": "PÉRUWELZ",
  "7610": "RUMES",
  "7611": "RUMES",
  "7618": "RUMES",
  "7620": "BRUNEHAUT",
  "7621": "BRUNEHAUT",
  "7622": "BRUNEHAUT",
  "7623": "BRUNEHAUT",
  "7624": "BRUNEHAUT",
  "7640": "ANTOING",
  "7641": "ANTOING",
  "7642": "ANTOING",
  "7643": "ANTOING",
  "7700": "MOESKROEN",
  "7711": "MOESKROEN",
  "7712": "MOESKROEN",
  "7730": "ESTAIMPUIS",
  "7740": "PECQ",
  "7742": "PECQ",
  "7743": "PECQ",
  "7750": "MONT-DE-L'ENCLUS",
  "7760": "CELLES",
  "7780": "KOMEN-WAASTEN",
  "7781": "KOMEN-WAASTEN",
  "7782": "KOMEN-WAASTEN",
  "7783": "KOMEN-WAASTEN",
  "7784": "KOMEN-WAASTEN",
  "7800": "ATH",
  "7801": "ATH",
  "7802": "ATH",
  "7803": "ATH",
  "7804": "ATH",
  "7810": "ATH",
  "7811": "ATH",
  "7812": "ATH",
  "7822": "ATH",
  "7823": "ATH",
  "7830": "SILLY",
  "7850": "EDINGEN",
  "7860": "LESSINES",
  "7861": "LESSINES",
  "7862": "LESSINES",
  "7863": "LESSINES",
  "7864": "LESSINES",
  "7866": "LESSINES",
  "7870": "LENS",
  "7880": "VLOESBERG",
  "7890": "ELLEZELLES",
  "7900": "LEUZE-EN-HAINAUT",
  "7901": "LEUZE-EN-HAINAUT",
  "7903": "LEUZE-EN-HAINAUT",
  "7904": "LEUZE-EN-HAINAUT",
  "7906": "LEUZE-EN-HAINAUT",
  "7910": "FRASNES-LEZ-ANVAING",
  "7911": "FRASNES-LEZ-ANVAING",
  "7912": "FRASNES-LEZ-ANVAING",
  "7940": "BRUGELETTE",
  "7941": "BRUGELETTE",
  "7942": "BRUGELETTE",
  "7943": "BRUGELETTE",
  "7950": "CHIÈVRES",
  "7951": "CHIÈVRES",
  "7970": "BELOEIL",
  "7971": "BELOEIL",
  "7972": "BELOEIL",
  "7973": "BELOEIL",
  "8000": "BRUGGE",
  "8020": "OOSTKAMP",
  "8200": "BRUGGE",
  "8210": "ZEDELGEM",
  "8211": "ZEDELGEM",
  "8300": "KNOKKE-HEIST",
  "8301": "KNOKKE-HEIST",
  "8310": "BRUGGE",
  "8340": "DAMME",
  "8370": "BLANKENBERGE",
  "8377": "ZUIENKERKE",
  "8380": "BRUGGE",
  "8400": "OOSTENDE",
  "8420": "DE HAAN",
  "8421": "DE HAAN",
  "8430": "MIDDELKERKE",
  "8431": "MIDDELKERKE",
  "8432": "MIDDELKERKE",
  "8433": "MIDDELKERKE",
  "8434": "MIDDELKERKE",
  "8450": "BREDENE",
  "8460": "OUDENBURG",
  "8470": "GISTEL",
  "8480": "ICHTEGEM",
  "8490": "JABBEKE",
  "8500": "KORTRIJK",
  "8501": "KORTRIJK",
  "8510": "KORTRIJK",
  "8511": "KORTRIJK",
  "8520": "KUURNE",
  "8530": "HARELBEKE",
  "8531": "HARELBEKE",
  "8540": "DEERLIJK",
  "8550": "ZWEVEGEM",
  "8551": "ZWEVEGEM",
  "8552": "ZWEVEGEM",
  "8553": "ZWEVEGEM",
  "8554": "ZWEVEGEM",
  "8560": "WEVELGEM",
  "8570": "ANZEGEM",
  "8572": "ANZEGEM",
  "8573": "ANZEGEM",
  "8580": "AVELGEM",
  "8581": "AVELGEM",
  "8582": "AVELGEM",
  "8583": "AVELGEM",
  "8587": "SPIERE-HELKIJN",
  "8600": "DIKSMUIDE",
  "8610": "KORTEMARK",
  "8620": "NIEUWPOORT",
  "8630": "VEURNE",
  "8640": "VLETEREN",
  "8647": "LO-RENINGE",
  "8650": "HOUTHULST",
  "8660": "DE PANNE",
  "8670": "KOKSIJDE",
  "8680": "KOEKELARE",
  "8690": "ALVERINGEM",
  "8691": "ALVERINGEM",
  "8700": "TIELT",
  "8710": "WIELSBEKE",
  "8720": "DENTERGEM",
  "8730": "BEERNEM",
  "8740": "PITTEM",
  "8750": "WINGENE",
  "8755": "RUISELEDE",
  "8760": "MEULEBEKE",
  "8770": "INGELMUNSTER",
  "8780": "OOSTROZEBEKE",
  "8790": "WAREGEM",
  "8791": "WAREGEM",
  "8792": "WAREGEM",
  "8793": "WAREGEM",
  "8800": "ROESELARE",
  "8810": "LICHTERVELDE",
  "8820": "TORHOUT",
  "8830": "HOOGLEDE",
  "8840": "STADEN",
  "8850": "ARDOOIE",
  "8851": "ARDOOIE",
  "8860": "LENDELEDE",
  "8870": "IZEGEM",
  "8880": "LEDEGEM",
  "8890": "MOORSLEDE",
  "8900": "IEPER",
  "8902": "IEPER",
  "8904": "IEPER",
  "8906": "IEPER",
  "8908": "IEPER",
  "8920": "LANGEMARK-POELKAPELLE",
  "8930": "MENEN",
  "8940": "WERVIK",
  "8950": "HEUVELLAND",
  "8951": "HEUVELLAND",
  "8952": "HEUVELLAND",
  "8953": "HEUVELLAND",
  "8954": "HEUVELLAND",
  "8956": "HEUVELLAND",
  "8957": "MESEN",
  "8958": "HEUVELLAND",
  "8970": "POPERINGE",
  "8972": "POPERINGE",
  "8978": "POPERINGE",
  "8980": "ZONNEBEKE",
  "9000": "GENT",
  "9030": "GENT",
  "9031": "GENT",
  "9032": "GENT",
  "9040": "GENT",
  "9041": "GENT",
  "9042": "GENT",
  "9050": "GENT",
  "9051": "GENT",
  "9052": "GENT",
  "9060": "ZELZATE",
  "9070": "DESTELBERGEN",
  "9075": "CSM Gent X",
  "9080": "LOCHRISTI",
  "9090": "MELLE",
  "9099": "Gent X",
  "9100": "SINT-NIKLAAS",
  "9111": "SINT-NIKLAAS",
  "9112": "SINT-NIKLAAS",
  "9120": "BEVEREN-WAAS",
  "9130": "BEVEREN-WAAS",
  "9140": "TEMSE",
  "9150": "KRUIBEKE",
  "9160": "LOKEREN",
  "9170": "SINT-GILLIS-WAAS",
  "9180": "MOERBEKE-WAAS",
  "9185": "WACHTEBEKE",
  "9190": "STEKENE",
  "9200": "DENDERMONDE",
  "9220": "HAMME",
  "9230": "WETTEREN",
  "9240": "ZELE",
  "9250": "WAASMUNSTER",
  "9255": "BUGGENHOUT",
  "9260": "WICHELEN",
  "9270": "LAARNE",
  "9280": "LEBBEKE",
  "9290": "BERLARE",
  "9300": "AALST",
  "9308": "AALST",
  "9310": "AALST",
  "9320": "AALST",
  "9340": "LEDE",
  "9400": "NINOVE",
  "9401": "NINOVE",
  "9402": "NINOVE",
  "9403": "NINOVE",
  "9404": "NINOVE",
  "9406": "NINOVE",
  "9420": "ERPE-MERE",
  "9450": "HAALTERT",
  "9451": "HAALTERT",
  "9470": "DENDERLEEUW",
  "9472": "DENDERLEEUW",
  "9473": "DENDERLEEUW",
  "9500": "GERAARDSBERGEN",
  "9506": "GERAARDSBERGEN",
  "9520": "SINT-LIEVENS-HOUTEM",
  "9521": "SINT-LIEVENS-HOUTEM",
  "9550": "HERZELE",
  "9551": "HERZELE",
  "9552": "HERZELE",
  "9570": "LIERDE",
  "9571": "LIERDE",
  "9572": "LIERDE",
  "9600": "RONSE",
  "9620": "ZOTTEGEM",
  "9630": "ZWALM",
  "9636": "ZWALM",
  "9660": "BRAKEL",
  "9661": "BRAKEL",
  "9667": "HOREBEKE",
  "9680": "MAARKEDAL",
  "9681": "MAARKEDAL",
  "9688": "MAARKEDAL",
  "9690": "KLUISBERGEN",
  "9700": "OUDENAARDE",
  "9750": "KRUISEM",
  "9770": "KRUISEM",
  "9771": "KRUISEM",
  "9772": "KRUISEM",
  "9790": "WORTEGEM-PETEGEM",
  "9800": "DEINZE",
  "9810": "NAZARETH",
  "9820": "MERELBEKE",
  "9830": "SINT-MARTENS-LATEM",
  "9831": "SINT-MARTENS-LATEM",
  "9840": "DE PINTE",
  "9850": "DEINZE",
  "9860": "OOSTERZELE",
  "9870": "ZULTE",
  "9880": "AALTER",
  "9881": "AALTER",
  "9890": "GAVERE",
  "9900": "EEKLO",
  "9910": "AALTER",
  "9920": "LIEVEGEM",
  "9921": "LIEVEGEM",
  "9930": "LIEVEGEM",
  "9931": "LIEVEGEM",
  "9932": "LIEVEGEM",
  "9940": "EVERGEM",
  "9950": "LIEVEGEM",
  "9960": "ASSENEDE",
  "9961": "ASSENEDE",
  "9968": "ASSENEDE",
  "9970": "KAPRIJKE",
  "9971": "KAPRIJKE",
  "9980": "SINT-LAUREINS",
  "9981": "SINT-LAUREINS",
  "9982": "SINT-LAUREINS",
  "9988": "SINT-LAUREINS",
  "9990": "MALDEGEM",
  "9991": "MALDEGEM",
  "9992": "MALDEGEM"
}