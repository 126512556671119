import React from 'react'
import MonthPicker from '../../component/monthPicker'
import moment from 'moment'
import {Button} from '@material-ui/core'
import {connect} from 'react-redux'
import {doDownloadDailyReservationsAction, doDownloadReservationsAction, doDownloadCustomersAction, doDownloadInvoicesAction} from '../../action/download'

function selectState(state) {
  return {

  }
}

class Downloads extends React.Component {

  constructor(props) {
    super(props)
    const currentMonth = moment().format("YYYYMM")

    this.state = {reservationsMonthValue: currentMonth,dailyReservationsMonthValue: currentMonth, invoicesMonthValue:currentMonth}
  }

  downloadReservations = () => {
    this.props.dispatch(doDownloadReservationsAction(this.state.reservationsMonthValue))
  }

  downloadDailyReservations = () => {
    this.props.dispatch(doDownloadDailyReservationsAction(this.state.dailyReservationsMonthValue))
  }

  downloadInvoices = () => {
    this.props.dispatch(doDownloadInvoicesAction(this.state.invoicesMonthValue))
  }

  downloadCustomers = () => {
      this.props.dispatch(doDownloadCustomersAction())
    }

  render() {

    return <div className="downloads">
      <div className="row">
        <div className="col-xs-12">
          <div className="download-combo">
            <Button variant="contained"  style={{width:'300px'}} onClick={this.downloadReservations}>Download Reservations</Button>
            <span style={{width:'1rem'}}/>
            <MonthPicker value={this.state.reservationsMonthValue} updateValue={(val) => {
              this.setState({reservationsMonthValue: val})
            }}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className="download-combo">
            <Button variant="contained" style={{width:'300px'}} onClick={this.downloadDailyReservations}>Download Daily Reservations</Button>
            <span style={{width:'1rem'}}/>
            <MonthPicker value={this.state.dailyReservationsMonthValue} updateValue={(val) => {
              this.setState({dailyReservationsMonthValue: val})
            }}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className="download-combo">
            <Button variant="contained" style={{width:'300px'}} onClick={this.downloadInvoices}>Download Invoices</Button>
            <span style={{width:'1rem'}}/>
            <MonthPicker value={this.state.invoicesMonthValue} updateValue={(val) => {
              this.setState({invoicesMonthValue: val})
            }}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className="download-combo">
            <Button variant="contained" style={{width:'300px'}} onClick={this.downloadCustomers}>Download Customers</Button>
            <span style={{width:'1rem'}}/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default connect(selectState)(Downloads)