import React from 'react'
import PropTypes from 'prop-types'
import {Checkbox, Button, FormControlLabel, DialogTitle, DialogContent, Select, DialogActions, Dialog} from "@material-ui/core"

export default class ModifyCuisinesDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {selectedCuisineIds: props.selectedCuisines ? props.selectedCuisines.map(cuisine => cuisine.id).toJSON() : []}
  }

  update = () => {
    const cuisines = this.props.cuisines.filter((cuisine) => {
      return this.state.selectedCuisineIds.includes(cuisine.id)
    })
    this.props.update(cuisines)
  }

  validateInput = () => {
    return this.state.selectedCuisineIds.length !== 0
  }

  isCuisineSelected = (id) => {
    return this.state.selectedCuisineIds.includes(id)
  }

  isCuisineDisabled = (id) => {
    return this.state.selectedCuisineIds.length >= 2 && !this.isCuisineSelected(id)
  }

  selectCuisine = (id) => {
    const ids = this.state.selectedCuisineIds
    ids.push(id)
    this.setState({selectedCuisineIds: ids})
  }

  deselectCuisine = (id) => {
    this.setState({selectedCuisineIds: this.state.selectedCuisineIds.filter(cuisineId => cuisineId !== id)})
  }

  render() {

    const actions = [
      <Button
        key="cancel"
        color="primary"
        onClick={this.props.cancel}
      >Cancel</Button>,
      <Button variant="contained"
              key="add"
              color="secondary"
              onClick={this.update}
              disabled={!this.validateInput()}
      >Done</Button>
    ]

    const cuisineCheckboxes = this.props.cuisines.map((cuisine) => {
      return <div className="col-xs-4" key={cuisine.id}><FormControlLabel
        key={cuisine.id}
        control={<Checkbox checked={this.isCuisineSelected(cuisine.id)} key={cuisine.id}
                           disabled={this.isCuisineDisabled(cuisine.id)}
                           onChange={(ev, checked) => {
                             if (checked) {
                               this.selectCuisine(cuisine.id)
                             } else {
                               this.deselectCuisine(cuisine.id)
                             }
                           }
                           }/>} label={cuisine.name}/></div>

    })

    return <Dialog
      open={this.props.show}
      onClose={this.props.cancel}
      fullWidth={true}
    >
      <DialogTitle>Cuisines</DialogTitle>
      <DialogContent>
        <div className="modify-cuisines-checkboxes">
          <div className="row">
            {cuisineCheckboxes}
          </div>
        </div>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  }
}

ModifyCuisinesDialog.propTypes = {
  show: PropTypes.bool,
  cancel: PropTypes.func,
  selectedCuisines: PropTypes.object,
  cuisines: PropTypes.object,
  update: PropTypes.func
}