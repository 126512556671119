import React from 'react'
import {connect} from 'react-redux'
import {loadCredentials} from './'
import {doLoginCurrentUserAction} from '../action/login'

export function requireAuthentication(Component) {

  function selectState(state) {
    return {
      user: state.userInfo
    }
  }

  class AuthenticatedComponent extends React.Component {

    componentWillMount() {
      this.checkAuth(this.props)
    }

    componentWillReceiveProps(newProps) {
      this.checkAuth(newProps)
    }

    checkAuth = (props) => {
      if (!props.user.loggedIn && !props.user.loggingIn) {
        const redirectAfterLogin = props.history.location.pathname
        console.log(redirectAfterLogin)
        const currentUser = loadCredentials()

        if(currentUser) {
          props.dispatch(doLoginCurrentUserAction(redirectAfterLogin || '/restaurant',props.dispatch,history))
        } else {
          props.history.push(`login?next=${redirectAfterLogin}`)
        }
      }
    }

    render() {
      if(this.props.user.loggedIn) {
        return <Component {...this.props}/>
      }

      return <div></div>
    }

  }

  return connect(selectState)(AuthenticatedComponent)
}

