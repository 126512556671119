import {getCuisines, getPlans, getRestaurants} from "../controller/restaurant"
import {actionMetaData, actionResult, actionType, createPromiseAction} from "./index"
import {getAllProspectsAction} from "./prospect"
import {Data} from "../model/data"
import {getAllRestaurantsAction} from "./restaurant"

console.log('HOI!!!!')

const GET_CUISINES = 'GET_CUISINES'
const GET_PLANS = 'GET_PLANS'

let loadingData = false

export function getAllDataAction() {

  console.log('loading data',loadingData)
  if(loadingData) {
    return ()=>{}
  }

  console.log('set to true')
  loadingData = true

  return (dispatch) => {
    dispatch(getAllCuisinesAction()).then(() => {
      return dispatch(getAllPlansAction())
    }).then(() => {
      return dispatch(getAllRestaurantsAction())
    }).then(() => {
      return dispatch(getAllProspectsAction())
    }).then(()=>{
      console.log('set to false')
      loadingData = false
    })
  }
}

function getAllCuisinesAction() {
  const promise = getCuisines()
  return createPromiseAction(GET_CUISINES, promise, {})
}

function getAllPlansAction() {
  const promise = getPlans()
  return createPromiseAction(GET_PLANS, promise, {})
}



export function reduceData(state = null, action = null) {
  if (!state) return new Data()
  let nextState = state

  const result = actionResult(action)

  switch (actionType(action)) {
    case `${GET_CUISINES}_SUCCESS`:
      nextState = nextState.merge({cuisines: result})
      break
    case `${GET_PLANS}_SUCCESS`:
      nextState = nextState.merge({plans: result})
      break
  }

  return nextState
}