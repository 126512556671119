import axios from 'axios'
import {BaseUrl} from '../config'
import EventEmitter from 'events'
import {buildSecurityHeader, getCurrentUserToken, updateCurrentUserToken, deleteCredentials} from '../auth'

export const networkEventEmitter = new EventEmitter()
export const ajaxStartEvent = 'ajax-start'
export const ajaxStopEvent = 'ajax-stop'
export const successEvent = 'ajax-success'
export const failureEvent = 'ajax-failure'

let loadCount = 0

axios.interceptors.request.use(function (config) {
  startLoad()
  const token = getCurrentUserToken()

  if (config.headers === undefined)
    config.headers = {}

  if (token) {
    config.headers.authorization = buildSecurityHeader()
  }

  return config
})

axios.interceptors.response.use(function (response) {

  stopLoad()
  return response
}, function (error) {

  console.log('error', error)

  stopLoad()

  if (error.status === 403) {

    console.log('403 detected, redirecting to login')
    if (window.location.pathname.indexOf('login') === -1) {
      deleteCredentials()
      window.location = '/login'
    }
  }

  return Promise.reject(error);
});

function doRequest(options) {
  return axios.request(options)
}

export function ajaxInProgress() {
  return loadCount > 0
}

function startLoad() {
  ++loadCount
  if (loadCount === 1) {
    networkEventEmitter.emit(ajaxStartEvent)
  }
}

function stopLoad() {
  --loadCount
  if (loadCount === 0) {
    networkEventEmitter.emit(ajaxStopEvent)
  }
}

export function getData(path, headers) {

  headers = headers || {}

  return axios.get(BaseUrl + path, {headers}).then((response) => {

    if (isResponseOk(response)) {
      return Promise.resolve(resultObject(response))
    } else {
      return Promise.reject(response)
    }
  })
}

export function getBinaryData(path, headers) {

  headers = headers || {}

  return axios.get(BaseUrl + path, {headers,responseType:"blob"}).then((response) => {

    if (isResponseOk(response)) {
      return Promise.resolve(resultObject(response))
    } else {
      return Promise.reject(response)
    }
  })
}
export function deleteData(path) {
  return axios.delete(BaseUrl + path).then((response) => {

    if (isResponseOk(response)) {
      return Promise.resolve(resultObject(response))
    } else {
      return Promise.reject(response)
    }
  })
}

export function putData(path, obj, isSave = true) {
  return axios.put(BaseUrl + path, obj).then(function (response) {

    if (isResponseOk(response)) {
      if (isSave)
        networkEventEmitter.emit(successEvent)

      return Promise.resolve(resultObject(response))
    } else {
      return Promise.reject(response)
    }
  }).catch(function (error) {

    if (isSave) {
      networkEventEmitter.emit(failureEvent, error.response)
    }

    return Promise.reject(error)
  })
}

export function postData(path, obj, isSave = true) {

  return axios.post(BaseUrl + path, obj).then(function (response) {

    if (isResponseOk(response)) {
      if (isSave)
        networkEventEmitter.emit(successEvent)

      return Promise.resolve(resultObject(response))
    } else {
      return Promise.reject(response)
    }
  }).catch(function (error) {

    console.log('error response', error.response)

    if (isSave) {
      networkEventEmitter.emit(failureEvent)
    }

    return Promise.reject(error)
  })
}

function isResponseOk(response) {
  return response.status >= 200 && response.status < 299
}

function resultObject(response) {
  return response.data
}

function responseError(response) {
  if (response.status >= 500) {
    return 'Server error'
  }

  if (response.status === 0) {
    return 'Server error'
  }

  return response.statusText
}