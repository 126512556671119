import React from 'react'
import PropTypes from 'prop-types'
import {Dialog, Button, DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import moment from 'moment'
import {cancelReservationAction} from '../../../action/restaurant'

export default class Reservation extends React.Component {

  constructor(props) {
    super(props)

    this.state = {showCancelReservationDialog: false}
  }

  showCancelReservationDialog = () => {
    this.setState({showCancelReservationDialog: true})
  }

  cancelDeleteReservation = () => {
    this.setState({showCancelReservationDialog: false})
  }

  deleteReservation = () => {
    this.setState({showCancelReservationDialog: false})
    this.props.dispatch(cancelReservationAction(this.props.reservation))
  }

  render() {
    const deleteReservationActions = [
      <Button
        key={1}
        color="primary"
        onClick={this.cancelDeleteReservation}
      >No</Button>,
      <Button
        variant="contained"
        key={2}
        color="secondary"
        onClick={this.deleteReservation}
      >Cancel Reservation</Button>
    ]

    const reservation = this.props.reservation
    const time = moment().hour(reservation.hour).minute(reservation.minute)

    let reservationType = "Lunch"
    let priceOrDiscount = null

    if (reservation.lunchPrice === null) {
      reservationType = "Dinner"
      priceOrDiscount = reservation.percentage + '%'
    } else {
      priceOrDiscount = reservation.lunchPrice + '€'
    }

    let deleteButton = <span/>
    let className = "reservation"

    if (time.isAfter()) {
      deleteButton =
        <Button variant="contained" color="secondary" onClick={this.showCancelReservationDialog}>Cancel</Button>
    } else {
      className += " past"
    }

    return <div className={className}>
      <div className="time">
        {time.format("HH:mm")}
      </div>
      <div className="type">
        <span>{reservationType}</span>
      </div>
      <div className="price-or-discount">
        {priceOrDiscount}
      </div>
      <div className="name">
        {reservation.firstName} {reservation.lastName}
      </div>
      <div className="email">
        {reservation.email}
      </div>
      <div className="phone">
        {reservation.phone}
      </div>
      <div className="places">
        {reservation.places}
      </div>
      <div className="delete-today">
        {deleteButton}
      </div>


      <Dialog
        modal={false}
        open={this.state.showCancelReservationDialog}
        onClose={this.cancelDeleteReservation}
      >
        <DialogTitle>
          Cancel Reservation
        </DialogTitle>
        <DialogContent>
          Do you want to cancel this reservation?
        </DialogContent>
        <DialogActions>{deleteReservationActions}</DialogActions>
      </Dialog>
    </div>
  }
}

Reservation.propTypes = {
  reservation: PropTypes.object
}