import Immutable from 'immutable'
import {Cuisine} from "./restaurant"

export const newProspectId = 'new'

export const ProspectsInfo = Immutable.Record({
  prospects: Immutable.List(),    // [{zipcode,items:[prospect]},...]
  stripeCustomers:Immutable.List(),
  selectedProspect: null,
  conversionErrorMessage:null,
  groupByZipcode:false,
  filter:"",
  noPotentialFilter:false,
  contactedByPhoneFilter:false
})

export const Prospect = Immutable.Record({
  id: newProspectId,
  tablebookerId:null,
  name: '',
  company: '',
  street: '',
  city: '',
  country: '',
  zipcode: '',
  phone: '',
  email: '',
  emailNotif: '',
  website: '',
  lat: 0,
  lon: 0,
  vat: '',
  comments: '',
  contactedByPhone:false,
  noPotential:false,
  cuisines: Immutable.List()
})

export const StripeCustomer = Immutable.Record({
  id:null,
  email:null,
  name:null,
  hasPaymentMethod:false,
  description:null,
  productName:null
})


Prospect.fromJson = (json) => {
  let prospect = new Prospect(json)
  let cuisines = Immutable.List(json.cuisines.map(cuisine => new Cuisine(cuisine)))

  return prospect.set('cuisines',cuisines)
}
