import React from 'react'
import PropTypes from 'prop-types'
import {Dialog, Button, Fab, TextField, DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import ContentAdd from '@material-ui/icons/Add'
import {setDayDealAvailablePlacesAction, deleteDayDealAction} from '../../../action/restaurant'

export default class TodayDeal extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      showAddPlacesDialog: false,
      placesToAdd: "1",
      hasValidPlacesToAdd: true,
      showDeleteDayDealDialog: false
    }
  }

  showAddPlacesDialog = () => {
    this.setState({showAddPlacesDialog: true})
  }

  cancelAddPlaces = () => {
    this.setState({showAddPlacesDialog: false})
  }

  addPlaces = () => {
    this.setState({showAddPlacesDialog: false})
    this.props.dispatch(setDayDealAvailablePlacesAction(this.props.deal, parseInt(this.state.placesToAdd) + this.props.deal.availablePlaces))
  }

  changePlacesToAdd = (event, value) => {
    const deal = this.props.deal
    const maxPlacesToAdd = 8 - deal.availablePlaces

    this.setState({placesToAdd: value}, () => {
      if (value < 1 || value > maxPlacesToAdd) {
        this.invalidPlacesToAdd()
      } else {
        this.validPlacesToAdd()
      }
    })
  }

  invalidPlacesToAdd = () => {
    this.setState({hasValidPlacesToAdd: false})
  }

  validPlacesToAdd = () => {
    this.setState({hasValidPlacesToAdd: true})
  }

  showDeleteDayDealDialog = () => {
    this.setState({showDeleteDayDealDialog: true})
  }

  cancelDeleteDayDeal = () => {
    this.setState({showDeleteDayDealDialog: false})
  }

  deleteDayDeal = () => {
    this.setState({showDeleteDayDealDialog: false})
    this.props.dispatch(deleteDayDealAction(this.props.restaurant, this.props.deal))
  }

  render() {
    const addPlacesActions = [
      <Button
        key={1}
        color="primary"
        onClick={this.cancelAddPlaces}
      >Cancel</Button>,
      <Button variant="contained"
              color="primary"
              key={2}
              onClick={this.addPlaces}
              disabled={!this.state.hasValidPlacesToAdd}
      >Add Places</Button>
    ]

    const deleteDayDealActions = [
      <Button
        key={1}
        color="primary"
        onClick={this.cancelDeleteDayDeal}
      >Cancel</Button>,
      <Button variant="contained"
              key={2}
              color="secondary"
              onClick={this.deleteDayDeal}
      >Delete</Button>
    ]

    const deal = this.props.deal
    const maxPlacesToAdd = 8 - deal.availablePlaces
    const dialogTitle = `Add Maximum ${maxPlacesToAdd} Places`

    let dealType = ""
    const priceOrDiscount = deal.percentage + '%'

    if (deal.lunch) {
      if(deal.dinner) {
        dealType += "Lunch - "
      } else {
        dealType += "Lunch"
      }
    }

    if(deal.dinner) {
      dealType += "Dinner"
    }

    let addButton = <span/>

    if (deal.availablePlaces < 8) {
      addButton =
        <Fab mini={true} onClick={this.showAddPlacesDialog}><ContentAdd/></Fab>
    }

    return <div className="today-deal">
      <div className="type">
        <span>{dealType}</span>
      </div>
      <div className="price-or-discount">
        {priceOrDiscount}
      </div>
      <div className="places">
        {deal.places}
      </div>
      <div className="available-places">
        {deal.availablePlaces}
      </div>

      <div className="delete-today">
        <Button variant="contained" color="secondary" onClick={this.showDeleteDayDealDialog}>Delete</Button>
      </div>

      <Dialog
        modal={false}
        open={this.state.showAddPlacesDialog}
        onClose={this.cancelAddPlaces}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <TextField
            id="num"
            type="number"
            value={this.state.placesToAdd}
            required
            onChange={this.changePlacesToAdd}
          />
        </DialogContent>
        <DialogActions>{addPlacesActions}</DialogActions>
      </Dialog>
      <Dialog
        modal={false}
        open={this.state.showDeleteDayDealDialog}
        onClose={this.cancelDeleteDayDeal}
      >
        <DialogTitle>Delete Day Deal</DialogTitle>
        <DialogContent>Do you want to delete this day deal?</DialogContent>
        <DialogActions>{deleteDayDealActions}</DialogActions>
      </Dialog>
    </div>
  }
}

TodayDeal.propTypes = {
  restaurant: PropTypes.object,
  deal: PropTypes.object
}
