import React from 'react'
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core"
import PropTypes from 'prop-types'

export default class ProspectFilter extends React.Component {

  render() {
    console.log(this.props)

    return <div className="prospect-filter">
      <FormControlLabel
        control={<Checkbox checked={this.props.contactedByPhoneFilter}
                           onChange={(ev, checked) => {
                             this.props.updateContactedByPhone(checked)
                           }
                           }/>} label="Contacted by Phone"/>
      <FormControlLabel
        control={<Checkbox checked={this.props.noPotentialFilter}
                           onChange={(ev, checked) => {
                             this.props.updateNoPotential(checked)
                           }
                           }/>} label="No Potential"/>
    </div>
  }
}

ProspectFilter.propTypes = {
  contactedByPhoneFilter:PropTypes.bool,
  noPotentialFilter:PropTypes.bool,
  updateContactedByPhone:PropTypes.func,
  updateNoPotential:PropTypes.func
}
