import React from 'react'
import PropTypes from 'prop-types'
import RestaurantListItem from './restaurantListItem'
import {FixedSizeList as List} from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import {groupForZipcode} from "../../../controller/restaurant"
import memoize from 'memoize-one'
import zipcodes from '../../../model/zipcodes'

export default class GroupedRestaurantList extends React.Component {

  constructor(props) {
    super(props)
    this.listRef = React.createRef();
  }

  restaurantsGroupedByZipcode = memoize((restaurants) => {
    return groupForZipcode(restaurants)
  })

  tableRowCount = memoize((groupedRestaurants) => {
    let total = 0

    groupedRestaurants.forEach((group) => {
      total += group.get('items').size + 1  // +1 for the zip code header
    })

    return total
  })

  entryForFlatIndex = memoize((index, groupedRestaurants) => {
    let counter = index
    let groupIndex = 0
    let resultIndex = -1  // -1 = zip code header, 0..n = restaurant entry
    let currentGroup = groupedRestaurants.get(groupIndex)

    while (counter > 0) {
      ++resultIndex

      if (resultIndex >= currentGroup.get('items').size) {
        resultIndex = -1
        ++groupIndex
        currentGroup = groupedRestaurants.get(groupIndex)
      }

      --counter
    }

    return {currentGroup, resultIndex}
  })

  listIndexForRestaurant = memoize((restaurant,groupedRestaurants) => {
    let groupIndex = 0
    let resultIndex = 0  // -1 = zip code header, 0..n = restaurant entry


    while (true) {
      const currentGroup = groupedRestaurants.get(groupIndex)
      ++resultIndex  // zipcode header
      const index = currentGroup.get('items').indexOf(restaurant)
      if(index !== -1) {
        return resultIndex + index
      }

      resultIndex += currentGroup.get('items').size
      ++groupIndex

      if(groupIndex === groupedRestaurants.size) {
        return -1
      }
    }
  })

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedRestaurant && !this.doneScroll) {
      const groupedRestaurants = this.restaurantsGroupedByZipcode(this.props.restaurants,this.props.filter)
      const indexInScrollableList = this.listIndexForRestaurant(this.props.selectedRestaurant,groupedRestaurants)

      if(indexInScrollableList > -1) {
        setTimeout(()=> {
          this.listRef.current.scrollToItem(indexInScrollableList)

        },1000)
      }
      this.doneScroll = true
    }
  }

  render() {
    if (this.props.restaurants.size === 0)
      return <div></div>

    const groupedRestaurants = this.restaurantsGroupedByZipcode(this.props.restaurants,this.props.filter)

    const row = ({index, style}) => {
      const {currentGroup, resultIndex} = this.entryForFlatIndex(index, groupedRestaurants)

      if (resultIndex >= 0) {
        const restaurant = currentGroup.get('items').get(resultIndex)
        const isSelected = this.props.selectedRestaurant ? (restaurant.id === this.props.selectedRestaurant.id) : false

        return (
          <div style={style}
               key={restaurant.id}>
            <RestaurantListItem isSelected={isSelected}
                                key={restaurant.id}
                                restaurant={restaurant}
                                selectRestaurant={() => {
                                  this.props.selectRestaurant(restaurant)
                                }}/>
          </div>
        )
      }

      return (
        <div style={style} key={currentGroup.get('zipcode')}>
          <div className="zipcode">
            <div className="zip">{currentGroup.get('zipcode')}</div>
            <div className="city"> {zipcodes[currentGroup.get('zipcode')]}</div>
          </div>
        </div>
      )
    }

    return (
      <AutoSizer>
        {({height, width}) => (
          <List
            ref={this.listRef}
            height={height}
            width={width}
            itemCount={this.tableRowCount(groupedRestaurants)}
            itemSize={59}
          >
            {row}
          </List>
        )}
      </AutoSizer>
    )
  }
}

GroupedRestaurantList.propTypes = {
  restaurants: PropTypes.object,
  selectedRestaurant: PropTypes.object,
  selectRestaurant: PropTypes.func
}
