import "core-js/stable"
import "regenerator-runtime/runtime"
import './css/flexboxgrid.css'
import './css/font-awesome.css'
import './css/style.css'
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactDOM from 'react-dom'
import React from 'react'

import {store} from './store'
import {Provider} from 'react-redux'
import {MuiThemeProvider} from '@material-ui/core/styles';
import Application from './view/application'
import {muiTheme} from './theme'
import {Route} from "react-router"
import Login from "./view/login"
import {requireAuthentication} from "./auth/requireAuthentication"
import Dashboard from "./view/dashboard"
import Restaurants from "./view/restaurant/index"
import Prospects from './view/prospect/index'
import Dormants from './view/dormant/index'
import Downloads from "./view/download"
import Links from "./view/links"

/*
  We moved the app body here so it doesn't get rerendered if something in the top bar changes

 */

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme}>
    <CssBaseline/>
    <Provider store={store}>
      <Application>
        <Route component={Login} path="/login"/>
        <Route component={requireAuthentication(Prospects)} path="/prospect/:id?"/>
        <Route component={requireAuthentication(Dashboard)} path="/dashboard"/>
        <Route component={requireAuthentication(Restaurants)} path="/restaurant/:id?"/>
        <Route component={requireAuthentication(Dormants)} path="/dormant"/>
        <Route component={requireAuthentication(Downloads)} path="/download"/>
        <Route component={requireAuthentication(Links)} path="/link"/>
      </Application>
    </Provider>
  </MuiThemeProvider>,
  document.querySelector(".application")
);

