import {getData} from "../network"
import Immutable from "immutable"
import {Plan} from "../model/subscription"

export function getPaymentErrorDescription(error) {

  if (error.status === 402) {
    const code = error.detail
    switch (code) {
      case "balance_insufficient":
        return "Insufficient Balance. Please use another card."
      case "card_declined":
        return "Your card was declined. Please use another card."
      case "email_invalid":
        return "Invalid email address."
      case "expired_card":
        return "Your card has expired. Please use another card."
      case "incorrect_cvc":
        return "The CVC you have entered is incorrect. Please correct it and try again."
      case "incorrect_number":
        return "The number you have entered is incorrect. Please correct it and try again."
      case "invalid_card_type":
        return "The card you use is not supported. Please use another card."
      case "invalid_charge_amount":
        return "Wrong charge amount."
      case "invalid_cvc":
        return "The CVC you have entered is invalid. Please correct it and try again."
      case "invalid_expiry_month":
        return "The expiry month is invalid. Please correct it and try again."
      case "invalid_expiry_year":
        return "The expiry year is invalid. Please correct it and try again."
      case "invalid_number":
        return "The number you have entered is invalid. Please correct it and try again."
      case "processing_error":
        return "There was an error processing your request."

    }
  }

  if (error.status === 400) {
    if (error.errors.user) {
      const userError = error.errors.user.message

      switch (userError) {
        case "tax_id_invalid":
          return "The VAT number is invalid. Please correct it, save it by clicking on 'Save Details' and try again."
        case "email_invalid":
          return "The email address is invalid. Please correct it, save it by clicking on 'Save Details' and try again."
      }
    }
  }

  return "Something went wrong"
}

export async function getSetupIntent() {
  const result = await getData('stripe/setupintent')
console.log(result)
  return result.setupIntentClientSecret
}
