import React from 'react'
import PropTypes from "prop-types"
import {injectStripe} from "react-stripe-elements"
import {Button, Checkbox, FormControlLabel, MenuItem, Select, TextField} from "@material-ui/core"
import {planToMenuItem} from "../../../component/planDropdownItem"
import ModifyPaymentMethodDialog from "../../../component/modifyPaymentMethodDialog"
import {createStripeCustomerAndConvertToRestaurantAction} from "../../../../action/prospect"
import {ElementsConsumer} from '@stripe/react-stripe-js';

const md5 = require("md5")

class CreateNewStripeCustomer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      planId: null,
      isFree: false,
      paymentMethod: null,
      email: props.prospect.email,
      language: "fr",
      password: "",
      showModifyPaymentDialog: false
    }
  }

  changePlan = (planId) => {
    this.setState({planId}, this.validate)
  }

  changeIsFree = (isFree) => {
    this.setState({isFree}, this.validate)
  }

  changePaymentMethod = () => {
    this.setState({
      showModifyPaymentDialog: true
    })
  }

  convert = () => {
    this.props.dispatch(createStripeCustomerAndConvertToRestaurantAction(
      this.props.prospect,
      md5(this.state.password),
      this.state.email,
      this.state.language,
      this.state.planId,
      this.state.isFree,
      this.state.paymentMethod
    ))
  }

  validate = () => {
    const result = (this.state.password && this.state.password.length >= 6) && (this.state.email.length > 3) && this.state.planId && (this.state.isFree || this.state.paymentMethod)

    if (result) {
      this.props.setDialogActions([
        <Button variant="contained" key="convert" color="primary"
                onClick={this.convert}>Convert</Button>
      ])
    } else {
      this.props.setDialogActions([])
    }
  }

  render() {
    const plans = this.props.plans.map(planToMenuItem)

    let paymentMethodButtonText = 'Payment Method'

    if (this.state.token) {
      paymentMethodButtonText = 'New Card Selected'
    }

    if (this.state.source) {
      paymentMethodButtonText = 'New IBAN Selected'
    }

    return (<ElementsConsumer>
      {({stripe, elements}) => (
        <div>
          <div>
            <div className="prospect-subscription">
              <div style={{display: 'flex', flexDirection: "column"}}>
                <TextField
                  style={{marginTop: '1rem'}}
                  type="text"
                  value={this.state.email}
                  label="Email"
                  onChange={(event) => this.setState({email: event.target.value}, this.validate)}
                />
                <TextField
                  style={{marginTop: '1rem'}}
                  type="password"
                  value={this.state.password}
                  label="Password"
                  onChange={(event) => this.setState({password: event.target.value}, this.validate)}
                />
                <Select
                  style={{marginTop: '1rem'}}
                  value={this.state.language} onChange={(event) => {
                  this.setState({language: event.target.value}, this.validate)
                }
                }>
                  <MenuItem value="nl" key="nl">nl</MenuItem>
                  <MenuItem value="fr" key="fr">fr</MenuItem>
                </Select>

                <Select
                  style={{marginTop: '1rem'}}
                  value={this.state.planId || ""} onChange={(event) => {
                  this.changePlan(event.target.value)
                }} displayEmpty>
                  <MenuItem value="" disabled>Plan</MenuItem>
                  {plans}
                </Select>

                <Button variant="outlined" color="secondary" onClick={this.changePaymentMethod}
                        style={{marginTop: '1rem'}}
                        disabled={this.state.isFree}>{paymentMethodButtonText}</Button>
                <FormControlLabel
                  style={{marginTop: '1rem'}}
                  control={<Checkbox checked={this.state.isFree} onChange={(ev, checked) => {
                    this.changeIsFree(checked)
                  }}/>}
                  label="Free Subscription"
                />
              </div>
            </div>

            <ModifyPaymentMethodDialog
              email={this.state.email}
              stripe={stripe}
              elements={elements}
              show={this.state.showModifyPaymentDialog}
              cancel={() => {
                this.setState({showModifyPaymentDialog: false}, this.validate)
              }}
              setPaymentMethod={(paymentMethod) => {
                this.setState({showModifyPaymentDialog: false, paymentMethod}, this.validate)
              }}
            />
          </div>

        </div>)}
    </ElementsConsumer>)
  }
}

CreateNewStripeCustomer.propTypes = {
  plans: PropTypes.object,
  dispatch: PropTypes.func,
  prospect: PropTypes.object,
  setDialogActions: PropTypes.func
}

export default CreateNewStripeCustomer
