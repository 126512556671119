import React from 'react'
import {RestaurantDetailsBase} from "../restaurant/details/restaurantDetails"
import {createProspectAction, updateProspectInfoAction} from "../../action/prospect"
import {newProspectId} from "../../model/prospect"

const validateSaveButton = (props,state,fields) => {
  let saveButtonEnabled = false
  const restaurant = props.restaurant

  for (let field of fields) {
    if (state[field] !== restaurant.get(field))
      saveButtonEnabled = true
  }

  if(state.name.length === 0) {
    saveButtonEnabled = false
  }

  return saveButtonEnabled
}

const saveDetails = (props,state) => {
  const restaurant = Object.assign({}, state)
  restaurant.id = props.restaurant.id

  // make sure the empty string is set to null before saving
  // in order to avoid "duplicate" email addresses which are just ""
  if(!restaurant.email)
    restaurant.email = null

  if(restaurant.id === newProspectId) {
    props.dispatch(createProspectAction(restaurant))
  } else {
    props.dispatch(updateProspectInfoAction(restaurant))
  }
}

export default function(props) {
  return <RestaurantDetailsBase {...props} saveDetails={saveDetails} validateSaveButton={validateSaveButton} />
}
