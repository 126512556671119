import RestaurantPictures from "../restaurant/details/restaurantPictures"
import {updateProspectPicturesAction} from "../../action/prospect"

function dataURItoBase64(dataURI) {
  return dataURI.split(',')[1]
}

export default class ProspectPictures extends RestaurantPictures {

  save() {
    this.props.dispatch(updateProspectPicturesAction(this.props.restaurant, this.state.pictureUrl.startsWith("http") ? null : dataURItoBase64(this.state.pictureUrl), this.state.menuUrl.startsWith("http") ? null : dataURItoBase64(this.state.menuUrl), this.state.menuMimeType))
  }

}