export function createAction(type, args = {},metaData={}) {
  return {
    type,
    payload: args,
    meta: {metaData}
  }
}

export function createPromiseAction(type, promise, metaData = {} ) {
  return {
    type,
    payload: {promise},
    meta: {metaData}
  }
}

export function actionType(action) {
  return action.type
}

export function actionArguments(action) {
  return action.payload
}

export function actionMetaData(action) {
  if( action.meta === undefined )
    return null

  if( action.meta.metaData === undefined )
    return null

  return action.meta.metaData
}

export function actionResult(action) {
  if( action.payload === undefined )
    return null

  if( action.payload === null )
    return null

  return action.payload
}
