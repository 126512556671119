import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import {createPromise} from 'redux-promise-middleware'
import {reduceLogin} from '../action/login'
import {reduceStats} from '../action/stats'
import {createLogger} from 'redux-logger'
import Immutable from 'immutable'
import {reduceDownloads} from '../action/download'
import {reduceRestaurants} from '../action/restaurant'
import ReduxThunk from 'redux-thunk'
import {reduceData} from "../action/data"
import {reduceProspects} from "../action/prospect"
import {reduceDormants} from "../action/dormant"

const reducer = combineReducers({
  userInfo: reduceLogin,
  stats: reduceStats,
  downloads: reduceDownloads,
  restaurantsInfo: reduceRestaurants,
  prospectsInfo: reduceProspects,
  dormantsInfo: reduceDormants,
  data: reduceData
})

export const store = createStore(
  reducer,
  applyMiddleware(
    createPromise({
      promiseTypeSuffixes: ['BEGIN', 'SUCCESS', 'ERROR']
    }),
    ReduxThunk,
    /*
    createLogger({
      stateTransformer: (state) => {
        var newState = {};
        for (var i of Object.keys(state)) {
          if (Immutable.Iterable.isIterable(state[i])) {
            newState[i] = state[i].toJS();
          } else {
            newState[i] = state[i];
          }
        }
        return newState
      },
      predicate: (getState, action) => process.env.NODE_ENV !== 'production'
    })

     */
  )
)

export function observeStore(select, onChange) {
  let currentState;

  function handleChange() {
    let nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}
