import {createAction, createPromiseAction, actionType, actionArguments, actionMetaData,actionResult} from './index'
import {getReservations, getDailyReservations, getDailyReservationsForRestaurant,getCustomers,getInvoices} from '../controller/stats'

const DOWNLOAD_RESERVATIONS = 'DOWNLOAD_RESERVATIONS'
const DOWNLOAD_DAILY_RESERVATIONS = 'DOWNLOAD_DAILY_RESERVATIONS'
const DOWNLOAD_CUSTOMERS = 'DOWNLOAD_CUSTOMERS'
const DOWNLOAD_INVOICES = 'DOWNLOAD_INVOICES'

export function doDownloadReservationsAction(month) {
  const promise = getReservations(month)
  return createPromiseAction(DOWNLOAD_RESERVATIONS,promise,{})
}

export function doDownloadDailyReservationsAction(month) {
  const promise = getDailyReservations(month)
  return createPromiseAction(DOWNLOAD_DAILY_RESERVATIONS,promise,{})
}

export function doDownloadCustomersAction() {
  const promise = getCustomers()
  return createPromiseAction(DOWNLOAD_CUSTOMERS,promise,{})
}

export function doDownloadInvoicesAction(month) {
  const promise = getInvoices(month)
  return createPromiseAction(DOWNLOAD_INVOICES,promise,{})
}

export function doDownloadDailyReservationsForRestaurantAction(month,restaurant) {
  const promise = getDailyReservationsForRestaurant(month,restaurant)
  return createPromiseAction(DOWNLOAD_DAILY_RESERVATIONS,promise,{})
}

export function reduceDownloads(state = null, action = null) {
  if(!state) return {}

  let nextState = state
  const meta = actionMetaData(action)
  const result = actionResult(action)

  switch(actionType(action)) {
    case `${DOWNLOAD_RESERVATIONS}_BEGIN`:
    case `${DOWNLOAD_DAILY_RESERVATIONS}_BEGIN`:
    case `${DOWNLOAD_CUSTOMERS}_BEGIN`:
    case `${DOWNLOAD_INVOICES}_BEGIN`:
      break
    case `${DOWNLOAD_RESERVATIONS}_SUCCESS`:
      sendDataToFile(result,'reservations')
      break
    case `${DOWNLOAD_DAILY_RESERVATIONS}_SUCCESS`:
      sendDataToFile(result,'daily_reservations')
      break
    case `${DOWNLOAD_CUSTOMERS}_SUCCESS`:
      sendDataToFile(result,'K_THIRD')
      break
    case `${DOWNLOAD_INVOICES}_SUCCESS`:
      sendZipDataToFile(result,'invoices')
      break
    case `${DOWNLOAD_CUSTOMERS}_ERROR`:
    case `${DOWNLOAD_INVOICES}_ERROR`:
    case `${DOWNLOAD_RESERVATIONS}_ERROR`:
    case `${DOWNLOAD_DAILY_RESERVATIONS}_ERROR`:
      break
  }

  return nextState
}

function sendDataToFile(csvData,filename) {
  const bytes = new Uint8Array(csvData.length);
  for (let i=0; i<csvData.length; i++)
      bytes[i] = csvData.charCodeAt(i);

  const blob = new Blob([bytes], {type: 'text/csv'})

  const a = window.document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = `${filename}.csv`

  document.body.appendChild(a)
  a.click();

  document.body.removeChild(a)

}

function sendZipDataToFile(zipData,filename) {
  console.log(zipData)
  const blob = zipData

  const a = window.document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = `${filename}.zip`

  document.body.appendChild(a)
  a.click();

  document.body.removeChild(a)

}